import { Component, OnInit } from '@angular/core';
import {
  NotificationFilter,
  NotificationFilterType
} from '@shared/models/notification-filter.model';
import { UiNotification } from '@shared/models/ui-notification.model';
import { NotificationState, NotificationType } from '@shared/graphql/types';
import { NotificationService } from '@shared/services/notification.service';
import { takeUntil } from 'rxjs';
import { HeaderMenuComponentDirective } from '../../base/header-menu-component.directive';
import {
  downloadIcon,
  trashIcon,
  infoCircleIcon,
  xOutlineIcon,
  exclamationCircleIcon,
  bellIcon,
  clockIcon,
  radiobuttonCheckedIcon
} from '@progress/kendo-svg-icons';

@Component({
  selector: 'trccp-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss']
})
export class HeaderNotificationsComponent
  extends HeaderMenuComponentDirective
  implements OnInit
{
  public icons = {
    download: downloadIcon,
    delete: trashIcon,
    error: xOutlineIcon,
    info: infoCircleIcon,
    warning: exclamationCircleIcon,
    recent: clockIcon,
    unread: radiobuttonCheckedIcon,
    notify: bellIcon
  };

  dateFormat = 'dd.MM.yyyy hh:mm:ss';
  selectedNotificationFilterType: NotificationFilterType =
    NotificationFilterType.RECENT;
  notificationFilters: NotificationFilter[] = [
    {
      type: NotificationFilterType.RECENT,
      title: 'HEADER.NOTIFICATIONS.FILTERS.RECENT',
      iconName: 'recent'
    },
    {
      type: NotificationFilterType.UNREAD,
      title: 'HEADER.NOTIFICATIONS.FILTERS.UNREAD',
      iconName: 'unread'
    },
    {
      type: NotificationFilterType.ERROR,
      title: 'HEADER.NOTIFICATIONS.FILTERS.ERROR',
      iconName: 'error'
    },
    {
      type: NotificationFilterType.INFO,
      title: 'HEADER.NOTIFICATIONS.FILTERS.INFO',
      iconName: 'info'
    },
    {
      type: NotificationFilterType.WARNING,
      title: 'HEADER.NOTIFICATIONS.FILTERS.WARNING',
      iconName: 'warning'
    }
  ];
  notifications!: UiNotification[];
  filteredNotifications!: UiNotification[];
  unreadNotificationsCount = 0;

  get notificationState(): typeof NotificationState {
    return NotificationState;
  }

  constructor(private notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.notificationService
      .getNotifications()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();

    this.notificationService.notifications$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((notifications) => {
        this.notifications = notifications;
        this.unreadNotificationsCount = this.notifications?.filter(
          (item) =>
            item?.state === NotificationState.Unread &&
            item.notificationType !== NotificationType.Error
        )?.length;
        this.getFilteredNotifications(this.selectedNotificationFilterType);
      });
  }

  getFilteredNotifications(filterType: NotificationFilterType): void {
    this.selectedNotificationFilterType = filterType;

    this.filteredNotifications = this.notifications
      .filter((notification) => notification.state !== NotificationState.Hidden)
      .filter(
        (notification) =>
          this.selectedNotificationFilterType ===
            NotificationFilterType.RECENT ||
          (this.selectedNotificationFilterType ===
            NotificationFilterType.UNREAD &&
            notification?.state === NotificationState.Unread) ||
          notification?.notificationType ===
            (this.selectedNotificationFilterType as unknown as NotificationType)
      );
  }

  readNotification(notification: UiNotification): void {
    if (notification.state === NotificationState.Read) {
      return;
    }

    this.notificationService
      .updateNotificationsStates([notification?.id], NotificationState.Read)
      .subscribe();
  }

  readAllNotification(): void {
    const notificationsIds: string[] = this.notifications
      .filter((notification) => notification.state === NotificationState.Unread)
      .map((notification) => notification.id);
    this.notificationService
      .updateNotificationsStates(notificationsIds, NotificationState.Read)
      .subscribe();
  }

  deleteNotification(notificationId: string): void {
    this.notificationService
      .updateNotificationsStates([notificationId], NotificationState.Hidden)
      .subscribe();
  }
}
