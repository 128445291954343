import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from '@progress/kendo-data-query';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(data: unknown[], groupField: string | null) {
    if (!groupField) {
      return data;
    }
    return groupBy(data, [{ field: groupField }]);
  }
}
