<div>
  <div class="charts">
    <trccp-arc
      [input]="data | metricParser : MetricType.ServiceLevel"
      [colors]="serviceLevelColors"
      description="{{ 'METRICS.SERVICE_LEVEL.TITLE' | translate }}"
    ></trccp-arc>
    <trccp-arc
      [input]="data | metricParser : MetricType.HandledQuota"
      [colors]="handledColors"
      description="{{ 'METRICS.HANDLED_QUOTA.TITLE' | translate }}"
    ></trccp-arc>
  </div>
  <div class="divider"></div>
  <ul class="info">
    <li class="info-row">
      <label for="agentsAvailable{{ id }}">{{
        'METRICS.AVAILABLE.TITLE' | translate
      }}</label>
      <input
        disabled
        type="text"
        class="info-value"
        id="agentsAvailable{{ id }}"
        [value]="data | metricParser : MetricType.AgentsAvailable"
      />
    </li>
    <li class="info-row">
      <label for="agentsOnline{{ id }}">{{
        'METRICS.ONLINE.TITLE' | translate
      }}</label>
      <input
        disabled
        type="text"
        class="info-value"
        id="agentsOnline{{ id }}"
        [value]="data | metricParser : MetricType.AgentsOnline"
      />
    </li>
  </ul>
  <div class="divider"></div>
  <ul class="info">
    <li class="info-row">
      <label for="ContactsInQueue{{ id }}">{{
        'METRICS.CONTACTS_IN_QUEUE.TITLE' | translate
      }}</label>
      <input
        disabled
        type="text"
        class="info-value"
        id="contactsInQueue{{ id }}"
        [value]="data | metricParser : MetricType.ContactsInQueue"
      />
    </li>
    <li class="info-row">
      <label for="contactsHandled{{ id }}">{{
        'METRICS.CONTACTS_INCOMING.TITLE' | translate
      }}</label>
      <input
        disabled
        type="text"
        class="info-value"
        id="contactsHandled{{ id }}"
        [value]="data | metricParser : MetricType.ContactsIncoming"
      />
    </li>
    <li class="info-row">
      <label for="waitTime{{ id }}">{{
        'METRICS.WAIT_TIME.TITLE' | translate
      }}</label>
      <input
        disabled
        type="text"
        class="info-value"
        id="waitTime{{ id }}"
        [value]="
          (data | metricParser : MetricType.QueueAnswerTime) * 1000
            | date : 'HH:mm:ss' : 'GMT'
        "
      />
    </li>
  </ul>
</div>
