import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({
  selector: '[trccpClickOutside]'
})
export class ClickOutsideDirective {
  @Input('trccpClickOutside') isEnabled: boolean;
  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target): void {
    const clickedInside = this.elementRef?.nativeElement?.contains(target);

    if (this.isEnabled && !clickedInside) {
      this.clickOutside.emit();
    }
  }
}
