import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { SharedModule } from '@shared/shared.module';
import { MetricDataPipe } from './metric/metric-data.pipe';
import { MetricComponent } from './metric/metric.component';
import { MetricsComponent } from './metrics.component';

@NgModule({
  declarations: [MetricsComponent, MetricComponent, MetricDataPipe],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    GaugesModule
  ],
  exports: [MetricsComponent]
})
export class MetricsModule {}
