import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from '@config/app.config';
import { ConnectService } from '@shared/services/connect.service';
import { APP_VERSION } from 'app/app.version';
import { AuthService } from 'app/auth/auth.service';
import { AppSettings } from 'config/app.settings';
import { ConfigService } from 'config/config.service';
import { environment } from 'environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription, forkJoin, merge, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { BaseComponentDirective } from '../base/base-component.directive';

@Component({
  selector: 'trccp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent
  extends BaseComponentDirective
  implements OnInit, OnDestroy
{
  public APP_VERSION = APP_VERSION;
  public configuration = environment.configuration;
  public title!: string;
  public isAccountPopupExpanded = false;
  public isNotificationsPopupExpanded = false;
  public isSupportPopupExpanded = false;
  public hideVersion = false;
  public agentName!: string;
  public menu: MenuItem[];

  private settings!: AppSettings;

  constructor(
    public auth: AuthService,
    private connectService: ConnectService,
    private logger: NGXLogger,
    configService: ConfigService
  ) {
    super();
    const { settings, title, menu, hideVersion } =
      configService.getFrontendConfig();
    this.title = title;
    this.settings = settings;
    this.menu = menu;
    this.hideVersion = hideVersion;
  }

  public authenticated$!: Observable<boolean>;

  private logoutSubscription: Subscription;

  public logout() {
    this.logger.info('Logout dashboard');
    this.logoutSubscription = merge(
      this.connectService.setOfflineState(),
      timer(this.settings.logoutTimeout).pipe(
        tap(() => this.logger.info('Trigger timeout'))
      )
    )
      .pipe(
        tap((result: any) =>
          this.logger.info('Set offline state ', result.success)
        ),
        switchMap(() =>
          forkJoin([this.connectService.logout(), this.auth.logout()])
        )
      )
      .subscribe();
  }

  // Event not always reliable, see usage notes:
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(e: Event) {
    this.logger.info('prepare quitting');
    this.logout();
  }

  @HostListener('window:unload', ['$event'])
  unload(e: Event) {
    this.logger.info('quitting');
    this.logout();
  }

  async ngOnInit(): Promise<void> {
    this.authenticated$ = this.auth.isAuthenticated();
    this.agentName = await this.getAgentName();
  }

  private async getAgentName() {
    const session = await this.auth.getAuthenticatedSession();
    const { idToken } = session.tokens;
    const payload = idToken.payload;
    return payload.email.toString() ?? '<missing>';
  }

  ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }
}
