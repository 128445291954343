import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartsModule } from './charts/charts.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipe.module';
import { PropertyListModule } from './property-list/property-list.module';
import { TimerModule } from './timer/timer.module';

const SHARED_MODULES = [
  ChartsModule,
  PipesModule,
  TimerModule,
  PropertyListModule,
  DirectivesModule
];
@NgModule({
  declarations: [],
  imports: [CommonModule, ...SHARED_MODULES],
  providers: [],
  exports: [...SHARED_MODULES]
})
export class SharedModule {}
