<div class="notifications">
  <kendo-badge-container>
    <button
      kendoButton
      fillMode="clear"
      size="none"
      (click)="handleAnchorClick($event)"
      class="notifications__action"
      #notificationAnchor
    >
      <kendo-avatar
        themeColor="primary"
        width="48"
        height="48"
        [svgIcon]="icons.notify"
      ></kendo-avatar>
    </button>
    <kendo-badge *ngIf="unreadNotificationsCount > 0" themeColor="error">
      {{ unreadNotificationsCount }}
    </kendo-badge>
  </kendo-badge-container>

  <kendo-popup
    (anchorViewportLeave)="toggleIsExpanded(false)"
    (clickOutside)="toggleIsExpanded()"
    *ngIf="isExpanded"
    [anchorAlign]="anchorAlign"
    [anchor]="notificationAnchor"
    [margin]="{ vertical: 10, horizontal: 0 }"
    [popupAlign]="popupAlign"
    [trccpClickOutside]="isExpanded"
  >
    <div class="notifications__content">
      <div class="notifications__content--header">
        <div class="notifications__content--header__title">
          {{ 'HEADER.NOTIFICATIONS.TITLE' | translate }}
        </div>
        <div
          (click)="readAllNotification(); $event.stopPropagation()"
          class="notifications__content--header__mark--read"
        >
          {{ 'HEADER.NOTIFICATIONS.MARK_ALL_AS_READ' | translate }}
        </div>
      </div>
      <div class="notifications__content--filters">
        <div
          (click)="getFilteredNotifications(filter?.type)"
          *ngFor="let filter of notificationFilters"
          [class.active]="filter?.type === selectedNotificationFilterType"
          class="notifications__content--filters__item"
        >
          <kendo-svg-icon [icon]="icons[filter?.iconName | lowercase]"></kendo-svg-icon>
          <div class="notifications__content--filters__item--title">
            {{ filter?.title | translate }}
          </div>
        </div>
      </div>

      <div class="notifications__content--items">
        <div
          (click)="readNotification(notification)"
          *ngFor="let notification of filteredNotifications"
          class="notifications__content--item"
        >
          <div
            class="notifications__content--item__left {{
              notification?.notificationType | lowercase
            }}"
          >
            <kendo-svg-icon [icon]="icons[notification?.notificationType | lowercase]"></kendo-svg-icon>
            <span
              *ngIf="notification.state === notificationState.Unread"
              class="notifications__content--item__left--read"
            >
            </span>
          </div>
          <div class="notifications__content--item__right">
            <div class="notifications__content--item__right--title">
              <span
                [title]="notification.title"
                class="notifications__content--item__right--title__cnt"
              >
                {{ notification.title }}
              </span>
              <span class="notifications__content--item__right--title__date">
                {{ notification?.timestamp | date : dateFormat }}
              </span>
            </div>
            <div class="notifications__content--item__right--title">
              <span
                [title]="notification.description"
                class="notifications__content--item__right--description"
              >
                {{ notification.description }}
              </span>

              <span class="notifications__content--item__right--title__icons">
                <a
                  (click)="$event.stopPropagation()"
                  *ngIf="notification.logUrl"
                  [download]="notification.logFileName"
                  [href]="notification.logUrl"
                  class="notifications__content--item__right--title__icon download"
                  [title]="
                    'HEADER.NOTIFICATIONS.ITEM.ACTIONS.DOWNLOAD' | translate
                  "
                >
                <kendo-svg-icon [icon]="icons.download"></kendo-svg-icon>
                </a>
                <a
                  (click)="
                    deleteNotification(notification.id);
                    $event.stopPropagation()
                  "
                  class="notifications__content--item__right--title__icon delete"
                  [title]="
                    'HEADER.NOTIFICATIONS.ITEM.ACTIONS.DELETE' | translate
                  "
                >
                <kendo-svg-icon [icon]="icons.delete"></kendo-svg-icon>
              </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-popup>
</div>
