import { Injectable } from '@angular/core';
import { InstanceStateService } from '@auth/connect/instance-state.service';
import {
  Agent,
  AgentListDocument,
  AgentListQuery,
  AgentListQueryVariables,
  AgentListUpdateDocument,
  AgentListUpdateSubscription,
  AgentListUpdateSubscriptionVariables,
  AgentStatus
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest } from 'rxjs';
import { map, scan, startWith, tap } from 'rxjs/operators';
import { StaffConfig } from './staff.config';

@Injectable()
export class StaffDataService {
  private initialAgents: Agent[] = [];
  private readonly statusLookup = {
    AVAILABLE: 1,
    ON_CALL: 2,
    AWAY: 3,
    OFFLINE: 4,
    MISSED: 5,
    ACW: 6,
    CALLBACK: 7
  };

  constructor(
    private gqlc: Apollo,
    private logger: NGXLogger,
    private instance: InstanceStateService
  ) {}

  public getStaffData(
    config: StaffConfig,
    filterBy?: AgentStatus
  ): Observable<Array<Agent>> {
    const currentAgents$ = this.getAgentList();
    const newAgent$ = this.getAgentUpdate().pipe(
      startWith<Agent>(undefined),
      tap((agent) => this.logger.debug('newAgentState: ', agent))
    );

    return combineLatest([currentAgents$, newAgent$]).pipe(
      scan<[Agent[], Agent], Agent[]>(
        (allAgents, agentState) => this.updateAgents(agentState, allAgents),
        this.initialAgents
      ),
      map((agents) =>
        config.sortBy === 'NAME'
          ? this.sortAgentsByName(agents)
          : this.sortAgentsByStatus(agents)
      ),
      map((agents) =>
        filterBy ? agents.filter((a: Agent) => a.status !== filterBy) : agents
      )
    );
  }

  private updateAgents(agentState: [Agent[], Agent], allAgents: Agent[]) {
    const currentAgents = agentState[0];
    const newAgent = agentState[1];

    allAgents.push(
      ...currentAgents.filter((currentAgent) =>
        allAgents.every((agent) => agent.agentId !== currentAgent.agentId)
      )
    );
    if (newAgent) {
      const index = allAgents.findIndex(
        (agent: Agent) => agent.agentId === newAgent.agentId
      );
      if (index > -1) {
        allAgents[index] = newAgent;
      }
    }
    return allAgents;
  }

  private sortAgentsByStatus(agents: Array<Agent>) {
    return agents.sort((a: Agent, b: Agent) => {
      let order = this.statusLookup[a.status] - this.statusLookup[b.status];
      if (order === 0) {
        const nameA = `${a.firstName}${a.lastName}`;
        const nameB = `${b.firstName}${b.lastName}`;
        order = nameA.localeCompare(nameB);
      }
      return order;
    });
  }

  private sortAgentsByName(agents: Array<Agent>) {
    return agents.sort((a: Agent, b: Agent) => {
      const nameA = `${a.firstName}${a.lastName}`;
      const nameB = `${b.firstName}${b.lastName}`;
      return nameA.localeCompare(nameB);
    });
  }

  private getAgentUpdate() {
    return this.gqlc
      .subscribe<
        AgentListUpdateSubscription,
        AgentListUpdateSubscriptionVariables
      >({
        query: AgentListUpdateDocument
      })
      .pipe(map((result) => result.data.subscribeToAgentList));
  }

  private getAgentList() {
    return this.gqlc
      .query<AgentListQuery, AgentListQueryVariables>({
        query: AgentListDocument,
        variables: {
          queueId: '',
          instanceId: this.instance.getInstanceId()
        }
      })
      .pipe(map((result) => result.data.getAgentStatus));
  }
}
