import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'propertyFilter'
})
export class PropertyFilterPipe implements PipeTransform {
  transform(
    properties: Array<KeyValue<string, any>>,
    filterKeys: Array<string>,
    invertFilter = false
  ): Array<KeyValue<string, any>> {
    const props = properties ?? [];
    return filterKeys
      ? props.filter((property: KeyValue<string, any>) =>
          invertFilter
            ? !filterKeys.includes(property.key)
            : filterKeys.includes(property.key)
        )
      : props;
  }
}
