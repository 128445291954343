export enum NotificationFilterType {
  RECENT = 'RECENT',
  UNREAD = 'UNREAD',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export class NotificationFilter {
  type: NotificationFilterType;
  title: string;
  iconName: string;
}
