import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConnectCredentialService } from '@auth/connect/connect-credential.service';
import { MenuItem } from '@config/app.config';
import { DashboardItemDialogComponent } from '@dashboard/dashboard-item-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ConnectCredentialsResponse } from '@shared/graphql/types';
import { LayoutService } from '@shared/services/layout.service';
import { takeUntil } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ConnectConfigService } from '../../../config/connect-config.service';
import { HeaderMenuComponentDirective } from '../../base/header-menu-component.directive';
import { CONNECT_SIGN_IN_URL } from '@auth/connect/auth-util.service';
import { userIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'trccp-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss']
})
export class HeaderAccountComponent
  extends HeaderMenuComponentDirective
  implements OnInit
{
  @Input() public menu: MenuItem[] = [];
  @Output() logoutClicked: EventEmitter<void> = new EventEmitter<void>();

  public editModelEnabled = false;
  public icons = {
    account: userIcon
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dialogService: DialogService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private connectConfigService: ConnectConfigService,
    private credentialService: ConnectCredentialService
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService.editModeEnabled$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((editModelEnabled) => {
        this.editModelEnabled = editModelEnabled;
      });
  }

  public toggleEditMode(editModeEnabled: boolean) {
    this.layoutService.editModeEnabled = editModeEnabled;
  }

  public openAddDashboardItemDialog(event: Event): void {
    event.preventDefault();
    this.toggleIsExpanded(false);
    this.dialogService.open({
      title: this.translateService.instant('DIALOGS.ADD_DASHBOARD_ITEM.TITLE'),
      content: DashboardItemDialogComponent,
      closeTitle: this.translateService.instant(
        'DIALOGS.ADD_DASHBOARD_ITEM.ACTIONS.CLOSE'
      ),
      actionsLayout: 'start'
    });
  }

  public openAdministrationView(event: Event) {
    event.preventDefault();
    const { administrationUrl } =
      this.connectConfigService.getConnectInstanceConfig();

    this.credentialService
      .getConnectCredentials()
      .pipe(
        tap((credentialResponse: ConnectCredentialsResponse) => {
          const actionUrl = new URL(CONNECT_SIGN_IN_URL, administrationUrl);
          this.redirectToConnectConsole(actionUrl, credentialResponse);
        }),
        take(1)
      )
      .subscribe();
  }

  public onMenuItemClick(event: Event, menuItem: MenuItem) {
    event.preventDefault();
    if (menuItem.url) {
      window.open(menuItem.url, '_blank');
    }
  }

  public onLogoutClick(event: Event): void {
    event.preventDefault();
    this.logoutClicked.emit();
  }

  private redirectToConnectConsole(
    actionUrl: URL,
    credentialResponse: ConnectCredentialsResponse
  ) {
    const redirectForm = this.document.getElementById(
      'admin-login-form'
    ) as HTMLFormElement;
    redirectForm.setAttribute('method', 'post');
    redirectForm.setAttribute('target', '_blank');
    redirectForm.setAttribute('action', actionUrl.toString());

    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'credentials');
    input.setAttribute('value', credentialResponse.credentials);
    redirectForm.appendChild(input);

    const destination = document.createElement('input');
    destination.setAttribute('type', 'hidden');
    destination.setAttribute('name', 'destination');
    destination.setAttribute('value', '/home');
    redirectForm.appendChild(destination);
    redirectForm.submit();

    redirectForm.removeChild(input);
    redirectForm.removeChild(destination);
  }
}
