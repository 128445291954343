import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  DataBindingDirective,
  GridComponent,
  GridDataResult
} from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { CallDataService } from './call-data.service';

@Directive({
  selector: '[trccpCallDataBinding]'
})
export class CallDataBindingDirective
  extends DataBindingDirective
  implements OnInit, OnDestroy
{
  protected unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private logger: NGXLogger,
    private callData: CallDataService,
    grid: GridComponent
  ) {
    super(grid);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.rebind();
  }

  public rebind(): void {
    this.queryCallData(this.state)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
    super.rebind();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
    super.ngOnDestroy();
  }

  private queryCallData(state: State): Observable<GridDataResult> {
    this.grid.loading = true;
    return this.callData.getCallHistory(state).pipe(
      tap((callResult: GridDataResult) => {
        this.logger.debug('grid results: ', callResult);
        this.grid.data = callResult;
        this.notifyDataChange();
      }),
      catchError((error) => {
        this.grid.loading = false;
        return throwError(() => error);
      }),
      tap(() => {
        this.grid.loading = false;
      })
    );
  }
}
