import { Pipe, PipeTransform } from '@angular/core';
import { Columns } from './column.model';

@Pipe({
  name: 'columnStyle'
})
export class ColumnStylePipe implements PipeTransform {
  transform(column: Columns, data: any) {
    return column.classFn ? column.classFn(data) : '';
  }
}
