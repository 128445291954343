import { Component, OnInit, ViewChild } from '@angular/core';
import { Columns } from '@dashboard/column.model';
import { TransferType } from '@dashboard/staff/staff.config';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SelectableSettings } from '@progress/kendo-angular-treeview';
import { PhonebookUser } from '@shared/graphql/types';
import { ConnectService } from '@shared/services/connect.service';
import { BaseComponentDirective } from 'app/base/base-component.directive';
import { catchError, EMPTY, map, Observable, take, takeUntil, tap } from 'rxjs';
import { PhonebookService } from './phonebook.service';

@Component({
  selector: 'trccp-phonebook',
  templateUrl: './phonebook.component.html',
  styleUrls: ['./phonebook.component.scss']
})
export class PhonebookComponent
  extends BaseComponentDirective
  implements OnInit
{
  public phonebookView$: Observable<Array<PhonebookUser>>;
  public phoneBook: PhonebookUser[];
  public agentState: string;
  public isLoading: boolean;
  public searchInput = '';

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public columns: Array<Columns> = [];
  public selectableSettings: SelectableSettings;

  public expandedDetailKeys: PhonebookUser[] = [];

  constructor(
    private connectService: ConnectService,
    private phonebookService: PhonebookService
  ) {
    super();
    this.selectableSettings = {
      mode: 'multiple'
    };
  }

  ngOnInit(): void {
    this.initializeColumns();
    this.connectService
      .onAgentStateChange()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((state) => (this.agentState = state.newState))
      )
      .subscribe();
  }

  public search(searchInput: string): void {
    this.isLoading = true;
    this.expandedDetailKeys = [];
    this.phonebookView$ = this.phonebookService
      .queryPhonebook(searchInput)
      .pipe(
        map((users: PhonebookUser[]) =>
          users.map((user) => {
            return {
              ...user,
              firstName: `${user.lastName}, ${user.firstName}`
            };
          })
        ),
        tap(() => {
          this.dataBinding.skip = 0;
          this.isLoading = false;
        }),
        catchError((error) => {
          this.isLoading = false;
          return EMPTY;
        })
      );
  }

  private initializeColumns() {
    this.columns = [
      {
        key: 'firstName',
        title: 'PHONEBOOK.COLUMNS.NAME',
        type: 'default',
        field: 'firstName'
      },
      {
        key: 'title',
        title: 'PHONEBOOK.COLUMNS.TITLE',
        type: 'default',
        field: 'title'
      },
      {
        key: 'function',
        title: 'PHONEBOOK.COLUMNS.FUNCTION',
        type: 'default',
        field: 'role'
      },
      {
        key: 'company',
        title: 'PHONEBOOK.COLUMNS.COMPANY',
        type: 'default',
        field: 'company'
      },
      {
        key: 'department',
        title: 'PHONEBOOK.COLUMNS.DEPARTMENT',
        type: 'default',
        field: 'department.code'
      },
      {
        key: 'location',
        title: 'PHONEBOOK.COLUMNS.LOCATION.CITY',
        type: 'default',
        field: 'contact.location.city'
      },
      {
        key: 'phone',
        title: 'PHONEBOOK.COLUMNS.PHONE',
        type: 'default',
        field: 'contact.phone'
      },
      {
        key: 'office',
        title: 'PHONEBOOK.COLUMNS.LOCATION.OFFICE',
        type: 'default',
        field: 'contact.location.office'
      }
    ];
  }

  public doCall(phonebookEntry: {
    phoneNumber: string;
    transferType: TransferType;
  }) {
    if (phonebookEntry.transferType === 'WARM') {
      this.connectService
        .doWarmTransferCallByNumber(phonebookEntry.phoneNumber)
        .subscribe();
    } else {
      this.connectService
        .doOutboundCall(phonebookEntry.phoneNumber)
        .pipe(take(1))
        .subscribe();
    }
  }

  public expandDetailsBy = (dataItem: PhonebookUser): PhonebookUser => {
    return dataItem;
  };
}
