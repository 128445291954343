import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import {
  CardModule,
  ExpansionPanelModule
} from '@progress/kendo-angular-layout';
import { SharedModule } from '@shared/shared.module';
import { AnyAgentOnlinePipe } from './any-agent-online.pipe';
import { FilterOfflineAgentsPipe } from './filter-offline-agents.pipe';
import { OnlineStaffCountPipe } from './online-staff-count.pipe';
import { StaffDataService } from './staff-data.service';
import { StaffComponent } from './staff.component';

@NgModule({
  declarations: [
    StaffComponent,
    OnlineStaffCountPipe,
    FilterOfflineAgentsPipe,
    AnyAgentOnlinePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    FormsModule,
    CardModule,
    GridModule,
    DropDownsModule,
    ExpansionPanelModule,
    InputsModule,
    IndicatorsModule,
    ButtonsModule,
    ReactiveFormsModule
  ],
  providers: [StaffDataService],
  exports: [StaffComponent]
})
export class StaffModule {}
