<kendo-circulargauge
class="timer"
[style.width.px]="width"
[style.height.px]="height"
[value]="time$()"
[scale]="timerScale"
[color]="'#1ca3d8'"
>
<ng-template kendoCircularGaugeCenterTemplate let-value="value">
    <span [class.timer--highlight]="value <= 0">{{ value * 1000 | date : 'm:ss' }}</span>
</ng-template>
</kendo-circulargauge>
