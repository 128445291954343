import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { BaseComponentDirective } from './base-component.directive';
import { Align } from '@progress/kendo-angular-popup';

@Directive()
export class HeaderMenuComponentDirective
  extends BaseComponentDirective
  implements OnDestroy
{
  @Input() isExpanded = false;
  @Output() expansionStateChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() anchorClicked: EventEmitter<void> = new EventEmitter<void>();

  anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  popupAlign: Align = { horizontal: 'right', vertical: 'top' };

  constructor() {
    super();
  }

  handleAnchorClick(event: Event) {
    this.anchorClicked.emit();
    this.toggleIsExpanded();
    event.stopPropagation();
  }

  toggleIsExpanded(isExpanded = !this.isExpanded) {
    this.isExpanded = isExpanded;
    this.expansionStateChanged.emit(isExpanded);
  }
}
