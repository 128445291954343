<header class="site-header k-widget k-toolbar">
  <trccp-status-badge [configuration]="configuration"></trccp-status-badge>
  <div class="logo"></div>
  <h1 class="title">{{ title }}</h1>
  <span *ngIf="!hideVersion" class="site-header__version">{{
    APP_VERSION
  }}</span>
  <div *ngIf="authenticated$ | async as agent; else menuSpacer" class="menu">
    <span class="login-name">
      <div *ngIf="agentName">
        {{ 'HEADER.LABEL.WELCOME' | translate }}, {{ agentName }}
      </div>
    </span>
    <trccp-header-notifications
      class="menu__item"
      [isExpanded]="isNotificationsPopupExpanded"
      (expansionStateChanged)="isNotificationsPopupExpanded = $event"
      (anchorClicked)="
        isAccountPopupExpanded = false; isSupportPopupExpanded = false
      "
    >
    </trccp-header-notifications>
    <trccp-header-support
      class="menu__item"
      [isExpanded]="isSupportPopupExpanded"
      (expansionStateChanged)="isSupportPopupExpanded = $event"
      (anchorClicked)="
        isAccountPopupExpanded = false; isNotificationsPopupExpanded = false
      "
    >
    </trccp-header-support>
    <trccp-header-account
      class="menu__item"
      [isExpanded]="isAccountPopupExpanded"
      [menu]="menu"
      (expansionStateChanged)="isAccountPopupExpanded = $event"
      (anchorClicked)="
        isNotificationsPopupExpanded = false; isSupportPopupExpanded = false
      "
      (logoutClicked)="logout()"
    >
    </trccp-header-account>
  </div>
  <ng-template #menuSpacer>
    <div class="spacer"></div>
  </ng-template>
</header>
