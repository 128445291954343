import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber } from '@shared/graphql/types';

@Pipe({
  name: 'phoneNumberValid'
})
export class PhoneNumberValidPipe implements PipeTransform {
  transform(phoneNumber: PhoneNumber): boolean {
    if (!phoneNumber) {
      return false;
    }
    return phoneNumber.phoneNumber && phoneNumber.phoneNumber !== '';
  }
}
