import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MetadataComponent } from './metadata.component';
import { ListViewModule } from '@progress/kendo-angular-listview';

@NgModule({
  declarations: [MetadataComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    ListViewModule
  ],
  exports: [MetadataComponent]
})
export class MetadataModule {}
