import { Injectable } from '@angular/core';
import {
  UpdateWrapUpReasonsDocument,
  UpdateWrapUpReasonsMutation,
  UpdateWrapUpReasonsMutationVariables,
  WrapUpMatching,
  WrapUpReasonsDocument,
  WrapUpReasonsQuery,
  WrapUpReasonsQueryVariables,
  WrapUpTimeDocument,
  WrapUpTimeQuery
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WrapUpDataService {
  constructor(private gqlc: Apollo, private logger: NGXLogger) {}

  public getWrapUpTime() {
    return this.gqlc
      .query<WrapUpTimeQuery>({
        query: WrapUpTimeDocument
      })
      .pipe(map((result) => result.data.getWrapUpTime));
  }

  public getWrapUpReasons(queueId?: string, matching?: WrapUpMatching) {
    return this.gqlc
      .query<WrapUpReasonsQuery, WrapUpReasonsQueryVariables>({
        query: WrapUpReasonsDocument,
        variables: {
          queueId: queueId,
          matching: matching
        }
      })
      .pipe(map((result) => result.data.getWrapUpReasons ?? []));
  }

  public setWrapUpReasons(
    contactId: string,
    originalContactId: string,
    reasons: Array<string>
  ) {
    this.logger.debug('write wrap-up reasons', reasons);
    return this.gqlc.mutate<
      UpdateWrapUpReasonsMutation,
      UpdateWrapUpReasonsMutationVariables
    >({
      mutation: UpdateWrapUpReasonsDocument,
      variables: {
        contactId,
        originalContactId,
        wrapUpNames: reasons
      }
    });
  }
}
