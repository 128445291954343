import { FetchPolicy } from '@apollo/client/core';

export enum Configuration {
  Development,
  Test,
  Production
}

export interface Environment {
  production: boolean;
  configuration: Configuration;
  fetchPolicy: FetchPolicy;
}
