<div class="login">
  <kendo-card [width]="'400px'">
    <kendo-card-body>
      <div class="login__form">
        @if (connectCredentials$ | async; as connectCredentials) {
        <form #authForm ngNoForm [action]="authUrl" method="POST">
          <input
            type="text"
            name="credentials"
            [value]="connectCredentials.credentials"
            style="display: none"
          />
          <input
            type="text"
            name="destination"
            [value]="connectCredentials.destination"
            style="display: none"
          />
          <div class="k-form-buttons login__controls">
            <button
              kendoButton
              [primary]="true"
              #authBtn
              type="submit"
              class="login-button"
            >
              {{ 'CCP.ACTIONS.LOGIN' | translate }}
            </button>
          </div>
        </form>
        } 
      </div>
    </kendo-card-body>
  </kendo-card>
</div>
