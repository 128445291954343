import { NgModule } from '@angular/core';
import { ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { AuthService } from '@auth/auth.service';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ConfigService } from 'config/config.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (config: ConfigService, authService: AuthService) => {
        const { appsync } = config.getBackendConfig();

        const auth: AuthOptions = {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => authService.getAuthenticatedSessionToken()
        };

        const url = appsync.graphqlEndpoint;
        const region = appsync.region;
        const httpLink = new HttpLink({
          uri: appsync.graphqlEndpoint
        });

        const errorLink = onError(
          ({ graphQLErrors, networkError, operation, forward }) => {
            if (graphQLErrors) {
              for (let err of graphQLErrors) {
                if (err.extensions?.code === 'UNAUTHENTICATED') {
                  const oldHeaders = operation.getContext().headers;
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: async () =>
                        authService.getAuthenticatedSessionToken()
                    }
                  });
                  return forward(operation);
                }
              }
            }
            if (networkError) {
              console.error(
                `Network Error: ${networkError.name}, Operation: ${operation.operationName}`
              );
            }
          }
        );

        const retryLink = new RetryLink({
          delay: {
            initial: 300,
            max: Infinity,
            jitter: true
          },
          attempts: {
            max: 2,
            retryIf: (error, operation) => {
              console.warn(`[Retry] Operation: ${operation.operationName}`);
              return !!error;
            }
          }
        });

        const authLink = createAuthLink({ url, region, auth });
        const subscriptionLink = createSubscriptionHandshakeLink(
          { url, region, auth },
          httpLink
        );

        const link = ApolloLink.from([
          errorLink,
          retryLink,
          authLink,
          subscriptionLink
        ]);

        return {
          link,
          cache: new InMemoryCache()
        };
      },
      deps: [ConfigService, AuthService]
    }
  ],
  bootstrap: []
})
export class GraphqlModule {}
