import { Injectable } from '@angular/core';
import { Customer } from '@shared/graphql/types';
import { StateService } from '@shared/services/state.service';

export type ProfileEvent = 'ON_SEARCH' | 'ON_PROFILE_DETAIL';

export interface ProfileEventState {
  event: ProfileEvent;
  isSingleProfile: boolean;
  profile?: Customer;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileEventStateService extends StateService<ProfileEventState> {
  constructor() {
    super({ event: 'ON_SEARCH', isSingleProfile: false });
  }

  public onProfileEventState() {
    return this.select((state) => state);
  }

  public onProfileEvent() {
    return this.select((state) => state.event);
  }

  public publishProfileEvent(
    event: ProfileEvent,
    customer?: Customer,
    isSingleProfile = false
  ) {
    this.setState({ event, isSingleProfile, profile: customer });
  }
}
