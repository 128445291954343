<dl>
  <ng-container
    *ngFor="
      let property of properties
        | keyvalue : keepOrder
        | propertyFilter : internalFilter : disallow
        | propertyFilter : filter : invertFilter
    "
  >
    <ng-container *ngIf="property.value">
      <ng-container
        [ngTemplateOutlet]="keyTemplate || defaultPropertyKey"
        [ngTemplateOutletContext]="{ key: property.key }"
      ></ng-container>
      
        <ng-template #defaultPropertyKey>
          <dt>{{ property.key | sentenceCase }}</dt>
        </ng-template>
      <dd>
        <ng-container
          *ngIf="property.value | keyvalue : keepOrder; else singleProperty"
        >
          <ng-container
            *ngFor="
              let propertyValue of property.value
                | keyvalue : keepOrder
                | propertyFilter : internalFilter : disallow
            "
          >
            <div
              *ngIf="propertyValue"
              [title]="propertyValue.value"
              class="property-value"
            >
              {{ propertyValue.value }}
            </div>
          </ng-container>
        </ng-container>
        <ng-template #singleProperty>
          <div
            *ngIf="property.value"
            [title]="property.value"
            class="property-value"
          >
            {{ property.value }}
          </div>
        </ng-template>
      </dd>
    </ng-container>
  </ng-container>
</dl>
