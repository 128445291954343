export type TimeTickEvent = (remainingTime: number) => void;
export type TimeEndedEvent = () => void;

export class Timer {
  private timerId: NodeJS.Timeout | null;
  private currentTime: number;
  private tickEvent: TimeTickEvent;
  private endEvent: TimeEndedEvent;

  constructor(totalTime?: number) {
    if (totalTime) {
      this.currentTime = totalTime;
    }
  }

  public setStartTime(timeInSeconds: number) {
    this.currentTime = timeInSeconds;
  }

  public start() {
    this.timerId = setInterval(() => {
      this.tick();
    }, 1000);
  }

  public stop() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
      if (this.endEvent) {
        this.endEvent();
      }
    }
  }

  public onTick(event: TimeTickEvent) {
    this.tickEvent = event;
  }

  public onEnded(event: TimeEndedEvent) {
    this.endEvent = event;
  }

  private tick() {
    if (this.currentTime > 0) {
      this.currentTime--;
      if (this.tickEvent) {
        this.tickEvent(this.currentTime);
      }
    } else {
      this.stop();
    }
  }
}
