<div class="retention">
  <button
    class="retention--action"
    kendoButton
    themeColor="primary"
    [title]="'PHONE.RETENTION.CONTRACT.TOOLTIP' | translate"
    (click)="onContract()"
  >
    {{ 'PHONE.RETENTION.CONTRACT.TEXT' | translate }}
  </button>
  <button
    class="retention--action"
    kendoButton
    themeColor="primary"
    [title]="'PHONE.RETENTION.COACHING.TOOLTIP' | translate"
    (click)="onCoaching()"
  >
    {{ 'PHONE.RETENTION.COACHING.TEXT' | translate }}
  </button>
</div>
