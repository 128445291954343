import { Component, Input } from '@angular/core';
import { ColorRange } from '@progress/kendo-angular-gauges';

@Component({
  selector: 'trccp-arc',
  templateUrl: './arc.component.html',
  styleUrls: ['./arc.component.scss']
})
export class ArcComponent {
  @Input() public input: number;
  @Input() public description: string;
  @Input() public colors: Array<ColorRange>;
}
