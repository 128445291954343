import { Component } from '@angular/core';
import { ConnectConfigService } from '../../../config/connect-config.service';
import { HeaderMenuComponentDirective } from '../../base/header-menu-component.directive';
import { questionCircleIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'trccp-header-support',
  templateUrl: './header-support.component.html',
  styleUrls: ['./header-support.component.scss']
})
export class HeaderSupportComponent extends HeaderMenuComponentDirective {
  public supportUrl!: string;
  public icons = {
    help: questionCircleIcon
  };

  constructor(connectConfig: ConnectConfigService) {
    super();

    const { supportUrl } = connectConfig.getConnectInstanceConfig();
    this.supportUrl = supportUrl;
  }
}
