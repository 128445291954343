import { Injectable } from '@angular/core';
import {
  CallRecordingAction,
  SetCallRecordingDocument,
  SetCallRecordingMutation,
  SetCallRecordingMutationVariables
} from '@shared/graphql/types';
import { ContactProxy } from '@tecracer/trccp-streams';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {
  private $contact = new BehaviorSubject<ContactProxy | undefined>(undefined);

  constructor(private gqlc: Apollo) {}

  public setContact(contact: ContactProxy) {
    this.$contact.next(contact);
  }

  public fromContact(): Observable<
    [contactId: string, initialContactId: string]
  > {
    return this.$contact
      .asObservable()
      .pipe(
        switchMap((contact: ContactProxy) =>
          combineLatest([
            contact.getContactId(),
            contact.getOriginalContactId()
          ])
        )
      );
  }

  public doCallRecording(
    action: CallRecordingAction,
    contactId: string,
    initialContactId: string
  ) {
    return this.gqlc
      .mutate<SetCallRecordingMutation, SetCallRecordingMutationVariables>({
        mutation: SetCallRecordingDocument,
        variables: {
          callRecordingEntry: {
            action: action,
            contactId: contactId,
            initialContactId: initialContactId
          }
        }
      })
      .pipe(map((result) => result.data?.setCallRecording));
  }
}
