import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BackendConfig, AppConfig, FrontendConfig } from './app.config';

const CONFIG_FILE_PATH = 'assets/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config = new AppConfig();

  constructor(private http: HttpClient, private logger: NGXLogger) {}

  public getConfig(): AppConfig {
    return this.config;
  }

  public getBackendConfig(): BackendConfig {
    return this.config.backend;
  }

  public getFrontendConfig(): FrontendConfig {
    return this.config.frontend;
  }

  loadConfigFromFile(): Observable<AppConfig> {
    const configPath = `${CONFIG_FILE_PATH}/config.json`;
    this.logger.debug(`Load config: ${configPath}`);
    return this.http.get<AppConfig>(configPath).pipe(
      tap((config: AppConfig) => {
        this.config = config;
        this.logger.debug('found config: ', this.config);
      }),
      catchError((error) => {
        this.logger.error(`Config file at '${configPath}' could not be found`);
        return EMPTY;
      })
    );
  }
}
