import { Injectable } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { InstanceStateService } from '@auth/connect/instance-state.service';
import {
  ConnectCredentialsResponse,
  ConnectSsoCredentialsDocument,
  ConnectSsoCredentialsQuery,
  ConnectSsoCredentialsQueryVariables
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectCredentialService {
  constructor(
    private gqlc: Apollo,
    private instanceService: InstanceStateService,
    private authService: AuthService
  ) {}

  public getConnectCredentials(): Observable<ConnectCredentialsResponse> {
    return this.authService
      .getAuthenticatedUserToken()
      .pipe(
        switchMap((jwtToken: string) =>
          this.getConnectSSOCredentials(
            this.instanceService.getInstanceId(),
            jwtToken
          )
        )
      );
  }

  private getConnectSSOCredentials(instanceId: string, jwtToken: string) {
    return this.gqlc
      .query<ConnectSsoCredentialsQuery, ConnectSsoCredentialsQueryVariables>({
        query: ConnectSsoCredentialsDocument,
        fetchPolicy: environment.fetchPolicy,
        variables: {
          instanceId,
          jwtToken
        }
      })
      .pipe(map((result) => result.data.getConnectSSOCredentials));
  }
}
