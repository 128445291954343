export class Utility {
  public static getQueueId(queue: connect.Queue) {
    if (queue) {
      const queueParts = queue.queueId.split('/');
      const queuePartLength = queueParts.length;
      if (queuePartLength > 0) {
        return queueParts[queuePartLength - 1];
      }
    }
    return '';
  }
}
