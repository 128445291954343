import { Component, Input } from '@angular/core';
@Component({
  selector: 'trccp-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  public MAX_CHARACTER_COUNT = 160;

  @Input() public title?: string;
  @Input() public message: string;
}
