<div
  class="ribbon"
  [ngClass]="position === 'left' ? 'ribbon__left' : 'ribbon__right'"
>
  <div
    *ngIf="configuration === ConfigurationState.Development"
    class="ribbon__dev"
  >
    DEV
  </div>
  <div *ngIf="configuration === ConfigurationState.Test" class="ribbon__test">
    TEST
  </div>
</div>
