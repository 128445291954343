import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Routes } from '@shared/routes.model';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { InstanceStateService } from './connect/instance-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private auth: AuthService,
    private instance: InstanceStateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.isAuthenticated();
  }

  canLoad() {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated().pipe(
      take(1),
      tap((authenticated: boolean) =>
        authenticated && this.instance.hasInstanceId()
          ? false
          : this.redirectToLogin()
      )
    );
  }

  private redirectToLogin(): Observable<UrlTree> {
    return of(this.router.createUrlTree([Routes.Login]));
  }
}
