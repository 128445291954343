import { Component } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { ConnectCredentialsResponse } from '@shared/graphql/types';
import { ConfigService } from 'config/config.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { InstanceStateService } from './instance-state.service';
import { AuthUtilService } from './auth-util.service';

@Component({
  selector: 'trccp-connect-login',
  templateUrl: './connect-login.component.html',
  styleUrls: ['./connect-login.component.scss']
})
export class ConnectLoginComponent {
  public authUrl!: string;
  public connectCredentials$: Observable<ConnectCredentialsResponse>;

  private instanceId!: string;

  constructor(
    configService: ConfigService,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private instance: InstanceStateService,
    private authUtil: AuthUtilService
  ) {
    const config = configService.getConfig();
    this.instanceId = this.instance.getInstanceId();
    this.authUrl = this.authUtil.buildAuthUrl(
      config.backend.connect[this.instanceId].instanceUrl
    );
    this.logger.info('Resolved authUrl ', this.authUrl);
    this.connectCredentials$ = this.parseConnectCredentials();
  }

  private parseConnectCredentials() {
    return this.route.data.pipe(
      map(
        (data: Data) => data.connectCredentials as ConnectCredentialsResponse
      ),
      tap((connectCredentials: ConnectCredentialsResponse) =>
        this.logger.info(
          'Resolved valid connect credentials ',
          this.authUtil.isConnectCredentialsValid(connectCredentials)
        )
      ),
      shareReplay(1)
    );
  }
}
