import { KeyValue } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'trccp-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent {
  @Input() keyTemplate: TemplateRef<any>;

  @Input() public properties: { [key: string]: any };
  @Input() public filter: Array<string>;
  @Input() public invertFilter = false;
  @Input() public height: number;
  @Input() public maxCharacterLength = 40;

  public disallow = true;
  public internalFilter = ['__typename'];

  public keepOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>) => {
    return 0;
  };
}
