<kendo-grid
  *let="outboundStateService.hasOutbound | async as hasOutbound"
  trccpCallDataBinding
  [pageable]="pager"
  [selectable]="selectable"
  [filter]="state.filter"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  (selectionChange)="onCallSelected($event)"
>
  <kendo-grid-messages
    [noRecords]="'TABLE.CONTROLS.NO_RECORDS' | translate"
    [pagerOf]="'TABLE.CONTROLS.PAGER_OF' | translate"
    [pagerItems]="'TABLE.CONTROLS.PAGER_ITEMS' | translate"
    [pagerFirstPage]="'TABLE.CONTROLS.PAGER_FIRST_PAGE' | translate"
    [pagerLastPage]="'TABLE.CONTROLS.PAGER_LAST_PAGE' | translate"
    [pagerPreviousPage]="'TABLE.CONTROLS.PAGER_PREVIOUS_PAGE' | translate"
    [pagerNextPage]="'TABLE.CONTROLS.PAGER_NEXT_PAGE' | translate"
    [pagerPage]="'TABLE.CONTROLS.PAGER_PAGE' | translate"
  ></kendo-grid-messages>
  <ng-container *ngFor="let column of columns">
    <kendo-grid-column
      *ngIf="column.type === 'default'"
      [field]="column.field"
      [headerStyle]="column | columnAlign"
      [filter]="column.filter"
      [format]="column.format"
      [class]="column.columnClass"
      [hidden]="column.hidden"
      [width]="column.width"
    >
      <ng-template kendoGridHeaderTemplate>
        <header class="header">{{ column.title | translate }}</header>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="column.type === 'template' && !column.hidden"
      [width]="column.width"
      [headerStyle]="column | columnAlign"
    >
      <ng-template kendoGridHeaderTemplate>
        <header class="header">{{ column.title | translate }}</header>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span
          [title]="column.title | translate"
          [class]="column.columnClass"
          [ngClass]="column | columnStyle : dataItem"
        >
          <div
            class="template__cell"
            *ngFor="let columnData of column | columnTransform : dataItem"
          >
            {{ columnData }}
          </div>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column
      *ngIf="column.type === 'command'"
      [headerStyle]="column | columnAlign"
      [width]="column.width"
      [class]="column.columnClass"
    >
      <ng-template kendoGridHeaderTemplate>
        <header class="header">{{ column.title | translate }}</header>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        @if(hasOutbound) {
        <button
          kendoButton
          themeColor="primary"
          [style.width.px]="40"
          [title]="
            'CALL_HISTORY.OUTBOUND.ENABLED.TOOLTIP'
              | translate : { phoneNumber: dataItem.phone ?? '-' }
          "
          (click)="onOutboundCall(dataItem)"
        >
          <i class="icons call-icon">phone</i>
        </button>
        } @else {
        <div
          [title]="'CALL_HISTORY.OUTBOUND.DISABLED.TOOLTIP' | translate"
        >
          <i class="icons warn-icon">warning</i>
        </div>
        }
      </ng-template>
    </kendo-grid-command-column>
  </ng-container>
</kendo-grid>
