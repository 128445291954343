import { Injectable } from '@angular/core';
import {
  ConnectionProxy,
  ContactProxy,
  StreamsService
} from '@tecracer/trccp-streams';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  pluck,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Agent, Queue } from '../graphql/types';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {
  constructor(private streams: StreamsService, private logger: NGXLogger) {}

  public onAccepted() {
    return this.streams.contact$.pipe(
      switchMap((contact: ContactProxy) =>
        contact.onAccepted().pipe(mapTo(contact))
      )
    );
  }

  public onConnecting() {
    return this.streams.contact$.pipe(
      switchMap((contact: ContactProxy) =>
        contact.onConnecting().pipe(mapTo(contact))
      )
    );
  }

  public onMissed() {
    return this.streams.contact$.pipe(
      switchMap((contact) => contact.onMissed().pipe(mapTo(contact)))
    );
  }

  public onAfterCallWork() {
    return this.streams.contact$.pipe(
      switchMap((contact) => contact.onACW().pipe(mapTo(contact)))
    );
  }

  public onEnded() {
    return this.streams.contact$.pipe(
      switchMap((contact) => contact.onEnded().pipe(mapTo(contact)))
    );
  }

  public onCleared() {
    return this.streams.contact$.pipe(
      switchMap((contact) => contact.onDestroy().pipe(mapTo(contact)))
    );
  }

  public onAgentStateChange() {
    return this.streams.agent$.pipe(
      switchMap((agent) => agent.onStateChange())
    );
  }

  public getAgentStates() {
    return this.streams.agent$.pipe(
      switchMap((agent) => agent.getAgentStates())
    );
  }

  public getState() {
    return this.streams.agent$.pipe(switchMap((agent) => agent.getState()));
  }

  public setOfflineState() {
    return this.streams.agent$.pipe(
      switchMap((agent) =>
        agent.getAgentStates().pipe(
          switchMap((states: Array<connect.AgentState>) => {
            const offlineState = states.find(
              (state) => state.type === 'offline'
            );
            return offlineState ? agent.setState(offlineState) : EMPTY;
          })
        )
      ),
      take(1)
    );
  }

  public getAgentId(): Observable<string> {
    return this.getAgentConfiguration().pipe(
      map((config: connect.AgentConfiguration) => {
        const queue = config.routingProfile.queues.find((queue) =>
          queue.queueARN.includes('agent')
        );
        // find agentId in agent queues
        return queue ? queue.queueARN.split('/')[4] : '';
      })
    );
  }

  public getAgentConfiguration(): Observable<connect.AgentConfiguration> {
    return this.streams.agent$.pipe(
      switchMap((agent) => agent.getConfiguration())
    );
  }

  public getAgentUsername(): Observable<string> {
    return this.getAgentConfiguration().pipe(pluck('username'));
  }

  public doColdTransferCall(transfer: Agent | Queue) {
    return this.streams.agent$.pipe(
      switchMap((agent) =>
        agent.getAllQueueARNs().pipe(
          tap((transferArns: string[]) =>
            this.logger.debug(`transferArns ${JSON.stringify(transferArns)}`)
          ),
          switchMap((transferArns: string[]) =>
            agent.getEndpoints(transferArns)
          ),
          map((result: connect.GetEndpointsResult) => {
            const endpoint = result.endpoints.find(
              (endpoint: connect.Endpoint) =>
                endpoint.endpointId.includes(transfer.quickConnectId)
            );
            if (!endpoint) {
              throw Error(
                `There was no endpoint found for quickConnect ${transfer.quickConnectId}`
              );
            }
            return endpoint;
          }),
          withLatestFrom(
            agent
              .getContacts(connect.ContactType.VOICE)
              .pipe(map((contacts) => contacts[0]))
          ),
          switchMap(([endpoint, contact]) =>
            endpoint && contact
              ? contact.addConnection(endpoint).pipe(
                  switchMap(() =>
                    contact.getAgentConnection().pipe(
                      switchMap((agentConnection) => agentConnection.destroy()),
                      catchError(() => EMPTY)
                    )
                  )
                )
              : EMPTY
          ),
          take(1)
        )
      )
    );
  }

  public doWarmTransferCall(transfer: Agent) {
    return this.streams.agent$.pipe(
      switchMap((agent) =>
        agent.getAllQueueARNs().pipe(
          tap((transferArns: string[]) =>
            this.logger.debug(`transferArns ${JSON.stringify(transferArns)}`)
          ),
          switchMap((transferArns: string[]) =>
            agent.getEndpoints(transferArns)
          ),
          map((result: connect.GetEndpointsResult) => {
            const endpoint = result.endpoints.find(
              (endpoint: connect.Endpoint) =>
                endpoint.endpointId.includes(transfer.quickConnectId)
            );
            if (!endpoint) {
              throw Error(
                `There was no endpoint found for quickConnect ${transfer.quickConnectId}`
              );
            }
            return endpoint;
          }),
          withLatestFrom(
            agent
              .getContacts(connect.ContactType.VOICE)
              .pipe(map((contacts) => contacts[0]))
          ),
          switchMap(([endpoint, contact]) =>
            endpoint && contact ? contact.addConnection(endpoint) : EMPTY
          )
        )
      ),
      take(1)
    );
  }

  public doWarmTransferCallByNumber(phoneNumber: string) {
    return this.streams.getEndpointByPhoneNumber(phoneNumber).pipe(
      switchMap((endpoint) =>
        this.streams.agent$.pipe(
          switchMap((agent) =>
            agent
              .getContacts(connect.ContactType.VOICE)
              .pipe(map((contacts) => contacts[0]))
          ),
          filter((contact) => !!contact && !!endpoint),
          switchMap((contact) => contact.addConnection(endpoint))
        )
      ),
      take(1)
    );
  }

  public doOutboundCall(phoneNumber: string) {
    return this.streams
      .getEndpointByPhoneNumber(phoneNumber)
      .pipe(
        switchMap((endpoint) =>
          this.streams.agent$.pipe(
            switchMap((agent) => agent.connect(endpoint))
          )
        )
      );
  }

  public getCurrentContact(): Observable<ContactProxy> {
    return this.streams.agent$.pipe(
      switchMap((agent) => agent.getContacts(connect.ContactType.VOICE)),
      map((contacts) => contacts[0])
    );
  }

  public getCurrentConnection() {
    return this.getCurrentContact().pipe(
      switchMap((contact: ContactProxy) => contact.getConnections()),
      map((connections) => connections[1])
    );
  }

  public getCurrentEndpoint() {
    return this.getCurrentConnection().pipe(
      switchMap((connection: ConnectionProxy) => connection.getEndpoint())
    );
  }

  public logout() {
    return this.streams.logout();
  }
}
