import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentStatus } from '@shared/graphql/types';

@Pipe({
  name: 'onlineStaffCount'
})
export class OnlineStaffCountPipe implements PipeTransform {
  transform(value: Agent[]): number {
    return value.filter(
      (agent: Agent) => agent?.status === AgentStatus.Available
    )?.length;
  }
}
