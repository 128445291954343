import { Component, Input, OnInit } from '@angular/core';
import {
  ContactEvent,
  ContactEventState,
  ContactStateService
} from '@dashboard/contact-event-state.service';
import { Customer } from '@shared/graphql/types';
import { merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ContactPhonePipe } from './contact-phone.pipe';
import { ProfileDataService } from './profile-data.service';
import {
  ProfileEventState,
  ProfileEventStateService
} from './profile-event-state.service';
import { ProfileConfig } from './profile.config';

@Component({
  selector: 'trccp-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input('config') config: ProfileConfig;

  public dashboardEvent$: Observable<ContactEvent>;
  public profilePhone$: Observable<string>;
  public profileEventState$: Observable<ProfileEventState>;
  public profiles$!: Observable<Array<Customer>>;
  public profileListFilter = [
    'accountNumber',
    'birthDate',
    'emailAddress',
    'phoneNumber'
  ];
  public loading = false;

  private customerSearch$ = new Subject<string>();

  constructor(
    private phonePipe: ContactPhonePipe,
    private stateService: ContactStateService,
    private profileEventService: ProfileEventStateService,
    private profileDataService: ProfileDataService
  ) {}

  ngOnInit(): void {
    this.profileEventState$ = this.profileEventService.onProfileEventState();

    this.dashboardEvent$ = this.stateService.onEventChanged().pipe(
      tap((event) => {
        if (event === 'CALLING') {
          this.profileEventService.publishProfileEvent('ON_SEARCH');
        }
      })
    );

    this.profilePhone$ = merge(
      this.stateService.onEventStateChanged().pipe(
        switchMap((dashboardState: ContactEventState) => {
          if (!dashboardState.payload?.phone) {
            return this.phonePipe.transform(dashboardState.payload);
          }
          return dashboardState.payload
            ? of(dashboardState.payload.phone)
            : of('');
        })
      ),
      this.customerSearch$
    );

    this.profiles$ = this.profilePhone$.pipe(
      tap(() => {
        this.loading = true;
      }),
      switchMap((phone) => this.profileDataService.getCustomerProfiles(phone)),
      tap((profiles) => {
        const isSingleProfile = profiles.length === 1;
        if (isSingleProfile) {
          const profile = profiles[0];
          this.profileEventService.publishProfileEvent(
            'ON_PROFILE_DETAIL',
            profile,
            isSingleProfile
          );
        }
        this.loading = false;
      }),
      catchError((err) => {
        this.loading = false;
        return throwError(() => err);
      })
    );
  }

  public onBack() {
    this.stateService.publishEvent('IDLE');
  }

  public onCustomerSearch(customerPhone: string) {
    this.customerSearch$.next(customerPhone);
  }
}
