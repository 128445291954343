import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CallingState } from '@shared/enums/dashboard-selection-item-state.enum';
import { DashboardItem } from '@shared/models/dashboard-item.model';
import { LayoutService } from '@shared/services/layout.service';
import { takeUntil } from 'rxjs';
import { BaseComponentDirective } from '../base/base-component.directive';

@Component({
  selector: 'trccp-add-dashboard-item-dialog',
  templateUrl: './dashboard-item-dialog.component.html',
  styleUrls: ['./dashboard-item-dialog.component.scss']
})
export class DashboardItemDialogComponent
  extends BaseComponentDirective
  implements OnInit
{
  notSelectedDashboardItems: DashboardItem[];
  selectedDashboardItems: DashboardItem[];
  dashboardItemsIdsToSelect: string[] = [];
  public CallingState = CallingState;

  constructor(private layoutService: LayoutService, public dialog: DialogRef) {
    super();
  }

  ngOnInit(): void {
    this.layoutService.dashboardItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dashboardItems) => {
        this.notSelectedDashboardItems = dashboardItems.filter(
          (item) => !item.selected && !item.uiDisabled
        );
        this.selectedDashboardItems = dashboardItems.filter(
          (item) => item.selected && !item.uiDisabled
        );
      });
  }

  toggleDashboardItem(dashboardItem: DashboardItem): void {
    dashboardItem.uiSelected = !dashboardItem.uiSelected;

    if (
      !dashboardItem?.uiSelected &&
      this.dashboardItemsIdsToSelect?.includes(dashboardItem?.id)
    ) {
      this.dashboardItemsIdsToSelect = this.dashboardItemsIdsToSelect.filter(
        (id) => id !== dashboardItem?.id
      );
    } else {
      this.dashboardItemsIdsToSelect.push(dashboardItem?.id);
    }
  }

  save(): void {
    this.layoutService.addNewDashboardItems(this.dashboardItemsIdsToSelect);
    this.dialog.close();
  }

  closeDialog(): void {
    this.dialog.close();
  }
}
