import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { chevronRightIcon } from '@progress/kendo-svg-icons';
import { RoutingProfile } from '@shared/graphql/types';
import { ConnectService } from '@shared/services/connect.service';
import { InfoService } from '@shared/services/info.service';
import { BaseComponentDirective } from 'app/base/base-component.directive';
import { Observable, catchError, throwError } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { RoutingProfileDataService } from './routing-profile-data.service';
import { RoutingProfileStateService } from './routing-profile-state.service';

@Component({
  selector: 'trccp-routing-profile',
  templateUrl: './routing-profile.component.html',
  styleUrl: './routing-profile.component.scss'
})
export class RoutingProfileComponent extends BaseComponentDirective {
  public routingProfile$: Observable<RoutingProfile>;
  public routingProfiles$: Observable<RoutingProfile[]>;
  public selectedRoutingProfile: RoutingProfile = null;

  public icons = {
    set: chevronRightIcon
  };

  public routingFilter: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  constructor(
    private connectService: ConnectService,
    private infoService: InfoService,
    private translate: TranslateService,
    private routingProfileService: RoutingProfileDataService,
    private routingProfileState: RoutingProfileStateService
  ) {
    super();
    this.routingProfiles$ = this.connectService
      .getAgentId()
      .pipe(
        switchMap((agentId) =>
          this.routingProfileService.getRoutingProfiles(agentId)
        )
      );

    this.routingProfile$ = this.connectService
      .getAgentId()
      .pipe(
        switchMap((agentId) =>
          this.routingProfileService.getRoutingProfile(agentId)
        )
      );
  }

  public onRoutingProfileChanged(routingProfile: RoutingProfile) {
    this.selectedRoutingProfile = routingProfile;
  }

  public onApplyRoutingProfile() {
    if (this.selectedRoutingProfile) {
      const { routingProfileId, routingProfileName } =
        this.selectedRoutingProfile;

      this.connectService
        .getAgentId()
        .pipe(
          switchMap((agentId) =>
            this.routingProfileService
              .setRoutingProfile({
                agentId: agentId,
                routingProfileId: routingProfileId,
                routingProfileName: routingProfileName
              })
              .pipe(
                tap(() => {
                  this.routingProfileState.trigger();
                  this.infoService.showMessage(
                    this.translate.instant(
                      'ROUTING_PROFILE.MESSAGE.APPLY_ROUTING.SUCCESS',
                      {
                        profile: routingProfileName
                      }
                    ),
                    'success'
                  );
                }),
                catchError((err) => {
                  this.infoService.showMessage(
                    this.translate.instant(
                      'ROUTING_PROFILE.MESSAGE.APPLY_ROUTING.ERROR',
                      {
                        profile: routingProfileName
                      }
                    ),
                    'error'
                  );
                  return throwError(() => err);
                }),
                takeUntil(this.unsubscribe$)
              )
          )
        )
        .subscribe();
    }
  }
}
