import { StreamsConfig } from '@tecracer/trccp-streams';
import { AppSettings } from './app.settings';

export interface AppSyncConfig {
  region: string;
  graphqlEndpoint: string;
  authenticationType: string;
}

export interface CognitoConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  mandatorySignIn: boolean;
}

export interface OAuthConfig {
  domain: string;
  identityProvider: string;
  scope: string[];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: 'code' | 'token';
}

export interface ConnectConfig {
  [instanceId: string]: ConnectInstanceConfig;
}

export interface ConnectInstanceConfig {
  instanceUrl: string;
  supportUrl?: string;
  administrationUrl?: string;
  streamsConfig: StreamsConfig;
}

export class AppConfig {
  frontend: FrontendConfig;
  backend: BackendConfig;
}
export interface BackendConfig {
  appsync: AppSyncConfig;
  cognito: CognitoConfig;
  oauth: OAuthConfig;
  connect: ConnectConfig;
}

export interface FrontendConfig {
  title: string;
  tabTitle: string;
  settings: AppSettings;
  hideVersion: boolean;
  menu: MenuItem[];
}

export interface MenuItem {
  icon: string;
  label: string;
  url: string;
}
