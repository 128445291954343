import { Pipe, PipeTransform } from '@angular/core';
import { CallingState } from '@shared/enums/dashboard-selection-item-state.enum';

@Pipe({
  name: 'legendColor'
})
export class LegendColorPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case CallingState.Detail:
        return '#ebc44e';
      case CallingState.Calling:
        return '#d68585';
      case CallingState.Idle:
        return '#96d37a';
      case CallingState.Acw:
        return '#7bafde';
      default:
        return '#ffffff';
    }
  }
}
