<kendo-grid
  [kendoGridBinding]="phonebookView$ | async"
  kendoGridSelectBy="id"
  [pageSize]="20"
  [pageable]="true"
  [sortable]="true"
  [reorderable]="true"
  [resizable]="true"
  [style.height.%]="100"
  [selectable]="selectableSettings"
  [columnMenu]="{ filter: true }"
  class="phonebook"
  [loading]="isLoading"
  [kendoGridExpandDetailsBy]="expandDetailsBy"
  [(expandedDetailKeys)]="expandedDetailKeys"
>
  <kendo-grid-messages
    [sortAscending]="'PHONEBOOK.COLUMNS.SORT.ASC' | translate"
    [sortDescending]="'PHONEBOOK.COLUMNS.SORT.DESC' | translate"
    [columns]="'PHONEBOOK.COLUMNS.FILTER.COLUMNS' | translate"
    [columnsApply]="'PHONEBOOK.COLUMNS.FILTER.APPLY_BUTTON' | translate"
    [columnsReset]="'PHONEBOOK.COLUMNS.FILTER.RESET_BUTTON' | translate"
    [filter]="'PHONEBOOK.COLUMNS.FILTER.TITLE' | translate"
    [filterAndLogic]="'PHONEBOOK.COLUMNS.FILTER.AND_OPERATOR' | translate"
    [filterOrLogic]="'PHONEBOOK.COLUMNS.FILTER.OR_OPERATOR' | translate"
    [filterClearButton]="'PHONEBOOK.COLUMNS.FILTER.CLEAR_BUTTON' | translate"
    [filterContainsOperator]="'PHONEBOOK.COLUMNS.FILTER.CONTAINS' | translate"
    [filterEndsWithOperator]="'PHONEBOOK.COLUMNS.FILTER.ENDS_WITH' | translate"
    [filterEqOperator]="'PHONEBOOK.COLUMNS.FILTER.ENDS_WITH' | translate"
    [filterFilterButton]="'PHONEBOOK.COLUMNS.FILTER.FILTER_BUTTON' | translate"
    [filterIsEmptyOperator]="'PHONEBOOK.COLUMNS.FILTER.IS_EMPTY' | translate"
    [filterIsNotEmptyOperator]="
      'PHONEBOOK.COLUMNS.FILTER.IS_NOT_EMPTY' | translate
    "
    [filterIsNotNullOperator]="'PHONEBOOK.COLUMNS.FILTER.IS_NULL' | translate"
    [filterIsNullOperator]="'PHONEBOOK.COLUMNS.FILTER.IS_NOT_NULL' | translate"
    [filterNotEqOperator]="'PHONEBOOK.COLUMNS.FILTER.NOT_EQUAL_TO' | translate"
    [filterStartsWithOperator]="
      'PHONEBOOK.COLUMNS.FILTER.STARTS_WITH' | translate
    "
    [filterNotContainsOperator]="
      'PHONEBOOK.COLUMNS.FILTER.NOT_CONTAINS' | translate
    "
    [noRecords]="'TABLE.CONTROLS.NO_RECORDS' | translate"
    [pagerOf]="'TABLE.CONTROLS.PAGER_OF' | translate"
    [pagerItems]="'TABLE.CONTROLS.PAGER_ITEMS' | translate"
    [pagerFirstPage]="'TABLE.CONTROLS.PAGER_FIRST_PAGE' | translate"
    [pagerLastPage]="'TABLE.CONTROLS.PAGER_LAST_PAGE' | translate"
    [pagerPreviousPage]="'TABLE.CONTROLS.PAGER_PREVIOUS_PAGE' | translate"
    [pagerNextPage]="'TABLE.CONTROLS.PAGER_NEXT_PAGE' | translate"
    [pagerPage]="'TABLE.CONTROLS.PAGER_PAGE' | translate"
  >
  </kendo-grid-messages>
  <ng-template kendoGridToolbarTemplate>
    <kendo-textbox
      [style.width.%]="40"
      [clearButton]="true"
      [(ngModel)]="searchInput"
      placeholder="{{ 'PHONEBOOK.SEARCH' | translate }}"
    ></kendo-textbox>
    <button kendoButton themeColor="primary" (click)="search(searchInput)" size="medium">
      {{ 'PHONEBOOK.SEARCH_BUTTON' | translate }}
    </button>
  </ng-template>
  <ng-container *ngFor="let column of columns">
    <kendo-grid-column
      [field]="column.field"
      [title]="column.title | translate"
    >
      <ng-template kendoGridHeaderTemplate>
        <header class="header">{{ column.title | translate }}</header>
      </ng-template>
    </kendo-grid-column>
  </ng-container>
  <ng-template kendoGridDetailTemplate let-dataItem>
    <trccp-phone-details
      [agentState]="agentState"
      (doCallEvent)="doCall($event)"
      [userDetails]="dataItem | phoneDetailsPipe"
    >
    </trccp-phone-details>
  </ng-template>
  <ng-template kendoGridNoRecordsTemplate>
    <p>{{ 'PHONEBOOK.NO_DATA' | translate }}</p>
  </ng-template>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="large"></kendo-loader>
    </div>
  </ng-template>
</kendo-grid>
