import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotificationType } from '@shared/enums/notification-type';
import { ErrorComponent } from './error/error.component';

const MAX_CONTAINER_WIDTH = 430;
@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private notificationService: NotificationService) {}

  public showMessage(
    notificationType: NotificationType,
    title: string,
    payload?: Error | string | null
  ) {
    const notificationRef = this.notificationService.show({
      content: ErrorComponent,
      width: MAX_CONTAINER_WIDTH,
      animation: { type: 'slide', duration: 200 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: notificationType, icon: true },
      closable: true
    });

    notificationRef.content.instance.title = title;

    if (payload instanceof Error) {
      notificationRef.content.instance.message = payload?.message;
    } else {
      notificationRef.content.instance.message = payload;
    }
  }
}
