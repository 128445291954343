import { Component } from '@angular/core';
import {
  PagerSettings,
  SelectableSettings
} from '@progress/kendo-angular-grid';

import { State } from '@progress/kendo-data-query';

import { RoutingProfileStateService } from '@dashboard/routing-profiles/routing-profile-state.service';
import { ColorRange } from '@progress/kendo-angular-gauges';
import { CurrentQueueMetric, MetricType, Queue } from '@shared/graphql/types';
import { QueueService } from '@shared/services/queue.service';
import { Observable, combineLatest, merge } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base-component.directive';
import { MetricDataService } from '../metrics/metrics-data.service';

@Component({
  selector: 'trccp-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./queue-list.component.scss']
})
export class QueueListComponent extends BaseComponentDirective {
  public metricData$: Observable<Array<CurrentQueueMetric>>;

  public MetricType = MetricType;

  public headerStyle = {
    'text-align': 'center',
    'font-size': '14px',
    padding: '5px'
  };

  public serviceLevelColors: Array<ColorRange> = [
    {
      to: 33.33,
      color: '#f31700'
    },
    {
      from: 33.34,
      to: 66.67,
      color: '#ffc000'
    },
    {
      from: 66.68,
      color: '#37b400'
    }
  ];

  public pager: PagerSettings = {
    buttonCount: 4,
    info: true,
    type: 'numeric'
  };

  public selectable: SelectableSettings = {
    enabled: true,
    checkboxOnly: false,
    mode: 'single'
  };

  public state: State = {
    skip: 0,
    take: 10
  };

  constructor(
    private queues: QueueService,
    private metrics: MetricDataService,
    private routingProfileState: RoutingProfileStateService
  ) {
    super();

    const updatedQueues$ = this.routingProfileState
      .getState()
      .pipe(switchMap(() => this.queues.getQueues()));

    const currentQueues$ = this.queues.getQueues();

    const queues$ = merge(currentQueues$, updatedQueues$);

    this.metricData$ = combineLatest([
      queues$,
      this.metrics.getCurrentMetrics()
    ]).pipe(map(([queues, metrics]) => this.filterMetrics(queues, metrics)));
  }

  private filterMetrics(
    queues: Array<Queue>,
    metrics: Array<CurrentQueueMetric>
  ) {
    return metrics.filter((metric) => {
      return queues.some((queue) => queue.id === metric.queue.id);
    });
  }
}
