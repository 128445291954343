export enum NotificationWorkerMessageType {
  START_NOTIFICATION_INTERVAL,
  STOP_NOTIFICATION_INTERVAL,
  ADD_NOTIFICATION_TO_LOCALDB,
  CLEAR_NOTIFICATIONS_FROM_LOCALDB,
  SET_NOTIFICATIONS
}

export interface NotificationWorkerMessage<T> {
  type: NotificationWorkerMessageType;
  payload: T;
}
