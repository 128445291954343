import { Component } from '@angular/core';
import { CallRecordingAction } from '@shared/graphql/types';
import { BaseComponentDirective } from 'app/base/base-component.directive';
import { switchMap, takeUntil } from 'rxjs';
import { RecordingState } from './recording-state.model';
import { RecordingService } from './recording.service';

@Component({
  selector: 'trccp-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.scss']
})
export class RecorderComponent extends BaseComponentDirective {
  public currentRecordingState: RecordingState = RecordingState.Ready;

  get RecordingState(): typeof RecordingState {
    return RecordingState;
  }

  constructor(private recordingService: RecordingService) {
    super();
  }

  public onStartRecording() {
    this.currentRecordingState = RecordingState.Progress;
    this.recordingService
      .fromContact()
      .pipe(
        switchMap(([contactId, initialContactId]) =>
          this.recordingService.doCallRecording(
            CallRecordingAction.Start,
            contactId,
            initialContactId
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  public onStopRecording() {
    this.currentRecordingState = RecordingState.Stop;
    this.recordingService
      .fromContact()
      .pipe(
        switchMap(([contactId, initialContactId]) =>
          this.recordingService.doCallRecording(
            CallRecordingAction.Stop,
            contactId,
            initialContactId
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  public onSuspendRecording() {
    this.currentRecordingState = RecordingState.Paused;
    this.recordingService
      .fromContact()
      .pipe(
        switchMap(([contactId, initialContactId]) =>
          this.recordingService.doCallRecording(
            CallRecordingAction.Suspend,
            contactId,
            initialContactId
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  public onResumeRecording() {
    this.currentRecordingState = RecordingState.Progress;
    this.recordingService
      .fromContact()
      .pipe(
        switchMap(([contactId, initialContactId]) =>
          this.recordingService.doCallRecording(
            CallRecordingAction.Resume,
            contactId,
            initialContactId
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
