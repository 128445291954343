import { Component, Input } from '@angular/core';
import { Configuration } from 'environments/environment.type';

@Component({
  selector: 'trccp-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent {
  @Input() configuration: Configuration = Configuration.Development;
  @Input() position: 'left' | 'right' = 'left';

  public ConfigurationState = Configuration;
}
