import { Injectable } from '@angular/core';
import { InstanceStateService } from '@auth/connect/instance-state.service';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  AgentQueuesDocument,
  AgentQueuesQuery,
  AgentQueuesQueryVariables,
  Queue
} from '../graphql/types';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  private selectedQueue = new BehaviorSubject<Queue>(undefined);

  constructor(private gqlc: Apollo, private instance: InstanceStateService) {}

  public getQueues() {
    return this.gqlc
      .query<AgentQueuesQuery, AgentQueuesQueryVariables>({
        query: AgentQueuesDocument,
        fetchPolicy: environment.fetchPolicy,
        variables: {
          instanceId: this.instance.getInstanceId()
        }
      })
      .pipe(
        map((result) => result.data.getAgentQueues),
        shareReplay(1)
      );
  }

  public getFirstQueue(): Observable<Queue | undefined> {
    return this.getQueues().pipe(map((queues) => queues?.[0]));
  }

  public setSelectQueue(queue: Queue) {
    this.selectedQueue.next(queue);
  }

  public getSelectedQueue(): Observable<Queue> {
    return this.selectedQueue.asObservable();
  }
}
