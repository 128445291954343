import { Injectable } from '@angular/core';
import { LayoutConfigService } from '../../../config/layout-config.service';
import { BehaviorSubject, Observable, share } from 'rxjs';
import { LayoutConfig } from '@shared/models/layout-config.model';
import { DashboardItem } from '@shared/models/dashboard-item.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private layoutConfigSubject: BehaviorSubject<LayoutConfig> =
    new BehaviorSubject<LayoutConfig>(this.layoutConfigService?.config);
  private editModeEnabledSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  dashboardItems$: Observable<DashboardItem[]> = this.layoutConfigSubject.pipe(
    map((layoutConfig) => {
      return [...layoutConfig?.dashboardItems];
    }),
    share()
  );

  selectedDashboardItems$: Observable<DashboardItem[]> =
    this.layoutConfigSubject.pipe(
      map((layoutConfig) => {
        return layoutConfig?.dashboardItems?.filter((item) => item.selected);
      }),
      share()
    );

  notSelectedDashboardItems$: Observable<DashboardItem[]> =
    this.layoutConfigSubject.pipe(
      map((layoutConfig) => {
        return layoutConfig?.dashboardItems?.filter((item) => !item.selected);
      }),
      share()
    );

  editModeEnabled$: Observable<boolean> =
    this.editModeEnabledSubject.asObservable();

  private get layoutConfig(): LayoutConfig {
    return this.layoutConfigService.config;
  }

  private set layoutConfig(value: LayoutConfig) {
    this.layoutConfigService.config = value;
    this.layoutConfigSubject.next(value);
  }

  set dashboardItems(value: DashboardItem[]) {
    this.layoutConfig = {
      ...this.layoutConfig
    };
  }

  set editModeEnabled(value: boolean) {
    this.editModeEnabledSubject.next(value);
  }

  constructor(private layoutConfigService: LayoutConfigService) {}

  addNewDashboardItems(dashboardItemsIds: string[]): void {
    dashboardItemsIds.forEach((id) => {
      const dashboardItemToSelect: DashboardItem =
        this.layoutConfig?.dashboardItems.find((item) => item?.id === id);
      dashboardItemToSelect.selected = true;
    });

    this.layoutConfig = {
      ...this.layoutConfig
    };
  }

  removeItem(dashboardItem: DashboardItem): void {
    let dashboardItemToRemove = dashboardItem?.parentItemId
      ? this.layoutConfig?.dashboardItems.find(
          (item) => item?.id === dashboardItem?.parentItemId
        )
      : dashboardItem;

    dashboardItemToRemove.selected = false;
    dashboardItemToRemove.x = dashboardItemToRemove?.defaultConfig?.x;
    dashboardItemToRemove.y = dashboardItemToRemove?.defaultConfig?.y;
    dashboardItemToRemove.realX = dashboardItemToRemove?.defaultConfig?.x;
    dashboardItemToRemove.realY = dashboardItemToRemove?.defaultConfig?.y;
    dashboardItemToRemove.cols = dashboardItemToRemove?.defaultConfig?.cols;
    dashboardItemToRemove.rows = dashboardItemToRemove?.defaultConfig?.rows;

    if (dashboardItemToRemove?.expansion) {
      dashboardItemToRemove.expansion.expansionLevel =
        dashboardItemToRemove?.defaultConfig?.expansionLevel;
    }

    if (dashboardItemToRemove?.items) {
      dashboardItemToRemove.items = [];
    }

    this.layoutConfig = {
      ...this.layoutConfig
    };
  }
}
