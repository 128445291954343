import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentStatus } from '@shared/graphql/types';

@Pipe({
  name: 'anyAgentOnline'
})
export class AnyAgentOnlinePipe implements PipeTransform {
  transform(agents: Agent[]): boolean {
    return agents.some((agent) => agent?.status !== AgentStatus.Offline);
  }
}
