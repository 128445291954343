import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ErrorComponent } from './error/error.component';
import { GlobalErrorHandler } from './global-error-handler';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, TranslateModule.forChild(), SharedModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ]
})
export class ErrorsModule {}
