import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: Array<any> | undefined): boolean {
    return value?.length === 0;
  }
}
