import { Component, Input } from '@angular/core';
import { ColorRange } from '@progress/kendo-angular-gauges';
import { CurrentQueueMetric, MetricType } from '@shared/graphql/types';

@Component({
  selector: 'trccp-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss']
})
export class MetricComponent {
  @Input() public id: string;
  @Input() public data: CurrentQueueMetric;

  public MetricType = MetricType;

  public serviceLevelColors: Array<ColorRange> = [
    {
      to: 33.33,
      color: '#f31700'
    },
    {
      from: 33.34,
      to: 66.67,
      color: '#ffc000'
    },
    {
      from: 66.68,
      color: '#37b400'
    }
  ];

  public handledColors: Array<ColorRange> = [
    {
      to: 33.33,
      color: '#f31700'
    },
    {
      from: 33.34,
      to: 66.66,
      color: '#ffc000'
    },
    {
      from: 66.67,
      color: '#37b400'
    }
  ];
}
