import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { ArcComponent } from './arc/arc.component';

@NgModule({
  declarations: [ArcComponent],
  imports: [CommonModule, GaugesModule],
  exports: [ArcComponent]
})
export class ChartsModule {}
