<div class="support">
  <kendo-badge-container [title]="'HEADER.SUPPORT.TOOLTIP_TITLE' | translate">
    <button
      kendoButton
      fillMode="clear"
      size="none"
      #supportAnchor
      (click)="handleAnchorClick($event)"
      class="support__action"
    >
      <kendo-avatar
        themeColor="primary"
        width="48"
        height="48"
        [svgIcon]="icons.help"
      ></kendo-avatar>
    </button>
  </kendo-badge-container>

  <kendo-popup
    [popupAlign]="popupAlign"
    [anchorAlign]="anchorAlign"
    [margin]="{ vertical: 10, horizontal: 0 }"
    [anchor]="supportAnchor"
    (anchorViewportLeave)="toggleIsExpanded(false)"
    [trccpClickOutside]="isExpanded"
    (clickOutside)="toggleIsExpanded()"
    *ngIf="isExpanded"
  >
    <div class="support__content">
      <div class="support__content--header">
        <div class="support__content--header__title">
          {{ 'HEADER.SUPPORT.TITLE' | translate }}
        </div>
      </div>
      <div class="support__content--items">
        <a [href]="supportUrl" target="_blank" class="support__content--item">
          <div class="support__content--item__left">
            <span class="icons">biotech</span>
          </div>
          <div class="support__content--item__right">
            {{ 'HEADER.SUPPORT.ENDPOINT_TEST_UTILITY' | translate }}
          </div>
        </a>
      </div>
    </div>
  </kendo-popup>
</div>
