<div class="profile-content">
  <div class="profile-header">
    <header class="profile-title">
      {{ customer.firstName }} {{ customer.lastName }}
    </header>
    <button kendoButton [primary]="true" (click)="onProfileDetail(customer)">
      {{ 'PROFILE.ACTIONS.DETAIL.TITLE' | translate }}
    </button>
  </div>
  <div class="profile-preview">
    <trccp-property-list
      [keyTemplate]="listTemplate"
      [properties]="customer"
      [filter]="propertyFilter"
    ></trccp-property-list>
  </div>
</div>
<ng-template #listTemplate let-key="key">
  <dt class="profile-attributes-key">
    {{ 'PROFILE.DETAIL.CUSTOMER.FIELDS.' + key | uppercase | translate }}
  </dt>
</ng-template>

