import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransferType } from '@dashboard/staff/staff.config';

@Component({
  selector: 'trccp-phone-details',
  providers: [],
  templateUrl: './phone-details.html',
  styleUrls: ['./phone-details.component.scss']
})
export class PhoneDetailsComponent {
  @Input() public userDetails: Array<KeyValue<string, string>>;
  @Input() public agentState: string;

  @Output() doCallEvent = new EventEmitter<{
    phoneNumber: string;
    transferType: TransferType;
  }>();

  emitCallEvent(
    userDetails: KeyValue<string, string>,
    transferType: TransferType
  ) {
    this.doCallEvent.emit({ phoneNumber: userDetails.value, transferType });
  }
}
