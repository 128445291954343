import { Pipe, PipeTransform } from '@angular/core';
import { ContactProxy } from '@tecracer/trccp-streams';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Pipe({
  name: 'contactPhone'
})
export class ContactPhonePipe implements PipeTransform {
  transform(contact: ContactProxy): Observable<string> {
    return contact
      ? contact.getConnections().pipe(
          map((connections) => connections[1]),
          switchMap((connection) => connection.getEndpoint()),
          map((endpoint) => {
            return endpoint ? endpoint.phoneNumber : '';
          }),
          catchError((error) => of(''))
        )
      : of('');
  }
}
