import { Injectable } from '@angular/core';
import {
  CustomerDocument,
  CustomerQuery,
  CustomerQueryVariables
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {
  constructor(private gqlc: Apollo) {}

  public getCustomerProfiles(search: string) {
    return this.gqlc
      .query<CustomerQuery, CustomerQueryVariables>({
        query: CustomerDocument,
        fetchPolicy: environment.fetchPolicy,
        variables: {
          search
        }
      })
      .pipe(map((result) => result.data.getCustomer));
  }
}
