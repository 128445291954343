import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentStatus } from '@shared/graphql/types';

@Pipe({
  name: 'filterOfflineAgents'
})
export class FilterOfflineAgentsPipe implements PipeTransform {
  transform(agents: Agent[], offlineVisibility: boolean): Agent[] {
    return offlineVisibility
      ? agents
      : agents.filter((agent: Agent) => agent?.status !== AgentStatus.Offline);
  }
}
