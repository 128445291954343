import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  computed,
  signal
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { StaffDataService } from '@dashboard/staff/staff-data.service';
import { Agent, AgentStatus, Queue } from '@shared/graphql/types';
import { QueueAgents } from '@shared/models/queue-agents.model';
import { ConnectService } from '@shared/services/connect.service';

import { eyeIcon, eyeSlashIcon } from '@progress/kendo-svg-icons';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base-component.directive';
import { StaffConfig } from './staff.config';

@Component({
  selector: 'trccp-staff',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent extends BaseComponentDirective implements OnInit {
  @Input('config') config: StaffConfig;

  public icons = {
    online: eyeIcon,
    offline: eyeSlashIcon
  };

  public agentId = '';
  public AgentStatus = AgentStatus;
  public queueAgents: QueueAgents[] = [];
  public filteredQueueAgents = signal<QueueAgents[]>([]);
  public loaded = computed(() => !!this.filteredQueueAgents()?.length);

  public allAgents: Agent[] = [];
  public filteredAgents: Agent[] = [];
  public staffSearchControl = new FormControl('');
  public offlineVisibility = true;
  private offlineVisibility$ = new BehaviorSubject<boolean>(true);

  public ngOnInit(): void {
    this.queryStaffData();
    this.staffSearchControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() =>
          this.config.groupBy === 'ALL'
            ? this.loadFilteredAgents()
            : this.loadFilteredQueueAgents()
        )
      )
      .subscribe();

    this.connect
      .getAgentId()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((agentId) => (this.agentId = agentId))
      )
      .subscribe();
  }

  constructor(
    private connect: ConnectService,
    private staffData: StaffDataService,
    private logger: NGXLogger
  ) {
    super();
  }

  public toggleVisibility() {
    this.offlineVisibility = !this.offlineVisibility;
    this.offlineVisibility$.next(this.offlineVisibility);
  }

  public onAgentTransfer(agent: Agent) {
    this.logger.debug(
      `onAgentTransfer clicked for agent ${agent.agentId}, transferType: ${this.config?.transferType}`
    );
    if (this.config?.transferType === 'COLD') {
      this.connect
        .doColdTransferCall(agent)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    } else {
      this.connect
        .doWarmTransferCall(agent)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    }
  }

  public onQueueTransfer(queue: Queue) {
    this.logger.debug(`onQueueTransfer clicked for queue ${queue.id}`);
    this.connect
      .doColdTransferCall(queue)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  private queryStaffData(): void {
    this.offlineVisibility$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((visibility: boolean) =>
          this.staffData.getStaffData(
            this.config,
            visibility ? undefined : AgentStatus.Offline
          )
        ),
        tap((agents) => {
          if (this.config.groupBy === 'ALL') {
            this.allAgents = agents;
            this.loadFilteredAgents();
          } else {
            const queueAgents: QueueAgents[] = this.getQueuesFromAgents(
              agents
            ).sort((a: QueueAgents, b: QueueAgents) =>
              a.name.localeCompare(b.name)
            );
            for (let queue of queueAgents) {
              queue.agents = agents.filter((agent) =>
                agent.queues?.some((q) => q?.id === queue?.id)
              );
            }
            this.queueAgents = queueAgents;
            this.loadFilteredQueueAgents();
          }
        })
      )
      .subscribe();
  }

  private getQueuesFromAgents(agents: Agent[]): Queue[] {
    const queues: Queue[] = [];
    if (!agents?.length) {
      return queues;
    }
    for (let i = 0; i < agents?.length; i++) {
      const currentAgent: Agent = agents[i];
      if (!currentAgent?.queues?.length) {
        continue;
      }
      for (let j = 0; j < currentAgent.queues.length; j++) {
        const currentQueue: Queue = currentAgent.queues[j];
        if (queues.some((queue) => queue?.id === currentQueue?.id)) {
          continue;
        }
        queues.push({ ...currentQueue });
      }
    }
    return queues;
  }

  private loadFilteredQueueAgents(): void {
    const searchText: string = this.staffSearchControl.value?.toLowerCase();
    if (!searchText?.length) {
      this.filteredQueueAgents.set(this.queueAgents);
    }
    const queueAgents = [...this.queueAgents]
      .map((queue) => {
        if (queue.name?.toLowerCase()?.includes(searchText)) {
          return queue;
        }
        const queueAgent: QueueAgents = {
          ...queue,
          agents: queue?.agents.filter((agent: Agent) =>
            (agent.firstName + agent.lastName)
              ?.toLowerCase()
              ?.includes(searchText)
          )
        };
        if (!queueAgent?.agents?.length) {
          return null;
        }
        return queueAgent;
      })
      .filter((queue) => !!queue);

    this.filteredQueueAgents.set(queueAgents);
  }

  private loadFilteredAgents(): void {
    const searchText: string = this.staffSearchControl.value?.toLowerCase();
    if (!searchText?.length) {
      this.filteredAgents = this.allAgents;
    }
    this.filteredAgents = [...this.allAgents].filter((agent: Agent) =>
      (agent.firstName + agent.lastName)?.toLowerCase()?.includes(searchText)
    );
  }
}
