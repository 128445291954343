<div class="search">
  <kendo-textbox
    [placeholder]="'STAFF.SEARCH' | translate"
    class="search-input"
    [formControl]="staffSearchControl"
  ></kendo-textbox>
</div>
<div class="staff-filter">
  <button
    kendoButton
    themeColor="info"
    [svgIcon]="offlineVisibility ? icons.offline : icons.online"
    look="clear"
    [title]="'STAFF.TOOLTIPS.TOGGLE_VISIBILITY' | translate"
    (click)="toggleVisibility()"
  ></button>
</div>

@if(config.groupBy !== 'ALL'){ @defer(when loaded()) {
<div class="staff-list">
  @for (queue of filteredQueueAgents(); track queue.id; let first = $first) {
  <kendo-expansionpanel
    [title]="queue.name"
    [expanded]="
      first && (!offlineVisibility || (queue.agents | anyAgentOnline))
    "
    [disabled]="offlineVisibility && !(queue.agents | anyAgentOnline)"
  >
    <ng-template kendoExpansionPanelTitleDirective>
      <div class="header-content">
        <span>
          <span
            style="margin-right: 5px; font-weight: bold"
            class="ellipsis"
            [title]="queue?.name"
            >{{ queue?.name }}</span
          >
          <span style="font-size: 12px">
            {{ queue?.agents | onlineStaffCount }} /
            {{ queue?.agents?.length }}</span
          >
        </span>
        @if(queue.agents | anyAgentOnline){
        <button
          class="staff-list__content--item__action call__icon"
          title="{{
            'STAFF.TOOLTIPS.TRANSFER.QUEUE' | translate : { queue: queue.name }
          }}"
          (click)="onQueueTransfer(queue); $event.stopPropagation()"
        >
          <i class="icons"> phone_icon </i>
        </button>
        }
      </div>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="
        agents;
        context: {
          $implicit: queue.agents | filterOfflineAgents : offlineVisibility
        }
      "
    ></ng-container>
  </kendo-expansionpanel>
  }
</div>
} @placeholder {
<div class="placeholder">
  <kendo-skeleton
    shape="rectangle"
    animation="pulse"
    [width]="355"
    [height]="500"
  ></kendo-skeleton>
</div>

} } @if(config.groupBy === 'ALL'){ @defer(when loaded()) {
<div class="staff-list">
  <ng-container
    *ngTemplateOutlet="
      agents;
      context: {
        $implicit: filteredAgents | filterOfflineAgents : offlineVisibility
      }
    "
  ></ng-container>
</div>
} @placeholder {
<div class="placeholder">
  <kendo-skeleton
    shape="rectangle"
    animation="pulse"
    [width]="355"
    [height]="500"
  ></kendo-skeleton>
</div>
} }

<ng-template #agents let-agents>
  <div class="staff-list__content">
    @for(agent of agents; track agent){
    <div class="staff-list__content--item">
      <div class="staff-list__content--item__title">
        <div
          class="staff-list__content--item__title--status status-{{
            agent.status | lowercase
          }}"
        ></div>
        <div class="staff-list__content--item__title--name">
          <span
            class="ellipsis"
            [title]="agent?.firstName + ' ' + agent?.lastName"
            >{{ agent?.firstName }} {{ agent?.lastName }}</span
          >
          @switch(agent.status) { @case (AgentStatus.Available) {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.AVAILABLE' | translate
          }}</span>
          } @case (AgentStatus.OnCall) {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.ON_CALL' | translate
          }}</span>
          } @case (AgentStatus.Missed) {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.MISSED' | translate
          }}</span>
          } @case (AgentStatus.Acw) {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.ACW' | translate
          }}</span>
          } @case (AgentStatus.Callback) {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.CALLBACK' | translate
          }}</span>
          } @case (AgentStatus.Offline) {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.OFFLINE' | translate
          }}</span>
          } @case (AgentStatus.Away) {
          <span class="status-name">{{ agent.statusName }}</span>
          } @default {
          <span class="status-name">{{
            'STAFF.AGENT_STATUS.UNKNOWN' | translate
          }}</span>
          } }
        </div>
      </div>
      <div class="staff-list__content--item__actions">
        @if(agentId !== agent?.agentId && agent.quickConnectId && agent?.status
        === AgentStatus.Available){
        <button
          class="staff-list__content--item__action call__icon"
          title="{{
            'STAFF.TOOLTIPS.TRANSFER.AGENT'
              | translate : { agent: agent?.firstName + ' ' + agent?.lastName }
          }}"
          (click)="onAgentTransfer(agent)"
        >
          <i class="icons"> phone_icon </i>
        </button>
        }
      </div>
    </div>
    }
  </div>
</ng-template>
