import { Pipe, PipeTransform } from '@angular/core';
import { CurrentQueueMetric, Queue } from '@shared/graphql/types';

@Pipe({
  name: 'metricData'
})
export class MetricDataPipe implements PipeTransform {
  transform(
    metrics: Array<CurrentQueueMetric>,
    queue: Queue
  ): CurrentQueueMetric {
    const result = metrics.filter(
      (metric: CurrentQueueMetric) => metric.queue.id === queue.id
    );
    return result[0];
  }
}
