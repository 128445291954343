<div
  class="phone-container"
  [class.with__recorder]="withRecorder && dashboardEvent === 'CALLING'"
  [class.with__outbound]="withOutbound"
>
  <div class="record-container" @slideVerticalAnimation>
    @if(withRecorder && dashboardEvent === 'CALLING') {
    <trccp-retention class="record-item-1"></trccp-retention>
    <trccp-recorder class="record-item-2"></trccp-recorder>
    }
  </div>
  <trccp-streams [config]="streamsConfig"></trccp-streams>
  <trccp-outbound *ngIf="withOutbound"></trccp-outbound>
</div>
