import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {
  arrowRotateCcwIcon,
  chevronRightIcon
} from '@progress/kendo-svg-icons';
import { PhoneNumber } from '@shared/graphql/types';
import { InfoService } from '@shared/services/info.service';
import { BaseComponentDirective } from 'app/base/base-component.directive';
import { Observable, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { OutboundDataService } from './outbound-data.service';
import { OutboundStateService } from './outbound-state.service';

@Component({
  selector: 'trccp-outbound',
  templateUrl: './outbound.component.html',
  styleUrls: ['./outbound.component.scss']
})
export class OutboundComponent extends BaseComponentDirective {
  public outboundPhoneNumber$: Observable<PhoneNumber>;
  public outboundPhoneNumbers$: Observable<Array<PhoneNumber>>;
  public selectedOutbound: PhoneNumber = null;
  public icons = {
    reset: arrowRotateCcwIcon,
    send: chevronRightIcon
  };

  public outboundFilter: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  constructor(
    private infoService: InfoService,
    private translate: TranslateService,
    private outboundDataService: OutboundDataService,
    private outboundStateService: OutboundStateService
  ) {
    super();
    this.outboundPhoneNumbers$ = this.outboundDataService.getOutboundNumbers();
    this.outboundPhoneNumber$ = this.outboundDataService
      .getOutboundNumber()
      .pipe(
        tap((outboundNumber) =>
          this.outboundStateService.setOutbound(outboundNumber)
        )
      );
  }

  public onOutboundSelected(outboundNumber: PhoneNumber) {
    this.selectedOutbound = outboundNumber;
  }

  public submitOutboundNumber() {
    if (this.selectedOutbound?.phoneNumber !== '') {
      this.outboundDataService
        .setOutboundNumber(this.selectedOutbound.phoneNumber)
        .pipe(
          tap(() => {
            this.outboundStateService.setOutbound(this.selectedOutbound);
          }),
          tap(() => {
            this.infoService.showMessage(
              this.translate.instant(
                'PHONE.OUTBOUND.MESSAGE.SAVE_OUTBOUND.SUCCESS',
                { phone: this.selectedOutbound.phoneNumber }
              ),
              'success'
            );
          }),
          catchError((err) => {
            this.infoService.showMessage(
              this.translate.instant(
                'PHONE.OUTBOUND.MESSAGE.SAVE_OUTBOUND.ERROR',
                { phone: this.selectedOutbound.phoneNumber }
              ),
              'error'
            );
            return throwError(() => err);
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }
  }
}
