import { Pipe, PipeTransform } from '@angular/core';
import { PhonebookUser } from '@shared/graphql/types';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'phoneDetailsPipe'
})
export class PhoneDetailsPipe implements PipeTransform {
  transform(user: PhonebookUser): Array<KeyValue<string, string>> {
    let phoneDetails: Array<KeyValue<string, string>> = [];

    for (const [key, value] of Object.entries(user.contact)) {
      let item = { key, value: value as string };

      if (['phone', 'mail', 'mobile'].includes(key)) {
        phoneDetails.push(item);
      }
    }
    return phoneDetails;
  }
}
