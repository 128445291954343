<div class="login-container">
  <kendo-card [width]="'460px'">
    <kendo-card-header>
      <div class="login-logo"></div>
    </kendo-card-header>
    <kendo-card-body>
      <div class="login-form-container">
        <h2 class="login-title">
          {{ 'LOGIN.TITLE' | translate }} <br />
          {{ title }}
        </h2>
        <div class="k-form-buttons login-controls">
          <button
            kendoButton
            [primary]="true"
            class="login-button"
            (click)="onLogin()"
          >
            {{ 'LOGIN.ACTIONS.LOGIN' | translate }}
          </button>
        </div>
      </div>
    </kendo-card-body>
  </kendo-card>
</div>
<div #container></div>
