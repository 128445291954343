import { Injectable } from '@angular/core';
import { ConnectConfig } from './app.config';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectConfigService {
  constructor(private config: ConfigService) {}

  public getConnectConfig(): ConnectConfig {
    const { connect } = this.config.getBackendConfig();
    return connect;
  }

  public getConnectInstanceId(): string {
    const connectConfig = this.getConnectConfig();
    const instanceIds = Object.keys(connectConfig);
    return instanceIds[0];
  }

  public getConnectInstanceConfig() {
    const connectConfig = this.getConnectConfig();
    const instanceId = this.getConnectInstanceId();
    return connectConfig[instanceId];
  }
}
