import { Component } from '@angular/core';
import { CurrentQueueMetric, Queue } from '@shared/graphql/types';
import { QueueService } from '@shared/services/queue.service';
import { Observable } from 'rxjs';
import { MetricDataService } from './metrics-data.service';

@Component({
  selector: 'trccp-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent {
  public queues$: Observable<Array<Queue>>;
  public metricData$: Observable<Array<CurrentQueueMetric>>;

  constructor(
    private queues: QueueService,
    private metrics: MetricDataService
  ) {
    this.queues$ = this.queues.getQueues();
    this.metricData$ = this.metrics.getCurrentMetrics();
  }
}
