import { Pipe, PipeTransform } from '@angular/core';
import { DataResult } from '@progress/kendo-data-query';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {
  transform(result: DataResult): any[] {
    if (!result) {
      return [];
    }

    return result.data.map((item) => item.value);
  }
}
