import { Component, OnInit } from '@angular/core';
import { ContactStateService } from '@dashboard/contact-event-state.service';
import { ContactProxy } from '@tecracer/trccp-streams';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Metadata, MetadataStyle } from './metadata.model';

@Component({
  selector: 'trccp-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  public metadataAttributes!: Observable<Array<Metadata>>;

  constructor(
    private stateService: ContactStateService,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    this.metadataAttributes = this.stateService.onEventStateChanged().pipe(
      switchMap((dashboardState) => {
        if (!dashboardState.payload) {
          return of([]);
        }

        if (
          dashboardState.event === 'CALLING' ||
          dashboardState.event === 'ACW'
        ) {
          const contact = dashboardState.payload as ContactProxy;
          return contact.getAttributes().pipe(
            map((attributes) => this.parseMetadataFromAttributes(attributes)),
            tap((metadata) => this.logger.debug('Metadata ', metadata))
          );
        }
        return of([]);
      })
    );
  }

  private parseMetadataFromAttributes(attributes: any): Array<Metadata> {
    const metadataStore = new Map<string, Metadata>();
    Object.values(attributes).forEach(
      (attribute: { name: string; value: string }) => {
        const [prefix, property] = this.parseAttribute(attribute.name);
        if (prefix.includes('view') && property) {
          if (metadataStore.has(prefix)) {
            let metadata = metadataStore.get(prefix);
            metadata = this.updateMetadata(metadata, property, attribute.value);
            metadataStore.set(prefix, metadata);
          } else {
            let metadata: Metadata = {};
            metadata = this.updateMetadata(metadata, property, attribute.value);
            metadataStore.set(prefix, metadata);
          }
        }
      }
    );
    return Array.from(metadataStore.values());
  }

  private updateMetadata(metadata: Metadata, property: string, value: string) {
    if (property === 'style') {
      metadata[property] = this.parseMetadataStyle(value);
    } else {
      metadata[property] = value;
    }
    return metadata;
  }

  private parseMetadataStyle(styleString: string): MetadataStyle {
    let metadataStyle: MetadataStyle = {};
    const styles = styleString.split(';');

    styles.forEach((s: string) => {
      const style = s.replace(/ /g, '');
      const properties = style.split(':');
      metadataStyle[properties[0]] = properties[1];
    });
    return metadataStyle;
  }

  private parseAttribute(value: string): [prefix: string, property: string] {
    const attributeElements = value.split('_');
    const prefix = `${attributeElements[0]}-${attributeElements[1]}`;
    const property = attributeElements[2];
    return [prefix, property];
  }
}
