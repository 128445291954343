import { Injectable } from '@angular/core';
import {
  SetCallRecordingDocument,
  SetCallRecordingMutation,
  SetCallRecordingMutationVariables,
  UpdateCallRecordingCategoryDocument,
  UpdateCallRecordingCategoryMutation,
  UpdateCallRecordingCategoryMutationVariables
} from '@shared/graphql/types';
import { ContactProxy } from '@tecracer/trccp-streams';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export type RecordingCategory = 'vertrag' | 'coaching';

@Injectable({
  providedIn: 'root'
})
export class RetentionService {
  private $contact = new BehaviorSubject<ContactProxy | undefined>(undefined);

  constructor(private gqlc: Apollo) {}

  public setContact(contact: ContactProxy) {
    this.$contact.next(contact);
  }

  public fromContact() {
    return this.$contact.asObservable();
  }

  public updateCallRecordingCategory(
    contactId: string,
    category: RecordingCategory
  ) {
    return this.gqlc
      .mutate<
        UpdateCallRecordingCategoryMutation,
        UpdateCallRecordingCategoryMutationVariables
      >({
        mutation: UpdateCallRecordingCategoryDocument,
        variables: {
          contactId: contactId,
          category: category
        }
      })
      .pipe(map((result) => result.data?.setCallRecordingCategory));
  }
}
