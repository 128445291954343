<kendo-listview [data]="metadataAttributes | async" class="metadata-list">
  <ng-template
    kendoListViewItemTemplate
    let-dataItem="dataItem"
    let-isLast="isLast"
  >
    <div [ngSwitch]="dataItem.type" class="metadata-tile">
      <div class="icons metadata-tile-type">
        <span *ngSwitchCase="'Phone'"> phone </span>
        <span *ngSwitchCase="'Text'"> notes </span>
        <span *ngSwitchCase="'Date'"> today </span>
        <span *ngSwitchCase="'Mail'"> mail_outline </span>
        <span *ngSwitchCase="'URL'"> insert_link </span>
        <span *ngSwitchDefault> tag </span>
      </div>
      <div>
        <header class="metadata-tile-label">
          {{ dataItem.label | ellipsis : 25 }}
        </header>
        <div class="metadata-tile-value">
          <div *ngSwitchCase="'Date'">
            <span [ngStyle]="dataItem.style">
              {{ dataItem.value | date : dataItem.format || 'dd.MM.YYYY' }}
            </span>
          </div>
          <div *ngSwitchCase="'URL'">
            <a
              target="_blank"
              rel="noopener"
              [href]="dataItem.value | sanitize : 'url'"
              >{{ dataItem.value }}</a
            >
          </div>
          <div *ngSwitchCase="'Phone'">
            <span [ngStyle]="dataItem.style">
              <a [href]="'tel:' + dataItem.value">{{ dataItem.value }}</a>
            </span>
          </div>
          <div *ngSwitchCase="'Mail'">
            <span [ngStyle]="dataItem.style">
              <a [href]="'mailto:' + dataItem.value">{{ dataItem.value }}</a>
            </span>
          </div>
          <div *ngSwitchCase="'Text'">
            <span [ngStyle]="dataItem.style">
              {{ dataItem.value }}
            </span>
          </div>
          <div *ngSwitchDefault>
            <span [ngStyle]="dataItem.style">
              {{ dataItem.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</kendo-listview>
