import { Injectable } from '@angular/core';
import {
  MetricsListDocument,
  MetricsListSubscription,
  MetricsListSubscriptionVariables
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetricDataService {
  constructor(private gqlc: Apollo) {}

  public getCurrentMetrics() {
    return this.gqlc
      .subscribe<MetricsListSubscription, MetricsListSubscriptionVariables>({
        query: MetricsListDocument
      })
      .pipe(map((result) => result.data.subscribeToMetrics));
  }
}
