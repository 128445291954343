import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { SharedModule } from '@shared/shared.module';
import { CategoryPipe } from './category.pipe';
import { WrapUpComponent } from './wrapup.component';

@NgModule({
  declarations: [WrapUpComponent, CategoryPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    TranslateModule.forChild(),
    ButtonsModule,
    LabelModule,
    InputsModule,
    DropDownsModule
  ],
  exports: [WrapUpComponent]
})
export class WrapUpModule {}
