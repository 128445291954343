import { Injectable } from '@angular/core';
import {
  GetAvailableOutboundNumbersDocument,
  GetAvailableOutboundNumbersQuery,
  GetOutboundNumberDocument,
  GetOutboundNumberQuery,
  PhoneNumber,
  SetOutboundNumberDocument,
  SetOutboundNumberMutation,
  SetOutboundNumberMutationVariables
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OutboundDataService {
  constructor(private gqlc: Apollo) {}

  public getOutboundNumbers(): Observable<Array<PhoneNumber>> {
    return this.gqlc
      .query<GetAvailableOutboundNumbersQuery, never>({
        query: GetAvailableOutboundNumbersDocument
      })
      .pipe(map((result) => result.data.getAvailableOutboundNumbers));
  }

  public getOutboundNumber(): Observable<PhoneNumber> {
    return this.gqlc
      .query<GetOutboundNumberQuery, never>({
        query: GetOutboundNumberDocument
      })
      .pipe(map((result) => result.data.getOutboundNumber));
  }

  public setOutboundNumber(phoneNumber: string) {
    return this.gqlc.mutate<
      SetOutboundNumberMutation,
      SetOutboundNumberMutationVariables
    >({
      mutation: SetOutboundNumberDocument,
      variables: {
        phoneNumber: phoneNumber
      }
    });
  }
}
