import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@shared/routes.model';

@Component({
  selector: 'trccp-code',
  templateUrl: './code.component.html',
  styles: ['']
})
export class CodeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate([Routes.Dashboard]);
  }
}
