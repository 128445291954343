<ng-container
  *let="{
    dashboardEvent: dashboardEvent$ | async,
    profileEventState: profileEventState$ | async,
    profilePhone: profilePhone$ | async,
    profiles: profiles$ | async
  } as state"
>
  <div class="profile-container">
    <ng-container
      *ngIf="state.profileEventState.event === 'ON_SEARCH'; else profileDetail"
    >
      <div class="profile-list">
        <kendo-textbox
          class="profile-search"
          placeholder="{{ 'PROFILE.SEARCH.PLACE_HOLDER' | translate }}"
          (valueChange)="onCustomerSearch($event)"
          [value]="state.profilePhone"
        >
          <ng-template kendoTextBoxPrefixTemplate>
            <span class="icons">search</span>
          </ng-template>
        </kendo-textbox>
        <kendo-listview [data]="state.profiles" [loading]="loading">
          <ng-template
            kendoListViewItemTemplate
            let-dataItem="dataItem"
            let-isLast="isLast"
          >
            <trccp-profile-list-item
              [customer]="dataItem"
              [propertyFilter]="profileListFilter"
            >
            </trccp-profile-list-item>
          </ng-template>
          <ng-template kendoListViewFooterTemplate>
            <div class="profile-list__footer">
              {{
                'PROFILE.SEARCH.COUNT'
                  | translate : { count: state.profiles?.length ?? 0 }
              }}
            </div>
          </ng-template>
        </kendo-listview>
        <div class="profile-actions">
          <button
            kendoButton
            [primary]="true"
            *ngIf="state.dashboardEvent !== 'CALLING'"
            (click)="onBack()"
          >
            {{ 'PROFILE.ACTIONS.BACK.TITLE' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #profileDetail>
      <trccp-profile-detail
        [config]="config"
        [isSingleProfile]="state.profileEventState.isSingleProfile"
        [isCalling]="state.dashboardEvent === 'CALLING'"
      ></trccp-profile-detail>
    </ng-template>
  </div>
</ng-container>
