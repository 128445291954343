import { Injectable } from '@angular/core';
import { AppSettings } from 'config/app.settings';
import { ConfigService } from 'config/config.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private settings!: AppSettings;

  constructor(configService: ConfigService) {
    const { settings } = configService.getFrontendConfig();
    this.settings = settings;
  }

  private setItemInternal(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  private getItemInternal(key: string): string | null {
    return localStorage.getItem(key);
  }

  private createInternalKey(key: string) {
    return `${this.settings.storagePrefix}.${key}`;
  }

  public setItem(
    key: string,
    value: any,
    replacer?: (this: any, key: string, value: any) => any
  ) {
    const serializedValue = JSON.stringify(value, replacer);
    this.setItemInternal(this.createInternalKey(key), serializedValue);
  }

  public getItem<T = string>(
    key: string,
    reviver?: (this: any, key: string, value: any) => any
  ): T | null {
    const item = this.getSerializedItem(key);
    return item ? JSON.parse(item, reviver) : null;
  }

  public getSerializedItem(key: string): string | null {
    return this.getItemInternal(this.createInternalKey(key));
  }

  public removeItem(key: string) {
    return localStorage.removeItem(this.createInternalKey(key));
  }

  public clearWithoutLocal() {
    Object.keys(localStorage)
      .filter((key) => !key.startsWith(this.settings.storagePrefix))
      .forEach((key) => localStorage.removeItem(key));
  }

  public clear(): void {
    localStorage.clear();
  }
}
