import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HeaderSupportComponent } from '@header/header-support/header-support.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { BadgeModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AvatarModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconsModule } from '@progress/kendo-angular-icons';
import { SharedModule } from '@shared/shared.module';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { HeaderAccountComponent } from './header-account/header-account.component';
import { HeaderNotificationsComponent } from './header-notifications/header-notifications.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNotificationsComponent,
    HeaderAccountComponent,
    HeaderSupportComponent,
    StatusBadgeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputsModule,
    TranslateModule.forChild(),
    IconsModule,
    PopupModule,
    BadgeModule,
    AvatarModule,
    ButtonModule,
    SharedModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}
