import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { StorageService } from '@shared/local-storage/storage.service';
import { DashboardItem } from '@shared/models/dashboard-item.model';
import { LayoutConfig } from '@shared/models/layout-config.model';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const CONFIG_FILE_PATH = 'assets/config';

@Injectable({
  providedIn: 'root'
})
export class LayoutConfigService {
  private storageService: StorageService;
  private layoutConfig = new LayoutConfig();

  get config(): LayoutConfig {
    return { ...this.layoutConfig };
  }

  set config(value: LayoutConfig) {
    this.layoutConfig = value;
    this.storageService.setItem('layoutConfig', this.layoutConfig);
  }

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private injector: Injector
  ) {}

  loadConfigFromFile(): Observable<LayoutConfig> {
    this.storageService = this.injector.get(StorageService);
    const configPath = `${CONFIG_FILE_PATH}/layout-config.json`;
    this.logger.debug(`Load layoutConfig: ${configPath}`);
    return this.http.get<LayoutConfig>(configPath).pipe(
      tap((config: LayoutConfig) => {
        this.loadLayoutConfig(config);
        this.logger.debug('found layoutConfig: ', this.config);
      }),
      catchError((error) => {
        this.logger.error(
          `layoutConfig file at '${configPath}' could not be found`
        );
        return EMPTY;
      })
    );
  }

  private loadLayoutConfig(config: LayoutConfig): void {
    const configFromStorage: LayoutConfig =
      this.storageService.getItem('layoutConfig');

    if (configFromStorage?.version === config?.version) {
      this.config = configFromStorage;
    } else {
      config?.dashboardItems?.forEach((item) => {
        item.realX = item?.x;
        item.realY = item?.y;
        item.defaultConfig = {
          x: item?.x,
          y: item?.y,
          cols: item?.cols,
          rows: item?.rows,
          expansionLevel: item?.expansion?.expansionLevel
        };
      });

      this.config = config;
    }

    this.layoutConfig.dashboardItems = this.config.dashboardItems?.filter(
      (dashboardItem: DashboardItem) => !dashboardItem.uiDisabled
    );
  }
}
