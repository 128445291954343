import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingProfileStateService {
  private state$ = new Subject<void>();

  public getState() {
    return this.state$.asObservable();
  }

  public trigger() {
    this.state$.next();
  }
}
