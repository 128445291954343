import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CardModule, LayoutModule } from '@progress/kendo-angular-layout';
import { CodeComponent } from './code/code.component';
import { LoginComponent } from './login/login.component';
import { ConnectLoginComponent } from './connect/connect-login.component';

@NgModule({
  declarations: [LoginComponent, CodeComponent, ConnectLoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputsModule,
    LayoutModule,
    LabelModule,
    ButtonsModule,
    IndicatorsModule,
    TranslateModule.forChild()
  ],
  exports: [LoginComponent, CodeComponent]
})
export class AuthModule {}
