<trccp-header></trccp-header>
<div style="height: calc(100vh - 56px); width: 100%">
  <gridster [options]="options">
    <gridster-item
      [item]="item"
      *ngFor="
        let item of dashboardItems | dashboardItems : dashboardEvent;
        trackBy: trackBy
      "
    >
      <ng-container [ngSwitch]="item.componentName">
        <trccp-dashboard-tile
          [class]="item?.className"
          [item]="item"
          (expansionLevelChanged)="changeExpansionLevel(item, $event)"
          (removeIconClicked)="removeItem(item)"
          [editModeEnabled]="editModeEnabled"
          *ngSwitchCase="dashboardItemComponentName.PHONE"
        >
          <trccp-phone [config]="item?.contextObj?.config"></trccp-phone>
        </trccp-dashboard-tile>

        <trccp-dashboard-tile
          [class]="item?.className"
          [item]="item"
          [editModeEnabled]="editModeEnabled"
          (expansionLevelChanged)="changeExpansionLevel(item, $event)"
          (removeIconClicked)="removeItem(item)"
          *ngSwitchDefault
        >
          <ngx-dynamic-hooks
            [context]="item?.contextObj"
            [content]="item?.componentName"
          ></ngx-dynamic-hooks>
        </trccp-dashboard-tile>
      </ng-container>
    </gridster-item>
  </gridster>
</div>
<div kendoDialogContainer></div>
