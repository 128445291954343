import { Injectable } from '@angular/core';
import {
  PhoneBookEntriesDocument,
  PhoneBookEntriesQuery,
  PhoneBookEntriesQueryVariables,
  PhonebookUser
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { InstanceStateService } from '@auth/connect/instance-state.service';

@Injectable({
  providedIn: 'root'
})
export class PhonebookService {
  constructor(private gqlc: Apollo, private instance: InstanceStateService) {}

  public queryPhonebook(search: string): Observable<Array<PhonebookUser>> {
    return this.gqlc
      .query<PhoneBookEntriesQuery, PhoneBookEntriesQueryVariables>({
        query: PhoneBookEntriesDocument,
        variables: {
          search
        }
      })
      .pipe(map((result) => result.data.getPhoneBookEntries));
  }
}
