import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { NotificationType as PopupNotificationType } from '@shared/enums/notification-type';
import { NotificationState, NotificationType } from '@shared/graphql/types';
import { NotificationService } from '@shared/services/notification.service';
import { v4 as uuid } from 'uuid';
import { ErrorService } from './error.service';

interface NestedError {
  errors: Error[];
}

function isNestedError(error: NestedError | Error): error is NestedError {
  return Array.isArray((error as any).errors);
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private notificationService: NotificationService;

  constructor(
    private zone: NgZone,
    private errorService: ErrorService,
    private injector: Injector
  ) {}

  public handleError(error: Error | NestedError): void {
    // if (!isNestedError(error)) {
    //   error = {
    //     errors: [error]
    //   };
    // }
    // this.zone.run(() => {
    //   for (const err of (error as NestedError).errors) {
    //     console.error('GlobalError: ', err);
    //     if (!this.notificationService) {
    //       this.notificationService = this.injector?.get(NotificationService);
    //     }
    //     if (this.notificationService?.isEnabled) {
    //       this.handleNotification(err);
    //     } else {
    //       this.errorService.showMessage(PopupNotificationType.ERROR, null, err);
    //       break;
    //     }
    //   }
    // });
  }

  public handleNotification(error: Error): void {
    const notification = {
      id: uuid(),
      agentId: '',
      timestamp: new Date().toISOString(),
      title: 'InternalError',
      notificationType: NotificationType.Error,
      description: error?.message ?? '',
      payload: error?.stack ?? JSON.stringify(error),
      state: NotificationState.Unread
    };

    if (error instanceof HttpErrorResponse) {
      notification.title = 'HttpError';
      notification.description = error?.message ?? '';
    }

    this.notificationService?.setNotifications([notification]).subscribe();
  }
}
