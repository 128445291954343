import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { InstanceStateService } from '@auth/connect/instance-state.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType } from '@shared/enums/notification-type';
import { Routes } from '@shared/routes.model';
import { AuthService } from 'app/auth/auth.service';
import { ErrorService } from 'app/errors/error.service';
import { ConfigService } from 'config/config.service';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'trccp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @ViewChild('container', { read: ViewContainerRef })
  public container: ViewContainerRef;
  public title!: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private instance: InstanceStateService,
    private errorService: ErrorService,
    configService: ConfigService
  ) {
    this.instance.init();

    const { title } = configService.getFrontendConfig();
    this.title = title;
  }

  public onLogin(): void {
    if (this.checkForInstanceId()) {
      this.auth
        .federatedLogin()
        .pipe(
          take(1),
          tap(() => {
            this.router.navigate([Routes.Dashboard]);
          })
        )
        .subscribe();
    }
  }

  private checkForInstanceId() {
    const hasInstanceId = this.instance.hasInstanceId();
    if (!hasInstanceId) {
      this.errorService.showMessage(
        NotificationType.ERROR,
        this.translate.instant('LOGIN.ERROR.LOGIN_ERROR')
      );
    }
    return hasInstanceId;
  }
}
