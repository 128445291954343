<div class="account">
  <kendo-badge-container>
    <button
      kendoButton
      fillMode="clear"
      size="none"
      #accountAnchor
      (click)="handleAnchorClick($event)"
      class="account__action"
    >
      <kendo-avatar
        themeColor="primary"
        width="48"
        height="48"
        [svgIcon]="icons.account"
      ></kendo-avatar>
    </button>
  </kendo-badge-container>

  <kendo-popup
    [popupAlign]="popupAlign"
    [anchorAlign]="anchorAlign"
    [margin]="{ vertical: 10, horizontal: 0 }"
    [anchor]="accountAnchor"
    (anchorViewportLeave)="toggleIsExpanded(false)"
    [trccpClickOutside]="isExpanded"
    (clickOutside)="toggleIsExpanded()"
    *ngIf="isExpanded"
  >
    <div class="account__content">
      <div class="account__content--header">
        <div class="account__content--header__title">
          {{ 'HEADER.ACCOUNT.TITLE' | translate }}
        </div>
        <div class="account__content--header__edit--mode">
          <span>{{ 'HEADER.ACCOUNT.EDIT_MODE' | translate }}</span>
          <kendo-switch
            (valueChange)="toggleEditMode($event)"
            [(ngModel)]="editModelEnabled"
          ></kendo-switch>
        </div>
      </div>
      <div class="account__content--items">
        <div
          class="account__content--item"
          (click)="openAddDashboardItemDialog($event)"
        >
          <div class="account__content--item__left">
            <span class="icons">playlist_add</span>
          </div>
          <div class="account__content--item__right">
            {{ 'HEADER.ACCOUNT.ADD_DASHBOARD_ITEM' | translate }}
          </div>
        </div>
        <div
          class="account__content--item"
          (click)="openAdministrationView($event)"
        >
          <div class="account__content--item__left">
            <span class="icons">admin_panel_settings</span>
          </div>
          <div class="account__content--item__right">
            {{ 'HEADER.ACCOUNT.GO_TO_ADMINISTRATION' | translate }}
          </div>
          <form id="admin-login-form"></form>
        </div>
        <div
          *ngFor="let menuItem of menu"
          class="account__content--item"
          (click)="onMenuItemClick($event, menuItem)"
        >
          <div class="account__content--item__left">
            <span class="icons"> {{ menuItem.icon }}</span>
          </div>
          <div class="account__content--item__right">
            {{ menuItem.label | translate }}
          </div>
        </div>
        <div class="account__content--item" (click)="onLogoutClick($event)">
          <div class="account__content--item__left">
            <span class="icons">logout</span>
          </div>
          <div class="account__content--item__right">
            {{ 'HEADER.ACCOUNT.LOGOUT' | translate }}
          </div>
        </div>
      </div>
    </div>
  </kendo-popup>
</div>
