<kendo-grid
  [kendoGridBinding]="metricData$ | async"
  [pageable]="pager"
  [selectable]="selectable"
  [filter]="state.filter"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
>
  <kendo-grid-messages
    [noRecords]="'TABLE.CONTROLS.NO_RECORDS' | translate"
    [pagerOf]="'TABLE.CONTROLS.PAGER_OF' | translate"
    [pagerItems]="'TABLE.CONTROLS.PAGER_ITEMS' | translate"
    [pagerFirstPage]="'TABLE.CONTROLS.PAGER_FIRST_PAGE' | translate"
    [pagerLastPage]="'TABLE.CONTROLS.PAGER_LAST_PAGE' | translate"
    [pagerPreviousPage]="'TABLE.CONTROLS.PAGER_PREVIOUS_PAGE' | translate"
    [pagerNextPage]="'TABLE.CONTROLS.PAGER_NEXT_PAGE' | translate"
    [pagerPage]="'TABLE.CONTROLS.PAGER_PAGE' | translate"
  ></kendo-grid-messages>
  <kendo-grid-column
    [title]="'QUEUE_TABLE.QUEUE_NAME.TITLE' | translate"
    [autoSize]="true"
    [width]="150"
    [headerStyle]="headerStyle"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="queue-list__queue-cell" title=" dataItem.queue.name ">
       {{ dataItem.queue.name }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column-group
    [columnMenu]="false"
    [title]="'QUEUE_TABLE.AGENTS.TITLE' | translate"
    [headerStyle]="headerStyle"
  >
    <kendo-grid-column [width]="70" [headerStyle]="headerStyle">
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span [title]="'QUEUE_TABLE.AGENTS.AVAILABLE.TOOLTIP' | translate">
          {{ 'QUEUE_TABLE.AGENTS.AVAILABLE.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          {{ dataItem | metricParser : MetricType.AgentsAvailable }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="60" [headerStyle]="headerStyle">
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span [title]=" 'QUEUE_TABLE.AGENTS.ONLINE.TOOLTIP' | translate ">
          {{ 'QUEUE_TABLE.AGENTS.ONLINE.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          {{ dataItem | metricParser : MetricType.AgentsOnline }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    [columnMenu]="false"
    [title]="'QUEUE_TABLE.CALLER.TITLE' | translate"
    [headerStyle]="headerStyle"
  >
    <kendo-grid-column
      [width]="70"
      [title]="'QUEUE_TABLE.CALLER.CONTACTS_IN_QUEUE.TITLE' | translate"
      [headerStyle]="headerStyle"
    >
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span
          [title]="'QUEUE_TABLE.CALLER.CONTACTS_IN_QUEUE.TOOLTIP' | translate"
        >
          {{ 'QUEUE_TABLE.CALLER.CONTACTS_IN_QUEUE.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          {{ dataItem | metricParser : MetricType.ContactsInQueue }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="70" [headerStyle]="headerStyle">
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span
          [title]="'QUEUE_TABLE.CALLER.CONTACTS_INCOMING.TOOLTIP' | translate"
        >
          {{ 'QUEUE_TABLE.CALLER.CONTACTS_INCOMING.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          {{ dataItem | metricParser : MetricType.ContactsIncoming }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="75" [headerStyle]="headerStyle">
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span [title]="'QUEUE_TABLE.CALLER.WAIT_TIME.TOOLTIP' | translate">
          {{ 'QUEUE_TABLE.CALLER.WAIT_TIME.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          {{ (dataItem | metricParser : MetricType.QueueAnswerTime) * 1000 | date :
          'HH:mm:ss' : 'GMT' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    [columnMenu]="false"
    [title]="'QUEUE_TABLE.SERVICE.TITLE' | translate"
    [headerStyle]="headerStyle"
  >
    <kendo-grid-column [width]="75" [headerStyle]="headerStyle">
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span [title]="'QUEUE_TABLE.SERVICE.SERVICE_LEVEL.TOOLTIP' | translate">
          {{ 'QUEUE_TABLE.SERVICE.SERVICE_LEVEL.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          <kendo-circulargauge
            class="queue-list__chart"
            [colors]="serviceLevelColors"
            [value]="dataItem | metricParser : MetricType.ServiceLevel"
            [scale]="{ max: 100 }"
          >
            <ng-template kendoCircularGaugeCenterTemplate let-value="value">
             {{ value + '%' }}
            </ng-template>
          </kendo-circulargauge>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="75" [headerStyle]="headerStyle">
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <span [title]="'QUEUE_TABLE.SERVICE.HANDLED_QUOTA.TOOLTIP' | translate">
          {{ 'QUEUE_TABLE.SERVICE.HANDLED_QUOTA.TITLE' | translate }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="queue-list__cell">
          <kendo-circulargauge
            class="queue-list__chart"
            [colors]="serviceLevelColors"
            [value]="dataItem | metricParser : MetricType.HandledQuota"
            [scale]="{ max: 100 }"
          >
            <ng-template kendoCircularGaugeCenterTemplate let-value="value">
              {{ value + '%' }}
            </ng-template>
          </kendo-circulargauge>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
</kendo-grid>
