import { Pipe, PipeTransform } from '@angular/core';
import { DashboardItem } from '@shared/models/dashboard-item.model';
import { DashboardItemComponentName } from '@shared/enums/dashboard-item-component-name.enum';
import { ContactEvent } from '@dashboard/contact-event-state.service';

@Pipe({
  name: 'dashboardItems'
})
export class DashboardItemsPipe implements PipeTransform {
  transform(
    dashboardItems: DashboardItem[],
    contactEvent: ContactEvent
  ): DashboardItem[] {
    if (!dashboardItems?.length) {
      return [];
    }

    const dashboardItemsToReturn = [...dashboardItems].filter(
      (item) =>
        !item?.visibilityStates?.length ||
        item?.visibilityStates?.includes(contactEvent)
    );
    const metricsDashboardItem: DashboardItem = dashboardItems?.find(
      (item) => item?.componentName === DashboardItemComponentName.METRICS
    );

    if (!metricsDashboardItem || !metricsDashboardItem?.items?.length) {
      return dashboardItemsToReturn;
    }

    dashboardItemsToReturn.push(...metricsDashboardItem?.items);

    return dashboardItemsToReturn?.filter(
      (item) => item?.id !== metricsDashboardItem?.id
    );
  }
}
