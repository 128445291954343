import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { AuthModule } from '@auth/auth.module';
import { CodeComponent } from '@auth/code/code.component';
import { ConnectLoginComponent } from '@auth/connect/connect-login.component';
import { ConnectLoginResolver } from '@auth/connect/connect-login.resolver';
import { LoginComponent } from '@auth/login/login.component';

const routes: Routes = [
  {
    path: 'code',
    component: CodeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'saml',
    component: ConnectLoginComponent,
    resolve: {
      connectCredentials: ConnectLoginResolver
    }
  },
  {
    path: 'dashboard',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AuthModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
