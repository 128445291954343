import { Injectable } from '@angular/core';
import { StorageService } from '@shared/local-storage/storage.service';
import {
  AuthUser,
  getCurrentUser,
  fetchAuthSession,
  signInWithRedirect,
  signOut
} from 'aws-amplify/auth';
import { AppConfig } from 'config/app.config';
import { ConfigService } from 'config/config.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private config!: AppConfig;
  private authenticated = new BehaviorSubject<boolean>(false);

  constructor(
    private logger: NGXLogger,
    configService: ConfigService,
    private storage: StorageService
  ) {
    this.config = configService.getConfig();
  }

  public getAuthenticatedUser() {
    return from(getCurrentUser());
  }

  public async getAuthenticatedSession() {
    try {
      const currentSession = await fetchAuthSession();
      const { idToken } = currentSession.tokens;
      if (idToken.payload?.exp * 1000 < +new Date()) {
        this.handleSessionExpiration();
        return;
      }

      return currentSession;
    } catch {
      this.handleSessionExpiration();
      return;
    }
  }

  public async getAuthenticatedSessionToken(): Promise<string> {
    const session = await this.getAuthenticatedSession();
    const { accessToken } = session.tokens;
    return accessToken.toString();
  }

  public getAuthenticatedUserToken() {
    return from(this.getAuthenticatedSession()).pipe(
      map((session) => session.tokens.idToken.toString())
    );
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getAuthenticatedUser().pipe(
      map((user: AuthUser) => {
        const validUser = user !== undefined;
        this.authenticated.next(validUser);
        this.logger.debug('user authenticated: ', validUser);
        return true;
      }),
      catchError((error) => {
        this.authenticated.next(false);
        return of(false);
      })
    );
  }

  public federatedLogin() {
    this.logger.info('Federated signin');
    return from(
      signInWithRedirect({
        provider: {
          custom: this.config.backend.oauth.identityProvider
        }
      })
    ).pipe(
      catchError((error: Error) => {
        this.logger.warn('Login: ', error.message);
        return of(false);
      })
    );
  }

  public logout() {
    this.logger.info('Logging out.');
    return from(signOut()).pipe(
      catchError((error: Error) => {
        this.logger.warn('Logout: ', error.message);
        return of(false);
      }),
      finalize(() => {
        this.storage.clearWithoutLocal();
        this.authenticated.next(false);
      })
    );
  }

  private handleSessionExpiration(): void {
    this.logger.error('Session Expired.');
    this.storage.clearWithoutLocal();
    this.authenticated.next(false);
  }
}
