import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

export class LetContext {
  $implicit: any;
  let: any;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[let]'
})
export class LetDirective implements OnInit {
  context = new LetContext();

  @Input()
  set let(value: any) {
    this.context.$implicit = this.context.let = value;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<LetContext>
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
