import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  GetNotificationsDocument,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  Notification,
  NotificationInput,
  NotificationState,
  NotificationUpdateDocument,
  NotificationUpdateSubscription,
  NotificationUpdateSubscriptionVariables,
  SetNotificationsDocument,
  SetNotificationsMutation,
  SetNotificationsMutationVariables
} from '@shared/graphql/types';
import {
  NotificationWorkerMessage,
  NotificationWorkerMessageType
} from '@shared/models/notification-worker-message.model';
import { UiNotification } from '@shared/models/ui-notification.model';
import { ConnectService } from '@shared/services/connect.service';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, EMPTY, merge, Observable, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private enabled = false;
  private internalNotifications: UiNotification[] = [];
  private notificationsSubject: BehaviorSubject<UiNotification[]> =
    new BehaviorSubject<UiNotification[]>(this.internalNotifications);
  private notificationWorker: Worker;

  notifications$: Observable<UiNotification[]> =
    this.notificationsSubject.asObservable();

  get isEnabled(): boolean {
    return this.enabled;
  }

  private set isEnabled(state: boolean) {
    this.enabled = state;
  }

  private get notifications(): UiNotification[] {
    return this.internalNotifications;
  }

  private set notifications(notifications: UiNotification[]) {
    this.internalNotifications = notifications;
    this.notificationsSubject.next(this.internalNotifications);
  }

  constructor(
    private gqlc: Apollo,
    private connect: ConnectService,
    private sanitizer: DomSanitizer
  ) {
    this.addNotificationWebWorker();
  }

  getNotifications(): Observable<Notification[]> {
    return this.connect.getAgentId().pipe(
      switchMap((agentId: string) => {
        return merge(
          this.queryInitialNotifications(agentId),
          this.subscribeToNotificationUpdate(agentId)
        );
      }),
      tap((notifications) => {
        this.initNotifications(notifications as UiNotification[]);
      })
    );
  }

  setNotifications(
    notifications: NotificationInput[],
    fromWorker = false,
    settingNewNotification = true
  ): Observable<Notification[]> {
    if (!this.isEnabled) {
      return;
    }
    if (!settingNewNotification) {
      this.handleUpdateNotificationsStates(notifications);
    }
    return this.connect.getAgentId().pipe(
      switchMap((agentId) => {
        return this.mutateSetNotification(notifications, agentId);
      }),
      tap(() => {
        if (fromWorker) {
          this.clearNotificationsFromLocalDB();
        }
      }),
      catchError((err) => {
        if (!settingNewNotification) {
          this.handleUpdateNotificationsStatesError(notifications);
        }
        if (!fromWorker) {
          this.handleSetNewNotificationError(notifications);
        }
        return EMPTY;
      })
    );
  }

  updateNotificationsStates(
    notificationsIds: string[],
    notificationState: NotificationState
  ): Observable<Notification[]> {
    const notificationsToUpdate: NotificationInput[] = [];
    for (let notificationId of notificationsIds) {
      const notificationOnUI: UiNotification = this.notifications?.find(
        (notification) => notification?.id === notificationId
      );
      if (!notificationOnUI) {
        continue;
      }
      notificationsToUpdate.push({
        id: notificationOnUI.id,
        agentId: notificationOnUI.agentId,
        timestamp: notificationOnUI.timestamp,
        title: notificationOnUI.title,
        notificationType: notificationOnUI.notificationType,
        description: notificationOnUI.description,
        payload: notificationOnUI.payload,
        state: notificationState
      } as NotificationInput);
    }
    return this.setNotifications(notificationsToUpdate, false, false);
  }

  initNotificationWorker(): void {
    this.isEnabled = true;
    this.notificationWorker?.postMessage({
      type: NotificationWorkerMessageType.START_NOTIFICATION_INTERVAL
    } as NotificationWorkerMessage<void>);
  }

  destroyNotificationWorker(): void {
    this.isEnabled = false;
    this.notificationWorker?.postMessage({
      type: NotificationWorkerMessageType.STOP_NOTIFICATION_INTERVAL
    } as NotificationWorkerMessage<void>);
    this.notificationWorker?.postMessage({
      type: NotificationWorkerMessageType.CLEAR_NOTIFICATIONS_FROM_LOCALDB
    } as NotificationWorkerMessage<void>);
  }

  private queryInitialNotifications(
    agentId: string
  ): Observable<Notification[]> {
    return this.gqlc
      .query<GetNotificationsQuery, GetNotificationsQueryVariables>({
        query: GetNotificationsDocument
      })
      .pipe(
        map((result) => {
          return result.data.getNotifications.filter(
            (notification) => notification.agentId === agentId
          );
        })
      );
  }

  private subscribeToNotificationUpdate(
    agentId: string
  ): Observable<Notification[]> {
    return this.gqlc
      .subscribe<
        NotificationUpdateSubscription,
        NotificationUpdateSubscriptionVariables
      >({
        query: NotificationUpdateDocument
      })
      .pipe(
        map((result) => {
          return result.data.subscribeToNotifications.filter(
            (notification) => notification.agentId === agentId
          );
        }),
        map((notifications: Notification[]) => {
          const oldNotifications: UiNotification[] = this.notifications.filter(
            (notification) =>
              notifications.every(
                (newNotification) => newNotification.id !== notification.id
              )
          );
          return [...oldNotifications, ...notifications];
        })
      );
  }

  private mutateSetNotification(
    notifications: NotificationInput[],
    agentId: string
  ): Observable<Notification[]> {
    for (let notification of notifications) {
      notification.agentId = agentId;
    }
    return this.gqlc
      .mutate<SetNotificationsMutation, SetNotificationsMutationVariables>({
        mutation: SetNotificationsDocument,
        variables: {
          notifications: notifications
        }
      })
      .pipe(map((result) => result.data?.setNotifications));
  }

  private initNotifications(notifications: Notification[]) {
    const uiNotifications = notifications.map(
      (notification: Notification): UiNotification => ({
        ...notification,
        ...this.createLog(notification)
      })
    );
    this.notifications = uiNotifications.sort(
      (prev, cur) => Date.parse(cur.timestamp) - Date.parse(prev.timestamp)
    );
  }

  private createLog(notification: Notification) {
    const timestamp = notification?.timestamp
      ? Date.parse(notification?.timestamp)
      : Date.now();
    return {
      logFileName: `Notification-${timestamp}.log`,
      logUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(
          new Blob([notification.payload ?? ''], {
            type: 'application/octet-stream'
          })
        )
      )
    };
  }

  private addNotificationWebWorker(): void {
    if (typeof Worker === 'undefined' || this.notificationWorker) {
      return;
    }
    this.notificationWorker = new Worker(
      new URL('../workers/notification.worker', import.meta.url)
    );
    this.notificationWorker.onmessage = ({
      data
    }: {
      data: NotificationWorkerMessage<any>;
    }) => {
      if (data.type === NotificationWorkerMessageType.SET_NOTIFICATIONS) {
        this.setNotifications(data?.payload, true).subscribe();
      }
    };
  }

  private handleUpdateNotificationsStates(
    notifications: NotificationInput[]
  ): void {
    for (let notification of notifications) {
      const notificationToUpdate: UiNotification = this.notifications?.find(
        (n) => n.id === notification.id
      );
      notificationToUpdate.oldState = notificationToUpdate.state;
      notificationToUpdate.state = notification.state;
    }
    this.initNotifications(this.notifications);
  }

  private handleUpdateNotificationsStatesError(
    notifications: NotificationInput[]
  ): void {
    for (let notification of notifications) {
      const notificationToUpdate: UiNotification = this.notifications?.find(
        (n) => n.id === notification.id
      );
      notificationToUpdate.state = notificationToUpdate.oldState;
    }
    this.initNotifications(this.notifications);
  }

  private handleSetNewNotificationError(
    notifications: NotificationInput[]
  ): void {
    this.notificationWorker?.postMessage({
      type: NotificationWorkerMessageType.ADD_NOTIFICATION_TO_LOCALDB,
      payload: notifications[0]
    } as NotificationWorkerMessage<NotificationInput>);
    const newNotifications: UiNotification[] = [
      ...(notifications as UiNotification[]),
      ...this.internalNotifications
    ];
    this.initNotifications(newNotifications);
  }

  private clearNotificationsFromLocalDB(): void {
    this.notificationWorker?.postMessage({
      type: NotificationWorkerMessageType.CLEAR_NOTIFICATIONS_FROM_LOCALDB
    } as NotificationWorkerMessage<void>);
  }
}
