import { Injectable } from '@angular/core';
import {
  GetRoutingProfileDocument,
  GetRoutingProfileQuery,
  GetRoutingProfileQueryVariables,
  GetRoutingProfilesDocument,
  GetRoutingProfilesQuery,
  GetRoutingProfilesQueryVariables,
  RoutingProfile,
  RoutingProfileInput,
  SetRoutingProfileDocument,
  SetRoutingProfileMutation,
  SetRoutingProfileMutationVariables
} from '@shared/graphql/types';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingProfileDataService {
  constructor(private gqlc: Apollo) {}

  public getRoutingProfiles(agentId: string): Observable<RoutingProfile[]> {
    return this.gqlc
      .query<GetRoutingProfilesQuery, GetRoutingProfilesQueryVariables>({
        query: GetRoutingProfilesDocument,
        fetchPolicy: environment.fetchPolicy,
        variables: {
          agentId
        }
      })
      .pipe(map((result) => result.data.getRoutingProfiles));
  }

  public getRoutingProfile(agentId: string): Observable<RoutingProfile> {
    return this.gqlc
      .query<GetRoutingProfileQuery, GetRoutingProfileQueryVariables>({
        query: GetRoutingProfileDocument,
        fetchPolicy: environment.fetchPolicy,
        variables: {
          agentId
        }
      })
      .pipe(map((result) => result.data.getRoutingProfile));
  }

  public setRoutingProfile(
    routingProfile: RoutingProfileInput
  ): Observable<boolean> {
    return this.gqlc
      .mutate<SetRoutingProfileMutation, SetRoutingProfileMutationVariables>({
        mutation: SetRoutingProfileDocument,
        variables: {
          routingProfile
        }
      })
      .pipe(map((result) => result.data.setRoutingProfile ?? false));
  }
}
