import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { process } from '@progress/kendo-data-query';
import { WrapUpMatching, WrapUpReason } from '@shared/graphql/types';
import { InfoService } from '@shared/services/info.service';
import { Timer } from '@shared/timer/timer';
import { Utility } from '@shared/util/utility';
import { ContactProxy } from '@tecracer/trccp-streams';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, throwError } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { WrapUpDataService } from './wrapup-data.service';
import { WrapUpComponent } from './wrapup.component';
import { WrapupResult } from './wrapup.result';

@Injectable({
  providedIn: 'root'
})
export class WrapUpService {
  private dialogRef: DialogRef;
  private timeoutTimer: Timer;

  constructor(
    private logger: NGXLogger,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private infoService: InfoService,
    private wrapUpDataService: WrapUpDataService
  ) {
    this.timeoutTimer = new Timer();
    this.timeoutTimer.onEnded(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }

  public openWrapup(contact: ContactProxy) {
    return combineLatest([
      contact.getOriginalContactId(),
      this.getWrapupReasons(contact),
      this.wrapUpDataService.getWrapUpTime()
    ]).pipe(
      tap(([originalContactId, wrapupData, wrapupTime]) => {
        if (!this.dialogRef && wrapupData.length > 0) {
          this.dialogRef = this.dialogService.open({
            title: this.translateService.instant('WRAP_UP.TITLE'),
            content: WrapUpComponent,
            preventAction: (ev, dialog) => {
              const component = dialog.content.instance as WrapUpComponent;
              return !component.form.valid;
            },
            width: 450,
            height: 320
          });

          this.dialogRef.content.instance.wrapupTime = wrapupTime;
          this.dialogRef.content.instance.wrapupData = process(wrapupData, {
            group: [{ field: 'category' }],
            sort: [{ field: 'name', dir: 'asc' }]
          });
          this.timeoutTimer.setStartTime(wrapupTime.expiration);
          this.timeoutTimer.start();

          this.dialogRef.result
            .pipe(
              take(1),
              tap((result) => {
                if (result instanceof WrapupResult) {
                  const { wrapups } = result;
                  this.doApplyWrapUps(
                    contact.contactId,
                    originalContactId,
                    wrapups
                  );
                }
              }),
              finalize(() => {
                this.timeoutTimer.stop();
                this.dialogRef = null;
              })
            )
            .subscribe();
        }
      })
    );
  }

  private getWrapupReasons(contact: ContactProxy) {
    return contact.getQueue().pipe(
      map((queue) => Utility.getQueueId(queue)),
      switchMap((queueId) =>
        this.wrapUpDataService
          .getWrapUpReasons(queueId, WrapUpMatching.Strict)
          .pipe(shareReplay(1))
      )
    );
  }

  private doApplyWrapUps(
    contactId: string,
    originalContactId: string,
    wrapups: Array<WrapUpReason>
  ) {
    this.logger.debug(`save wrap-up reasons for contactId ${contactId}`);
    if (contactId) {
      const selectedWrapUpNames = wrapups.map((wrapup) => wrapup.name);
      this.wrapUpDataService
        .setWrapUpReasons(contactId, originalContactId, selectedWrapUpNames)
        .pipe(
          take(1),
          tap(() =>
            this.infoService.showMessage(
              this.translateService.instant('WRAP_UP.NOTIFICATION.SUCCESS'),
              'success'
            )
          ),
          catchError((err) => {
            this.infoService.showMessage(
              this.translateService.instant(
                'WRAP_UP.ERRORS.CONTACT_ERROR.TITLE'
              ),
              'error'
            );
            return throwError(() => err);
          })
        )
        .subscribe();
    } else {
      this.infoService.showMessage(
        this.translateService.instant('WRAP_UP.ERRORS.CONTACT_ERROR.TITLE'),
        'error'
      );
    }
  }
}
