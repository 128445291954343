<div class="outbound">
  <kendo-dropdownlist
    class="outbound__select"
    [data]="outboundPhoneNumbers$ | async"
    [value]="outboundPhoneNumber$ | async"
    [kendoDropDownFilter]="outboundFilter"
    (selectionChange)="onOutboundSelected($event)"
    textField="description"
    valueField="phoneNumber"
  >
  <kendo-dropdownlist-messages
    [noDataText]="'PHONE.OUTBOUND.NO_DATA' | translate"
  ></kendo-dropdownlist-messages>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      @if(dataItem | phoneNumberValid) {
        <span> {{ dataItem.phoneNumber}} </span>
      } @else {
        <span>{{
          'PHONE.OUTBOUND.PLACEHOLDER' | translate
        }}</span>
      }
    </ng-template>
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <div class="outbound__item">
        <span [title]="dataItem.phoneNumber" class="outbound__item--value">{{
          dataItem.phoneNumber
        }}</span>
        @if(dataItem.description) {
          <span
          [title]="dataItem.description"
          class="outbound__item--label"
        >
          {{ dataItem.description }}
        </span>
        }
      </div>
    </ng-template>
  </kendo-dropdownlist> 
  <button
    kendoButton
    [svgIcon]="icons.send"
    fillMode="solid"
    themeColor="success"
    [disabled]="selectedOutbound?.phoneNumber === null"
    title="{{ 'PHONE.OUTBOUND.TOOLTIPS.SAVE_OUTBOUND' | translate }}"
    class="outbound__action"
    (click)="submitOutboundNumber()"
  ></button>
</div>
