import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  WritableSignal,
  signal
} from '@angular/core';
import { CircularGaugeScale } from '@progress/kendo-angular-gauges';
import { Timer } from './timer';

@Component({
  selector: 'trccp-timer',
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss'
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input()
  set totalTime(value: number) {
    this.timer.setStartTime(value);
    this.time$.set(value);
    this.timerScale.max = value;
  }

  @Input() public width = 55;
  @Input() public height = 55;

  @Output() public timeEnded = new EventEmitter();

  public time$: WritableSignal<number> = signal(0);

  public timerScale: CircularGaugeScale = {
    rangeSize: 5
  };

  private timer!: Timer;

  constructor() {
    this.timer = new Timer();
  }

  ngOnInit(): void {
    this.timer.start();
    this.timer.onTick((remainingTime: number) => {
      this.time$.set(remainingTime);
    });
    this.timer.onEnded(() => {
      this.timeEnded.emit();
    });
  }

  ngOnDestroy(): void {
    if (this.timer) {
      this.timer.stop();
    }
  }
}
