<div class="routing">
  <p class="routing__description">
    {{ 'ROUTING_PROFILE.DESCRIPTION.TEXT' | translate }}
  </p>
  <div *let="routingProfiles$ | async as routingProfiles" class="routing__controls">
    <kendo-combobox
      [data]="routingProfiles ?? []"
      [value]="routingProfile$ | async"
      [disabled]="routingProfiles?.length === 0"
      [clearButton]="false"
      [kendoDropDownFilter]="routingFilter"
      textField="routingProfileName"
      valueField="routingProfileId"
      (valueChange)="onRoutingProfileChanged($event)"
    >
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <span>{{
          dataItem?.routingProfileName ?? 'ROUTING_PROFILE.LIST.PLACEHOLDER'
            | translate
        }}</span>
      </ng-template>
    </kendo-combobox>
    <button
      kendoButton
      [svgIcon]="icons.set"
      themeColor="success"
      fillMode="solid"
      [title]="'ROUTING_PROFILE.APPLY.TOOLTIP' | translate"
      [disabled]="selectedRoutingProfile === null || routingProfiles?.length === 0"
      class="routing__controls__apply"
      (click)="onApplyRoutingProfile()"
    ></button>
  </div>
</div>
