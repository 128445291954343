<h3>{{ 'DIALOGS.ADD_DASHBOARD_ITEM.AVAILABLE_ITEMS' | translate }}</h3>
<div class="dashboard__items" *ngIf="notSelectedDashboardItems?.length">
  <div
    *ngFor="let dashboardItem of notSelectedDashboardItems"
    class="dashboard__item"
    [class.active]="dashboardItem?.uiSelected"
    (click)="toggleDashboardItem(dashboardItem)"
  >
    <div
      *ngIf="dashboardItem?.visibilityStates"
      class="dashboard__item--states"
    >
      <div
        *ngFor="let state of dashboardItem?.visibilityStates"
        [style.background-color]="state | legendColor"
        class="dashboard__item--states__state"
        title="{{
          'DIALOGS.ADD_DASHBOARD_ITEM.LEGEND.STATES.' + state | translate
        }}"
      ></div>
    </div>
    <div class="dashboard__item--box">
      <i class="icons tile__icon"> {{ dashboardItem?.mIcon }}_icon </i>
    </div>
    <div
      class="dashboard__item--title"
      [title]="dashboardItem?.title | translate"
    >
      {{ dashboardItem?.title | translate }}
    </div>
    <div
      *ngIf="dashboardItem?.info"
      kendoTooltip
      filter=".dashboard__item--tooltip__title"
      class="tooltip__icon"
      tooltipClass="dashboard__item--tooltip"
      [tooltipTemplate]="template"
    >
      <ng-template #template let-anchor>
        <div
          [innerHTML]="anchor.nativeElement.getAttribute('data-description')"
        ></div>
      </ng-template>

      <div
        class="dashboard__item--tooltip__title"
        [attr.data-description]="dashboardItem?.info | translate"
      >
        <i class="k-icon k-i-info"></i>
      </div>
    </div>
  </div>
</div>
<div class="dashboard__items--empty" *ngIf="!notSelectedDashboardItems?.length">
  {{ 'DIALOGS.ADD_DASHBOARD_ITEM.EVERYTHING_ADDED' | translate }}
</div>

<h3>{{ 'DIALOGS.ADD_DASHBOARD_ITEM.SELECTED_ITEMS' | translate }}</h3>
<div class="dashboard__items--visible" *ngIf="selectedDashboardItems?.length">
  <div
    *ngFor="let dashboardItem of selectedDashboardItems"
    class="dashboard__item--visible"
  >
    <div
      class="dashboard__item--box"
      [title]="dashboardItem?.title | translate"
    >
      <i class="icons tile__icon"> {{ dashboardItem?.mIcon }}_icon </i>
    </div>
  </div>
</div>

<legend class="legend">
  <ul>
    <li>
      <h4>{{ 'DIALOGS.ADD_DASHBOARD_ITEM.LEGEND.TITLE' | translate }}</h4>
    </li>
    <li *ngFor="let state of CallingState | keyvalue">
      <div [style.background-color]="state.value | legendColor"></div>
      <p>
        {{
          'DIALOGS.ADD_DASHBOARD_ITEM.LEGEND.STATES.' + state.value | translate
        }}
      </p>
    </li>
  </ul>
</legend>
<kendo-dialog-actions layout="start">
  <button kendoButton (click)="closeDialog()">
    {{ 'DIALOGS.ADD_DASHBOARD_ITEM.ACTIONS.CANCEL' | translate }}
  </button>
  <button
    kendoButton
    (click)="save()"
    [primary]="true"
    [disabled]="!dashboardItemsIdsToSelect.length"
  >
    {{ 'DIALOGS.ADD_DASHBOARD_ITEM.ACTIONS.SAVE' | translate }}
  </button>
</kendo-dialog-actions>
