import { Component, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DataResult } from '@progress/kendo-data-query';
import { WrapUpReason, WrapUpTime } from '@shared/graphql/types';
import { Timer } from '@shared/timer/timer';
import { WrapupResult } from './wrapup.result';

@Component({
  selector: 'trccp-wrapup',
  templateUrl: './wrapup.component.html',
  styleUrls: ['./wrapup.component.scss']
})
export class WrapUpComponent extends DialogContentBase {
  public form: FormGroup;
  public wrapupTime: WrapUpTime;
  public wrapupData: DataResult;
  public category$ = signal('');
  public classifications$ = signal([]);

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  private timer: Timer;

  constructor(public dialog: DialogRef, private formBuilder: FormBuilder) {
    super(dialog);
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      category: [null, Validators.required],
      classification: [null, Validators.required]
    });
  }

  public onCategorySelected(category: string) {
    this.category$.set(category);
    const classifications = this.wrapupData.data.filter(
      (item) => item.value === category
    );
    this.classifications$.set(classifications[0]?.items ?? []);
  }

  public onConfirm(e: Event) {
    e.preventDefault();
    this.form.markAllAsTouched();
    const classificationField = this.form.get('classification');
    this.dialog.close(
      new WrapupResult([classificationField.value as WrapUpReason])
    );
  }

  public onReset() {
    this.form.reset();
    this.category$.set('');
    this.classifications$.set([]);
  }
}
