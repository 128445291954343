import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerComponent } from './timer.component';
import { GaugesModule } from '@progress/kendo-angular-gauges';

@NgModule({
  declarations: [TimerComponent],
  imports: [CommonModule, GaugesModule],
  exports: [TimerComponent]
})
export class TimerModule {}
