import { Pipe, PipeTransform } from '@angular/core';
import { TemplateColumn } from './column.model';

@Pipe({
  name: 'columnTransform'
})
export class ColumnTransformPipe implements PipeTransform {
  transform(column: TemplateColumn, data: any) {
    return column.transformFn ? column.transformFn(data) : data;
  }
}
