import { Pipe, PipeTransform } from '@angular/core';
import {
  CurrentMetric,
  CurrentQueueMetric,
  MetricType
} from '@shared/graphql/types';

@Pipe({
  name: 'metricParser'
})
export class MetricParserPipe implements PipeTransform {
  transform(value: CurrentQueueMetric, type: MetricType): number {
    const metrics = value?.metrics;
    return metrics?.length > 0 ? this.parseMetricValue(metrics, type) : 0;
  }

  private parseMetricValue(
    metrics: Array<CurrentMetric>,
    type: MetricType
  ): number {
    const currentMetric = metrics.find((metric) => metric.metric === type);
    return currentMetric ? currentMetric.value : 0;
  }
}
