<div [class.tile]="!hide">
  <header *ngIf="item?.title" class="tile-header">
    <span
      class="tile-header__title"
      [title]="item?.title | translate"
      [class.drag--enabled]="editModeEnabled && item?.dragEnabled"
    >
      {{ item?.title | translate }}
    </span>

    <ng-container *ngIf="editModeEnabled">
      <button
        kendoButton
        [title]="'TILE.CONTROLS.DRAG' | translate"
        themeColor="inverse"
        fillMode="none"
        class="tile__drag drag-handler"
        *ngIf="item?.dragEnabled"
        [svgIcon]="icons.drag"
        (click)="changeExpansionLevel(dashboardItemExpansionLevel.EXPANDED)"
      ></button>
      <div class="tile__controls">
        <ng-container *ngIf="item?.expansion?.enabled">
          <button
            kendoButton
            class="tile__controls__button"
            [title]="'TILE.CONTROLS.EXPAND' | translate"
            themeColor="inverse"
            fillMode="none"
            *ngIf="
              item?.expansion?.expansionLevel ===
              dashboardItemExpansionLevel?.COLLAPSED
            "
            [svgIcon]="icons.maximize"
            (click)="changeExpansionLevel(dashboardItemExpansionLevel.EXPANDED)"
          ></button>
          <button
            kendoButton
            class="tile__controls__button"
            [title]="'TILE.CONTROLS.COLLAPSE' | translate"
            themeColor="inverse"
            fillMode="none"
            *ngIf="
              item?.expansion?.expansionLevel ===
              dashboardItemExpansionLevel?.EXPANDED
            "
            [svgIcon]="icons.minimize"
            (click)="
              changeExpansionLevel(dashboardItemExpansionLevel.COLLAPSED)
            "
          ></button>
        </ng-container>
        <button
          kendoButton
          class="tile__controls__button"
          [title]="'TILE.CONTROLS.DELETE' | translate"
          themeColor="inverse"
          fillMode="none"
          *ngIf="item?.removable"
          [svgIcon]="icons.delete"
          (click)="removeItem()"
        ></button>
      </div>
    </ng-container>
  </header>
  <div [class.tile-content]="!hide" class="gridster-item-content">
    <ng-content></ng-content>
  </div>
</div>
