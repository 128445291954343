import { Component, Input, OnInit } from '@angular/core';
import { ContactStateService } from '@dashboard/contact-event-state.service';
import { Customer } from '@shared/graphql/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileEventStateService } from '../profile-event-state.service';
import { ProfileConfig, ProfileDetailViewMode } from '../profile.config';

@Component({
  selector: 'trccp-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit {
  @Input() isSingleProfile = false;
  @Input() isCalling = false;
  @Input('config') profileConfig: ProfileConfig;

  public widgetHeight = 640;
  public profileHeight = 540;
  public profile$: Observable<Customer>;

  public detailViewMode: ProfileDetailViewMode;
  public profileFilter: Array<string>;
  public attributeFilter: Array<string>;

  private internalProfileFilter = ['profileId', 'attributes'];

  constructor(
    private profileState: ProfileEventStateService,
    private stateService: ContactStateService
  ) {}

  ngOnInit(): void {
    this.detailViewMode = this.profileConfig?.detailViewMode;
    this.profileFilter = this.profileConfig?.profileFilter
      ? [...this.profileConfig?.profileFilter, ...this.internalProfileFilter]
      : this.internalProfileFilter;
    this.attributeFilter = this.profileConfig?.attributeFilter ?? [];

    this.profile$ = this.profileState.onProfileEventState().pipe(
      filter((state) => !!state.profile),
      map((state) => state.profile)
    );
  }

  public onBack() {
    if (this.isSingleProfile) {
      this.stateService.publishEvent('IDLE');
    }
    this.profileState.publishProfileEvent('ON_SEARCH');
  }
}
