import { Pipe, PipeTransform } from '@angular/core';
import { CustomerAttribute } from '@shared/graphql/types';

@Pipe({
  name: 'profileAttributes'
})
export class ProfileAttributePipe implements PipeTransform {
  transform(customerAttributes: Array<CustomerAttribute>): any {
    const attributes = {};

    if (customerAttributes) {
      customerAttributes.forEach((a: CustomerAttribute) => {
        attributes[a.key] = a.value;
      });
    }

    return attributes;
  }
}
