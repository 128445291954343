import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '@shared/shared.module';
import { ColumnAlignPipe } from '../column-align.pipe';
import { ColumnStylePipe } from '../column-style.pipe';
import { ColumnTransformPipe } from '../column-transform.pipe';
import { CallDataBindingDirective } from './call-data-binding.directive';
import { CallHistoryComponent } from './call-history.component';

@NgModule({
  declarations: [
    CallHistoryComponent,
    ColumnStylePipe,
    ColumnTransformPipe,
    ColumnAlignPipe,
    CallDataBindingDirective
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    ButtonsModule,
    GridModule
  ],
  exports: [CallHistoryComponent]
})
export class CallHistoryModule {}
