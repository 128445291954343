import { Injectable } from '@angular/core';
import { FetchPolicy } from '@apollo/client/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State
} from '@progress/kendo-data-query';
import {
  CallEntry,
  CallHistoryDocument,
  CallHistoryQuery,
  CallHistoryQueryVariables,
  CallHistoryUpdateDocument,
  CallHistoryUpdateSubscription,
  CallHistoryUpdateSubscriptionVariables,
  CallStatus,
  QueryCallHistoryOptionsInput
} from '@shared/graphql/types';
import { ConnectService } from '@shared/services/connect.service';
import { Apollo } from 'apollo-angular';
import uniqBy from 'lodash.uniqby';
import { NGXLogger } from 'ngx-logger';
import { merge, Observable } from 'rxjs';
import { filter, map, scan, switchMap } from 'rxjs/operators';

const FETCH_WITH_SUBSCRIPTION_UPDATE: FetchPolicy = 'network-only';

@Injectable({
  providedIn: 'root'
})
export class CallDataService {
  constructor(
    private gqlc: Apollo,
    private connectService: ConnectService,
    private logger: NGXLogger
  ) {}

  public getCallHistory(state: State): Observable<GridDataResult> {
    this.logger.debug('history state: ', state);
    return this.connectService.getAgentId().pipe(
      switchMap(() =>
        merge(this.queryCallHistory(state), this.subscribeCallHistory(state))
      ),
      scan<GridDataResult, GridDataResult>((current, update) => {
        const gridData: GridDataResult = {
          data:
            state.skip === 0
              ? [...update.data, ...current.data].slice(
                  state.skip,
                  state.skip + state.take
                )
              : current.data,
          total: current.total + update.total
        };
        const uniqueGridData: GridDataResult =
          this.enforceUniqueGridData<CallEntry>(
            gridData,
            (entry: CallEntry) => entry.contactId
          );
        this.logger.debug('history data: ', uniqueGridData);
        return uniqueGridData;
      }),
      map((result: GridDataResult) => ({
        ...result,
        data: this.toDate(result.data)
      }))
    );
  }

  public queryCallHistory(state: State): Observable<GridDataResult> {
    return this.gqlc
      .query<CallHistoryQuery, CallHistoryQueryVariables>({
        query: CallHistoryDocument,
        fetchPolicy: FETCH_WITH_SUBSCRIPTION_UPDATE,
        variables: {
          options: this.toQueryOptions(state)
        }
      })
      .pipe(
        map((result) => ({
          data: result.data.getCallHistory.history,
          total: result.data.getCallHistory.total
        }))
      );
  }

  public subscribeCallHistory(state: State): Observable<GridDataResult> {
    this.logger.debug('history update for agent: ', state);
    const agentId = this.fromFilter<string>(state, 'AgentId');
    const callStatusFilter = this.fromFilter<string>(state, 'CallStatus');
    return this.gqlc
      .subscribe<
        CallHistoryUpdateSubscription,
        CallHistoryUpdateSubscriptionVariables
      >({
        query: CallHistoryUpdateDocument
      })
      .pipe(
        map((result) => result.data.subscribeToCallHistory),
        filter((call: CallEntry) =>
          agentId
            ? call.agent?.agentId === agentId ||
              callStatusFilter === CallStatus.Missed
            : true
        ),
        map((call: CallEntry) => ({
          data: [call],
          total: 1
        })),
        scan<GridDataResult, GridDataResult>((current, update) => {
          const callHistoryResult: GridDataResult = {
            data: [...update.data, ...current.data],
            total: current.total + update.total
          };
          this.logger.debug('history result: ', callHistoryResult);
          return callHistoryResult;
        })
      );
  }

  public enforceUniqueGridData<T>(
    dataResult: GridDataResult,
    compareFn: (entry: T) => unknown
  ): GridDataResult {
    const uniqueData = uniqBy(dataResult.data, compareFn);
    const duplicateCount = Math.abs(dataResult.data.length - uniqueData.length);
    const totalResultCount = Math.max(dataResult.total - duplicateCount, 0);
    const uniqueGridData: GridDataResult = {
      data: uniqueData,
      total: totalResultCount
    };
    this.logger.debug('history data duplication check: ', uniqueGridData);
    return uniqueGridData;
  }

  private fromFilter<T>(state: State, field: string): T | undefined {
    const appliedFilter = state.filter?.filters.find(
      (filter: FilterDescriptor | CompositeFilterDescriptor) =>
        this.isFilterDescriptor(filter) && filter.field === field
    ) as FilterDescriptor;
    return appliedFilter?.value as T;
  }

  private isFilterDescriptor(filter: any): filter is FilterDescriptor {
    return filter.field !== undefined;
  }

  private toQueryOptions(state: State): QueryCallHistoryOptionsInput {
    const queryOptions: QueryCallHistoryOptionsInput = {
      skip: state.skip,
      take: state.take,
      filter: state.filter as any
    };
    return queryOptions;
  }

  private toDate(calls: Array<Partial<CallEntry>>) {
    return calls.map((call: CallEntry) => {
      return {
        ...call,
        time: new Date(call.time)
      };
    });
  }
}
