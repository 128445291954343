import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import {
  ContactEvent,
  ContactEventState,
  ContactStateService
} from '@dashboard/contact-event-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactProxy, StreamsConfig } from '@tecracer/trccp-streams';
import { ConnectConfigService } from 'config/connect-config.service';
import { takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base-component.directive';
import { PhoneConfig } from './phone.config';
import { RecordingService } from './recorder/recording.service';
import { RetentionService } from './retention/retention.service';

@Component({
  selector: 'trccp-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  animations: [
    trigger('slideVerticalAnimation', [
      transition(':enter', [
        style({ height: 0 }),
        animate('300ms ease-in-out', style({ height: '38px' }))
      ]),
      transition(':leave', [animate('300ms ease-in-out', style({ height: 0 }))])
    ])
  ]
})
export class PhoneComponent extends BaseComponentDirective implements OnInit {
  @Input('config') config: PhoneConfig;

  public streamsConfig: StreamsConfig;
  public withRecorder = false;
  public withOutbound = false;
  public dashboardEvent: ContactEvent = 'IDLE';

  constructor(
    private translateService: TranslateService,
    private connectConfigService: ConnectConfigService,
    private stateService: ContactStateService,
    private recordingService: RecordingService,
    private retentionService: RetentionService
  ) {
    super();
  }

  ngOnInit(): void {
    const { enableOutbound, enableRecorder } = this.config;

    this.withOutbound = enableOutbound;
    this.withRecorder = enableRecorder;

    this.initPhone();
    this.registerDashboardHook();
  }

  private registerDashboardHook() {
    this.stateService
      .onEventStateChanged()
      .pipe(
        tap((state: ContactEventState<ContactProxy>) => {
          this.dashboardEvent = state.event;
          if (state.event === 'CALLING') {
            this.recordingService.setContact(state.payload);
            this.retentionService.setContact(state.payload);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private initPhone() {
    const connectConfig = this.connectConfigService.getConnectInstanceConfig();

    this.streamsConfig = {
      ...connectConfig.streamsConfig,
      ccpConfig: {
        ...connectConfig.streamsConfig.ccpConfig,
        storageAccess: {
          mode: 'custom',
          custom: {
            hideCCP: false,
            header: ''
          }
        }
      },
      notificationConfig: {
        enableCustomNotification: true,
        notificationTitle: this.translateService.instant(
          'CCP.NOTIFICATION.TITLE'
        ),
        notificationMessage: this.translateService.instant(
          'CCP.NOTIFICATION.MESSAGE'
        )
      }
    } as StreamsConfig;
  }
}
