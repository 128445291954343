export enum DashboardItemComponentName {
  PHONE = '<trccp-phone></trccp-phone>',
  PHONEBOOK = '<trccp-phonebook></trccp-phonebook>',
  CALL_HISTORY = '<trccp-call-history></trccp-call-history>',
  STAFF = '<trccp-staff></trccp-staff>',
  METADATA = '<trccp-metadata></trccp-metadata>',
  METRICS = '<trccp-metrics></trccp-metrics>',
  PROFILE = '<trccp-profile></trccp-profile>',
  WRAP_UP = '<trccp-wrapup></trccp-wrapup>',
  METRIC_TABLE = '<trccp-metric-table></trccp-metric-table>',
  ROUTING = '<trccp-routing-profile></trccp-routing-profile>'
}
