import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderModule } from '@header/header.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { SharedModule } from '@shared/shared.module';
import { GridsterModule } from 'angular-gridster2';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import { CallHistoryModule } from './call-history/call-history.module';
import { DashboardItemDialogComponent } from './dashboard-item-dialog.component';
import { DashboardItemsPipe } from './dashboard-items.pipe';
import { DashboardTileComponent } from './dashboard-tile.component';
import { DashboardComponent } from './dashboard.component';
import { MetadataModule } from './metadata/metadata.module';
import { MetricsModule } from './metrics/metrics.module';
import { PhoneModule } from './phone/phone.module';
import { PhonebookModule } from './phonebook/phonebook.module';
import { ProfileModule } from './profile/profile.module';
import { QueueListModule } from './queue-list/queue-list.module';
import { StaffModule } from './staff/staff.module';
import { WrapUpModule } from '../wrapup/wrapup.module';
import { RoutingProfileModule } from './routing-profiles/routing-profile.module';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardTileComponent,
    DashboardItemDialogComponent,
    DashboardItemsPipe
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    ButtonsModule,
    DialogsModule,
    TooltipsModule,
    CommonModule,
    HeaderModule,
    PhoneModule,
    MetricsModule,
    QueueListModule,
    StaffModule,
    MetadataModule,
    ProfileModule,
    CallHistoryModule,
    WrapUpModule,
    GridsterModule,
    DynamicHooksModule,
    PhonebookModule,
    RoutingProfileModule
  ],
  exports: [DashboardComponent],
  providers: []
})
export class DashboardModule {}
