import { Component, Input } from '@angular/core';
import { Customer } from '@shared/graphql/types';
import { ProfileEventStateService } from '../profile-event-state.service';

@Component({
  selector: 'trccp-profile-list-item',
  templateUrl: './profile-list-item.component.html',
  styleUrls: ['./profile-list-item.component.scss']
})
export class ProfileListItemComponent {
  @Input() public customer: Customer;
  @Input() public propertyFilter: Array<string>;

  constructor(private profileEventService: ProfileEventStateService) {}

  public onProfileDetail(customer: Customer) {
    this.profileEventService.publishProfileEvent('ON_PROFILE_DETAIL', customer);
  }
}
