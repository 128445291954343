import { Injectable, ViewContainerRef } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { finalize, take } from 'rxjs/operators';

export type InfoType = 'none' | 'success' | 'warning' | 'error' | 'info';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  private isActive = false;

  constructor(private notificationService: NotificationService) {}

  public showMessage(
    message: string,
    type: InfoType,
    anchor?: ViewContainerRef
  ) {
    if (!this.isActive) {
      this.isActive = true;
      this.notificationService
        .show({
          content: message,
          appendTo: anchor,
          animation: { type: 'slide', duration: 200 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: type, icon: true }
        })
        .afterHide.pipe(
          take(1),
          finalize(() => {
            this.isActive = false;
          })
        )
        .subscribe();
    }
  }
}
