import { Component, Input, OnInit } from '@angular/core';
import { ContactStateService } from '@dashboard/contact-event-state.service';
import { OutboundStateService } from '@dashboard/phone/outbound/outbound-state.service';
import {
  PagerSettings,
  SelectableSettings,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import {
  FilterDescriptor,
  FilterOperator,
  State
} from '@progress/kendo-data-query';
import { CallEntry, CallStatus } from '@shared/graphql/types';
import { ConnectService } from '@shared/services/connect.service';
import { LayoutService } from '@shared/services/layout.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base-component.directive';
import { CallIcon, Columns } from '../column.model';
import { CallHistoryConfig } from './call-history.config';

@Component({
  selector: 'trccp-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss']
})
export class CallHistoryComponent
  extends BaseComponentDirective
  implements OnInit
{
  @Input('config') config: CallHistoryConfig;

  public state: State = {
    skip: 0,
    take: 10
  };

  public pager: PagerSettings = {
    buttonCount: 4,
    info: true,
    type: 'numeric'
  };

  public selectable: SelectableSettings = {
    enabled: true,
    checkboxOnly: false,
    mode: 'single'
  };

  public columns: Array<Columns> = [];

  private callIconMapping = new Map<CallStatus, CallIcon>([
    [CallStatus.Answered, { name: 'call_received', style: 'answered' }],
    [CallStatus.Missed, { name: 'call_missed', style: 'missed' }],
    [CallStatus.Callback, { name: 'phone_callback', style: 'callback' }],
    [CallStatus.Outbound, { name: 'call_made', style: 'outbound' }],
    [CallStatus.Voicemail, { name: 'voicemail', style: 'voicemail' }],
    [CallStatus.Transfer, { name: 'east', style: 'transfer' }]
  ]);

  constructor(
    private logger: NGXLogger,
    private connectService: ConnectService,
    private stateService: ContactStateService,
    private layoutService: LayoutService,
    public outboundStateService: OutboundStateService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeColumns(this.config);
    this.initializeFilter();

    this.layoutService.selectedDashboardItems$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((selectedDashboardItems) => {
          this.selectable.enabled = selectedDashboardItems?.some(
            (item) => item?.key === 'profile'
          );
        })
      )
      .subscribe();
  }

  private initializeFilter() {
    const callHistoryFilter: FilterDescriptor[] = [];

    if (this.config.filterBy === 'ALL') {
      return;
    }

    if (this.config.filterBy === 'AGENT_WITH_MISSED') {
      callHistoryFilter.push({
        field: 'CallStatus',
        value: CallStatus.Missed,
        operator: FilterOperator.EqualTo
      });
    }

    this.connectService
      .getAgentId()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((agentId: string) => {
          callHistoryFilter.push({
            field: 'AgentId',
            value: agentId,
            operator: FilterOperator.EqualTo
          });
        })
      )
      .subscribe();

    this.state = {
      ...this.state,
      filter: {
        logic: 'or',
        filters: callHistoryFilter
      }
    };
  }

  public onCallSelected(event: SelectionEvent) {
    const selectedCallEntry = event.selectedRows[0].dataItem as CallEntry;
    this.stateService.publishEvent<CallEntry>('DETAIL', selectedCallEntry);
  }

  public onOutboundCall(call: CallEntry) {
    if (call.phone) {
      this.connectService
        .doOutboundCall(call.phone)
        .pipe(
          take(1),
          tap((result) => {
            this.logger.debug(`Outbound respond with ${result}`);
          })
        )
        .subscribe();
    }
  }

  private initializeColumns(config: CallHistoryConfig) {
    this.columns = [
      {
        key: 'callStatus',
        title: 'CALL_HISTORY.COLUMNS.STATUS',
        align: 'center',
        type: 'template',
        field: 'callStatus',
        columnClass: 'icons icon',
        transformFn: (call: CallEntry) => [
          this.callIconMapping.get(call.callStatus).name
        ],
        classFn: (call: CallEntry) =>
          this.callIconMapping.get(call.callStatus).style,
        width: 90
      },
      {
        key: 'time',
        title: 'CALL_HISTORY.COLUMNS.CALL_TIME',
        type: 'default',
        field: 'time',
        format: '{0:dd.MM.yy HH:mm:ss}'
      },
      {
        key: 'phone',
        title: 'CALL_HISTORY.COLUMNS.PHONE',
        type: 'template',
        transformFn: (call: CallEntry) =>
          call.customer && call.customer.firstName && call.customer.lastName
            ? [
                `${call.customer.firstName} ${call.customer.lastName}`,
                call.phone
              ]
            : [call.phone]
      },
      {
        key: 'queue',
        title: 'CALL_HISTORY.COLUMNS.QUEUE',
        type: 'template',
        transformFn: (call: CallEntry) =>
          call.queue ? [call.queue.name] : ['-']
      },
      {
        key: 'agent',
        title: 'CALL_HISTORY.COLUMNS.AGENT',
        type: 'template',
        hidden: config.filterBy !== 'ALL',
        transformFn: (call: CallEntry) =>
          call.agent && call.agent.firstName && call.agent.lastName
            ? [`${call.agent.firstName} ${call.agent.lastName}`]
            : ['-']
      },
      {
        key: 'actions',
        title: 'CALL_HISTORY.COLUMNS.ACTION',
        type: 'command',
        align: 'center',
        width: 98
      }
    ];
  }
}
