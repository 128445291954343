import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SharedModule } from '@shared/shared.module';
import { PhoneDetailsComponent } from './phone-details';
import { PhoneDetailsPipe } from './phone-details.pipe';
import { PhonebookComponent } from './phonebook.component';

@NgModule({
  declarations: [PhonebookComponent, PhoneDetailsComponent, PhoneDetailsPipe],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    GridModule,
    ButtonsModule,
    FormsModule,
    IndicatorsModule,
    InputsModule
  ],
  exports: [PhonebookComponent]
})
export class PhonebookModule {}
