<kendo-grid
  [kendoGridBinding]="userDetails"
  [pageSize]="3"
  scrollable="none"
  [navigable]="true"
  kendoGridFocusable
>
  <kendo-grid-column
    field="id"
    title="{{ 'PHONEBOOK.COLUMNS.DETAILS.INDEX' | translate }}"
    [width]="120"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{rowIndex + 1}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="key"
    title="{{ 'PHONEBOOK.COLUMNS.DETAILS.TYPE' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="value"
    title="{{ 'PHONEBOOK.COLUMNS.DETAILS.CONTACT' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
    title="{{ 'PHONEBOOK.COLUMNS.DETAILS.ACTIONS' | translate }}"
    [width]="120"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div
        class="details--buttons"
        *ngIf="dataItem.key !== 'mail' && dataItem.value"
      >
        <span
          title="{{(agentState === 'Busy' ? 'PHONEBOOK.COLUMNS.DETAILS.CALL_DISABLED' : 'PHONEBOOK.COLUMNS.DETAILS.CALL') | translate }}"
        >
          <button
            kendoButton
            class="details--buttons__cold"
            [primary]="true"
            (click)="emitCallEvent(dataItem, 'COLD'); $event.stopPropagation();"
            [disabled]="agentState === 'Busy'"
          >
            <span class="icons phone-icon"> phone </span>
          </button>
        </span>
        <span
          title="{{(agentState !== 'Busy' ? 'PHONEBOOK.COLUMNS.DETAILS.TRANSFER_CALL_DISABLED' : 'PHONEBOOK.COLUMNS.DETAILS.TRANSFER_CALL') | translate }}"
        >
          <button
            kendoButton
            class="details--buttons__warm"
            themeColor="secondary"
            (click)="emitCallEvent(dataItem, 'WARM'); $event.stopPropagation();"
            [disabled]="agentState !== 'Busy'"
          >
            <span class="icons phone-icon"> phone_forwarded </span>
          </button>
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
