import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallHistoryComponent } from '@dashboard/call-history/call-history.component';
import { MetadataComponent } from '@dashboard/metadata/metadata.component';
import { MetricComponent } from '@dashboard/metrics/metric/metric.component';
import { MetricsComponent } from '@dashboard/metrics/metrics.component';
import { PhonebookComponent } from '@dashboard/phonebook/phonebook.component';
import { ProfileComponent } from '@dashboard/profile/profile.component';
import { QueueListComponent } from '@dashboard/queue-list/queue-list.component';
import { RoutingProfileComponent } from '@dashboard/routing-profiles/routing-profile.component';
import { StaffComponent } from '@dashboard/staff/staff.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { GraphqlModule } from '@shared/graphql/graphql.module';
import { WrapUpComponent } from 'app/wrapup/wrapup.component';
import { Amplify } from 'aws-amplify';
import { BackendConfig, FrontendConfig } from 'config/app.config';
import { ConfigService } from 'config/config.service';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import { INGXLoggerConfig, LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { forkJoin } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LayoutConfigService } from '../config/layout-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ErrorsModule } from './errors/errors.module';

const loggerConfig: INGXLoggerConfig = {
  level: isDevMode() ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.INFO,
  context: 'TEAG'
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initApp(
  translate: TranslateService,
  configService: ConfigService,
  layoutConfigService: LayoutConfigService
) {
  return () =>
    configService.loadConfigFromFile().pipe(
      tap((config) => {
        initLoggerFromConfig(config.frontend);
        initBackendFromConfig(config.backend);
      }),
      switchMap((config) =>
        forkJoin([
          initTranslationsFromConfig(translate, config.frontend),
          layoutConfigService.loadConfigFromFile()
        ])
      )
    );
}

function initTranslationsFromConfig(
  translate: TranslateService,
  config: FrontendConfig
) {
  const { defaultLanguage, supportedLanguages } = config.settings;
  const detectedLanguage = translate.getBrowserLang();
  const availableLanguages = new RegExp(`${supportedLanguages.join('|')}`);
  const language = availableLanguages.test(detectedLanguage)
    ? detectedLanguage
    : defaultLanguage;
  translate.setDefaultLang(language);
  return translate.use(language);
}

function initBackendFromConfig(config: BackendConfig) {
  const { appsync, cognito, oauth } = config;

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: cognito.userPoolId,
        userPoolClientId: cognito.userPoolWebClientId,
        loginWith: {
          oauth: {
            domain: oauth.domain,
            redirectSignIn: [oauth.redirectSignIn],
            redirectSignOut: [oauth.redirectSignOut],
            responseType: oauth.responseType,
            scopes: oauth.scope,
            providers: [{ custom: oauth.identityProvider }]
          }
        }
      }
    },
    API: {
      GraphQL: {
        endpoint: appsync.graphqlEndpoint,
        region: appsync.region,
        defaultAuthMode: 'userPool'
      }
    }
  });
}

function initLoggerFromConfig(config: FrontendConfig) {
  const { settings } = config;
  loggerConfig.level = settings.logLevel;
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AuthModule,
        AppRoutingModule,
        DashboardModule,
        GraphqlModule,
        ErrorsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        LoggerModule.forRoot(loggerConfig),
        IndicatorsModule,
        NotificationModule,
        DynamicHooksModule.forRoot({
            globalParsers: [
                {
                    component: CallHistoryComponent
                },
                {
                    component: StaffComponent
                },
                {
                    component: MetadataComponent
                },
                {
                    component: MetricsComponent
                },
                {
                    component: ProfileComponent
                },
                {
                    component: WrapUpComponent
                },
                {
                    component: MetricComponent
                },
                {
                    component: QueueListComponent
                },
                {
                    component: PhonebookComponent
                },
                {
                    component: RoutingProfileComponent
                }
            ]
        })], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [TranslateService, ConfigService, LayoutConfigService],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
