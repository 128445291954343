import { Injectable } from '@angular/core';
import { StateService } from '@shared/services/state.service';

export type ContactEvent = 'CALLING' | 'ACW' | 'IDLE' | 'DETAIL';

export interface ContactEventState<T = any> {
  event: ContactEvent;
  payload?: T;
}

@Injectable({
  providedIn: 'root'
})
export class ContactStateService extends StateService<ContactEventState> {
  constructor() {
    super({
      event: 'IDLE'
    });
  }

  public onEventStateChanged() {
    return this.select((state) => state);
  }

  public onEventChanged() {
    return this.select((state) => state.event);
  }

  public publishEvent<T = any>(event: ContactEvent, payload?: T) {
    this.setState({ event, payload });
  }
}
