import { NgModule } from '@angular/core';
import { EllipsisPipe } from './ellipsis.pipe';
import { ExistsPipe } from './exists.pipe';
import { FlattenPipe } from './flatten.pipe';
import { GroupByPipe } from './group-by.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { LegendColorPipe } from './legend-color.pipe';
import { MetricParserPipe } from './metric.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';

const PIPES = [
  EllipsisPipe,
  FlattenPipe,
  SanitizePipe,
  ExistsPipe,
  SentenceCasePipe,
  GroupByPipe,
  IsEmptyPipe,
  MetricParserPipe,
  LegendColorPipe
];

@NgModule({
  declarations: [...PIPES],
  providers: [...PIPES],
  exports: [...PIPES]
})
export class PipesModule {}
