import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@shared/local-storage/storage.service';
import { StateService } from '@shared/services/state.service';
import { ConnectConfigService } from 'config/connect-config.service';

const INSTANCE_ID_PARAM = 'instanceId';

export interface InstanceState {
  id: string;
}

const initialState: InstanceState = {
  id: ''
};

@Injectable({
  providedIn: 'root'
})
export class InstanceStateService extends StateService<InstanceState> {
  constructor(
    private store: StorageService,
    private route: ActivatedRoute,
    private connectConfig: ConnectConfigService
  ) {
    super(initialState);
    this.init();
  }

  public init() {
    let instanceId = this.connectConfig.getConnectInstanceId();
    const parsedInstanceId = this.parseInstanceId();
    if (parsedInstanceId !== '') {
      instanceId = parsedInstanceId;
    }
    this.setInstanceId(instanceId);
  }

  private parseInstanceId(): string {
    return this.route.snapshot.queryParams[INSTANCE_ID_PARAM] || '';
  }

  private setInstanceId(id: string): void {
    this.setState({ id });
    this.store.setItem(INSTANCE_ID_PARAM, id);
  }

  public getInstanceId(): string {
    if (this.state.id === '') {
      const id = this.store.getItem(INSTANCE_ID_PARAM) || '';
      this.setInstanceId(id);
    }
    return this.state.id;
  }

  public hasInstanceId(): boolean {
    const instanceId = this.getInstanceId();
    return instanceId !== '';
  }
}
