import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ProfileComponent } from './profile.component';
import { ProfileListItemComponent } from './profile-list-item/profile-list-item.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { PropertyListModule } from '@shared/property-list/property-list.module';
import { ProfileAttributePipe } from './profile-detail/profile-attribute.pipe';
import { ContactPhonePipe } from './contact-phone.pipe';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileListItemComponent,
    ProfileDetailComponent,
    ProfileAttributePipe,
    ContactPhonePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    ListViewModule,
    LayoutModule,
    InputsModule,
    ButtonsModule,
    PropertyListModule
  ],
  providers: [ContactPhonePipe],
  exports: [ProfileComponent]
})
export class ProfileModule {}
