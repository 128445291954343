import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MetricDataService } from '@dashboard/metrics/metrics-data.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogCloseResult,
  DialogRef,
  DialogService
} from '@progress/kendo-angular-dialog';
import { DashboardItemComponentName } from '@shared/enums/dashboard-item-component-name.enum';
import { DashboardItemExpansionLevel } from '@shared/enums/dashboard-item-expansion-level.enum';
import { CurrentQueueMetric, Queue } from '@shared/graphql/types';
import {
  DashboardItem,
  DashboardItemExpansionSize
} from '@shared/models/dashboard-item.model';
import { ConnectService } from '@shared/services/connect.service';
import { LayoutService } from '@shared/services/layout.service';
import { NotificationService } from '@shared/services/notification.service';
import { QueueService } from '@shared/services/queue.service';
import { ContactProxy } from '@tecracer/trccp-streams';
import {
  CompactType,
  DisplayGrid,
  GridType,
  GridsterComponent,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponent,
  GridsterPush
} from 'angular-gridster2';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Subject,
  combineLatest,
  debounceTime,
  fromEvent,
  merge,
  takeUntil
} from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../base/base-component.directive';
import { WrapUpService } from '../wrapup/wrapup.service';
import {
  ContactEvent,
  ContactStateService
} from './contact-event-state.service';

@Component({
  selector: 'trccp-agent-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent
  extends BaseComponentDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(GridsterComponent) gridster: GridsterComponent;
  @ViewChildren(GridsterItemComponent)
  gridsterItems: QueryList<GridsterItemComponent>;

  public dashboardEvent: ContactEvent;

  options: GridsterConfig;
  dashboardItems: DashboardItem[];
  editModeEnabled = false;

  private unsubscribeMetrics$: Subject<void> = new Subject<void>();
  private lastWindowWidth = window.innerWidth;
  private readonly dashboardItemColWidth = 56;

  get dashboardItemComponentName(): typeof DashboardItemComponentName {
    return DashboardItemComponentName;
  }

  constructor(
    private connectService: ConnectService,
    private stateService: ContactStateService,
    private logger: NGXLogger,
    private layoutService: LayoutService,
    private queues: QueueService,
    private metrics: MetricDataService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private wrapupService: WrapUpService
  ) {
    super();
  }

  ngOnInit() {
    this.notificationService.initNotificationWorker();
    this.stateService
      .onEventChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dashboardEvent) => (this.dashboardEvent = dashboardEvent));

    merge(
      this.connectService.onConnecting().pipe(
        tap((contact) =>
          this.logger.debug('Received connecting event', contact.contactId)
        ),
        tap((contact: ContactProxy) =>
          this.stateService.publishEvent<ContactProxy>('CALLING', contact)
        )
      ),
      this.connectService.onAfterCallWork().pipe(
        tap((contact) =>
          this.logger.debug('Received connecting event', contact.contactId)
        ),
        tap((contact: ContactProxy) =>
          this.stateService.publishEvent<ContactProxy>('ACW', contact)
        ),
        switchMap((contact: ContactProxy) =>
          contact
            .isInbound()
            .pipe(
              switchMap((isInbound) =>
                isInbound ? this.wrapupService.openWrapup(contact) : EMPTY
              )
            )
        )
      ),
      this.connectService.onCleared().pipe(
        tap((contact) =>
          this.logger.debug('Received disconnecting event', contact.contactId)
        ),
        tap(() => this.stateService.publishEvent<ContactProxy>('IDLE'))
      )
    )
      .pipe()
      .subscribe();

    this.options = {
      itemInitCallback: this.itemInit,
      gridType: GridType.Fixed,
      compactType: CompactType.None,
      margin: 16,
      draggable: {
        enabled: true,
        ignoreContent: true,
        stop: this.dragStop
      },
      resizable: {
        enabled: false
      },
      displayGrid: DisplayGrid.None,
      swap: true,
      swapWhileDragging: true,
      pushItems: true,
      disablePushOnDrag: true,
      disableScrollHorizontal: true,
      disableWarnings: true,
      fixedColWidth: 40,
      fixedRowHeight: 60
    };

    combineLatest([
      this.queues.getQueues(),
      this.layoutService.selectedDashboardItems$.pipe(
        takeUntil(this.unsubscribe$)
      )
    ]).subscribe(([queues, dashboardItems]) => {
      this.distributeCallHistoryDependentItems(dashboardItems);
      this.distributeMetrics(dashboardItems, queues);
    });

    this.layoutService.editModeEnabled$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((editModeEnabled) => {
        this.editModeEnabled = editModeEnabled;
      });
  }

  ngAfterViewInit() {
    this.resizeItems();
    this.listenToResize();
  }

  changeExpansionLevel(
    dashboardItem: DashboardItem,
    expansionLevel: DashboardItemExpansionLevel
  ): void {
    const selectedSize: DashboardItemExpansionSize =
      dashboardItem.expansion.sizes.find(
        (size) => size.expansionLevel === expansionLevel
      );

    if (!selectedSize) {
      return;
    }

    dashboardItem.expansion.expansionLevel = selectedSize.expansionLevel;

    this.updateDashboardItemSizes(dashboardItem, selectedSize);
  }

  removeItem(dashboardItem: DashboardItem): void {
    const itemTitle: string = dashboardItem?.parentItemId
      ? this.dashboardItems?.find(
          (item) => item?.id === dashboardItem?.parentItemId
        )?.title
      : dashboardItem?.title;

    const dialog: DialogRef = this.openRemoveItemDialog(itemTitle);

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult || !(result as any)?.isApprove) {
        return;
      }
      this.layoutService.removeItem(dashboardItem);
    });
  }

  trackBy(index: number, item: DashboardItem): string {
    return item.id;
  }

  private distributeCallHistoryDependentItems(
    dashboardItems: DashboardItem[]
  ): void {
    const callHistoryDashboardItem: DashboardItem = dashboardItems?.find(
      (dashboardItem) => dashboardItem?.key === 'callHistory'
    );

    if (callHistoryDashboardItem) {
      for (const dashboardItem of dashboardItems) {
        switch (dashboardItem?.key) {
          case 'metadata': {
            dashboardItem.x = callHistoryDashboardItem.x;
            dashboardItem.y = callHistoryDashboardItem.y;
            break;
          }
          case 'profile': {
            dashboardItem.x = callHistoryDashboardItem.x + 7;
            dashboardItem.y = callHistoryDashboardItem.y;
            break;
          }
        }
      }
    }
  }

  private distributeMetrics(
    dashboardItems: DashboardItem[],
    queues: Queue[]
  ): void {
    const metricsDashboardItem: DashboardItem = dashboardItems.find(
      (item) => item?.componentName === DashboardItemComponentName.METRICS
    );

    if (!metricsDashboardItem) {
      this.dashboardItems = dashboardItems;
      this.unsubscribeMetrics$.next();
      this.unsubscribeMetrics$.complete();
      return;
    }

    if (
      this.dashboardItems &&
      this.dashboardItems?.some((item) => item?.id === metricsDashboardItem?.id)
    ) {
      this.dashboardItems = dashboardItems;
      return;
    }

    metricsDashboardItem.items =
      metricsDashboardItem?.items?.filter((item) => {
        if (item?.contextObj?.metric) {
          item.contextObj.metric = null;
        }
        return queues.some((queue) => queue?.id === item?.id);
      }) || [];

    for (let queue of queues) {
      this.addMetricItem(metricsDashboardItem, queue);
    }

    this.dashboardItems = dashboardItems;
    this.getCurrentMetrics(metricsDashboardItem);
  }

  private addMetricItem(
    metricsDashboardItem: DashboardItem,
    queue: Queue
  ): void {
    if (metricsDashboardItem.items?.some((item) => item.id === queue?.id)) {
      return;
    }

    metricsDashboardItem?.items.push({
      id: queue?.id,
      key: 'metrics',
      cols: metricsDashboardItem?.cols,
      rows: metricsDashboardItem?.rows,
      y: metricsDashboardItem?.y,
      x: metricsDashboardItem?.x,
      realX: metricsDashboardItem?.x,
      realY: metricsDashboardItem?.y,
      dragEnabled: metricsDashboardItem?.dragEnabled,
      componentName: `<trccp-metric [id]="context.queue.id" [data]="context.metric"></trccp-metric>`,
      title: queue?.name,
      className: metricsDashboardItem?.className,
      removable: metricsDashboardItem?.removable,
      contextObj: {
        queue: queue,
        metric: null
      },
      parentItemId: metricsDashboardItem?.id
    });
  }

  private getCurrentMetrics(metricsDashboardItem: DashboardItem): void {
    this.unsubscribeMetrics$ = new Subject<void>();
    this.metrics
      .getCurrentMetrics()
      .pipe(takeUntil(this.unsubscribeMetrics$))
      .subscribe((metrics) => {
        for (let item of metricsDashboardItem?.items) {
          const result = metrics.filter(
            (metric: CurrentQueueMetric) =>
              metric.queue.id === item.contextObj?.queue?.id
          );

          item.contextObj.metric = result[0];
        }
      });
  }

  private openRemoveItemDialog(itemTitle: string): DialogRef {
    return this.dialogService.open({
      title: this.translateService.instant(
        'DIALOGS.REMOVE_DASHBOARD_ITEM.TITLE'
      ),
      content: this.translateService.instant(
        'DIALOGS.REMOVE_DASHBOARD_ITEM.CONTENT',
        {
          item: this.translateService.instant(itemTitle)
        }
      ),
      actions: [
        {
          text: this.translateService.instant(
            'DIALOGS.REMOVE_DASHBOARD_ITEM.ACTIONS.NO'
          ),
          isApprove: false
        },
        {
          text: this.translateService.instant(
            'DIALOGS.REMOVE_DASHBOARD_ITEM.ACTIONS.YES'
          ),
          themeColor: 'primary',
          isApprove: true
        }
      ],
      closeTitle: this.translateService.instant(
        'DIALOGS.REMOVE_DASHBOARD_ITEM.ACTIONS.CLOSE'
      ),
      width: 450,
      height: 200,
      minWidth: 250,
      actionsLayout: 'start'
    });
  }

  private listenToResize(): void {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        const windowSizeDecreased = window.innerWidth < this.lastWindowWidth;

        this.lastWindowWidth = window.innerWidth;

        this.resizeItems(windowSizeDecreased);
      });
  }

  private resizeItems(windowSizeDecreased = true): void {
    if (!this.dashboardItems?.length) {
      return;
    }

    for (let i = 1; i < this.gridsterItems.length; i++) {
      const dashboardItem = this.gridsterItems.toArray()[i];
      const widthFromStart: number =
        (dashboardItem?.$item.x + dashboardItem?.$item.cols) *
        this.dashboardItemColWidth;

      if (windowSizeDecreased) {
        this.handleWindowSizeDecreaseEvent(dashboardItem, widthFromStart);
        continue;
      }

      this.handleWindowSizeIncreaseEvent(dashboardItem, widthFromStart, i);
    }
  }

  private handleWindowSizeDecreaseEvent(
    dashboardItem: GridsterItemComponent,
    widthFromStart: number
  ): void {
    if (widthFromStart > window.innerWidth) {
      const previousItems: GridsterItemComponent[] = this.gridsterItems
        ?.filter((item) => item?.$item.y === dashboardItem?.$item?.y)
        ?.sort((a, b) => a?.$item.x - b?.$item.x);

      if (previousItems?.length === 1) {
        return;
      }

      if (
        dashboardItem?.$item.x + dashboardItem?.$item.cols >
        previousItems.reduce((a, b) => a + b.$item?.cols, 0)
      ) {
        for (let i = 1; i < previousItems?.length; i++) {
          const gridsterPreviousItem: GridsterItemComponent =
            previousItems[i - 1];
          const gridsterCurrentItem: GridsterItemComponent = previousItems[i];

          if (!gridsterPreviousItem || !gridsterCurrentItem) {
            continue;
          }
          gridsterCurrentItem.$item.x =
            gridsterPreviousItem.$item.x + gridsterPreviousItem.$item.cols;
        }
        return;
      }

      const push = new GridsterPush(dashboardItem); // init the service

      dashboardItem.$item.x = 0;
      dashboardItem.$item.y = dashboardItem.$item.y + dashboardItem.$item.rows;

      this.pushItems(dashboardItem, push, push.fromWest);
    }
  }

  private handleWindowSizeIncreaseEvent(
    dashboardItem: GridsterItemComponent,
    widthFromStart: number,
    currentIndex: number
  ): void {
    if (widthFromStart < window.innerWidth) {
      const rowLastItem: GridsterItemComponent = this.gridsterItems
        ?.filter((item) => item?.item.realY === dashboardItem?.item?.realY)
        ?.sort((a, b) => b?.item.realX - a?.item.realX)[0];

      if (
        dashboardItem?.item?.y === dashboardItem?.item?.realY &&
        (rowLastItem.item?.realX + rowLastItem.item?.cols) *
          this.dashboardItemColWidth <
          window.innerWidth
      ) {
        dashboardItem.$item.x = dashboardItem.item?.realX;
        return;
      }

      const previousDashboardItem =
        this.gridsterItems.toArray()[currentIndex - 1];
      if (previousDashboardItem?.$item.y === dashboardItem?.$item?.y) {
        return;
      }

      const previousItems: GridsterItemComponent[] = this.gridsterItems
        ?.filter((item) => item?.$item.y === previousDashboardItem?.$item?.y)
        ?.sort((a, b) => a?.$item.x - b?.$item.x);
      const colsWithNextItem: number = previousItems.reduce(
        (a, b) => a + b.item?.cols,
        dashboardItem?.item?.cols
      );

      if (
        colsWithNextItem * this.dashboardItemColWidth > window.innerWidth &&
        dashboardItem.$item.y - dashboardItem.$item.rows ===
          previousDashboardItem?.$item?.y
      ) {
        return;
      }

      const push = new GridsterPush(dashboardItem); // init the service

      dashboardItem.$item.y = dashboardItem.$item.y - dashboardItem.$item.rows;
      dashboardItem.$item.x =
        dashboardItem.$item.y === previousDashboardItem?.$item?.y
          ? previousDashboardItem?.$item?.x + previousDashboardItem?.$item?.cols
          : 0;

      this.pushItems(dashboardItem, push, push.fromEast);
    }
  }

  private pushItems(
    dashboardItem: GridsterItemComponent,
    push: GridsterPush,
    pushDirection: string
  ): void {
    const isPushed = push.pushItems(pushDirection);

    if (isPushed) {
      // push items from a direction
      push.checkPushBack(); // check for items can restore to original position
      push.setPushedItems(); // save the items pushed
      dashboardItem.setSize();
      dashboardItem.checkItemChanges(dashboardItem.$item, dashboardItem.item);
    }
    push.destroy(); // destroy push instance
  }

  private updateDashboardItemSizes(
    dashboardItem: DashboardItem,
    selectedSize: Partial<GridsterItem>,
    selectedDashboardItem?: DashboardItem
  ): void {
    const itemToPush: GridsterItemComponent = this.gridsterItems?.find(
      (item) => item.item.id === dashboardItem.id
    );
    const selectedItemToPush: GridsterItemComponent = this.gridsterItems?.find(
      (item) => item.item.id === selectedDashboardItem?.id
    );

    if (!itemToPush) {
      return;
    }

    const push = new GridsterPush(itemToPush); // init the service

    itemToPush.$item.cols = selectedSize?.cols || ++dashboardItem.cols;
    itemToPush.$item.rows = selectedSize?.rows || dashboardItem?.rows;

    if (selectedDashboardItem) {
      selectedItemToPush.$item.x = 0;
    }

    const isPushed = push.pushItems(push.fromNorth);

    if (isPushed) {
      // push items from a direction
      push.checkPushBack(); // check for items can restore to original position
      push.setPushedItems(); // save the items pushed
      itemToPush.setSize();
      itemToPush.checkItemChanges(itemToPush.$item, itemToPush.item);
    }

    if (!isPushed || !selectedSize) {
      itemToPush.$item.cols = --dashboardItem.cols;
      itemToPush.$item.rows = dashboardItem?.rows;
      push.restoreItems(); // restore to initial state the pushed items
    }

    push.destroy(); // destroy push instance
  }

  private dragStop: (
    item: DashboardItem,
    itemComponent: GridsterItemComponent,
    event: MouseEvent
  ) => void = (item, itemComponent, event) => {
    const widthFromStart: number =
      (itemComponent?.$item.x + itemComponent?.$item.cols) *
      this.dashboardItemColWidth;

    if (widthFromStart > window.innerWidth) {
      return Promise.reject();
    }

    this.updateItems(item, itemComponent);
    return Promise.resolve();
  };

  private itemInit: (
    item: DashboardItem,
    itemComponent: GridsterItemComponent
  ) => void = (item, itemComponent) => {
    this.updateItems(item, itemComponent);
  };

  private updateItems(
    item: DashboardItem,
    itemComponent: GridsterItemComponent
  ): void {
    item.x = itemComponent?.$item.x;
    item.y = itemComponent?.$item.y;
    item.realX = itemComponent?.$item.x;
    item.realY = itemComponent?.$item.y;
    this.layoutService.dashboardItems = this.dashboardItems;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribeMetrics$.next();
    this.unsubscribeMetrics$.complete();
    this.notificationService.destroyNotificationWorker();
  }
}
