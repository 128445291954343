import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CardModule } from '@progress/kendo-angular-layout';
import { StreamsModule } from '@tecracer/trccp-streams';
import { PhoneComponent } from './phone.component';
import { OutboundComponent } from './outbound/outbound.component';
import { RecorderComponent } from './recorder/recorder.component';
import { RetentionComponent } from './retention/retention.component';
import { PhoneNumberValidPipe } from './outbound/phone-number-valid.pipe';

@NgModule({
  declarations: [
    PhoneComponent,
    OutboundComponent,
    RecorderComponent,
    RetentionComponent,
    PhoneNumberValidPipe
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    StreamsModule,
    FormsModule,
    CardModule,
    DropDownsModule,
    ButtonsModule
  ],
  exports: [PhoneComponent]
})
export class PhoneModule {}
