import { Injectable } from '@angular/core';
import { ConnectCredentialsResponse } from '@shared/graphql/types';

export const CONNECT_SIGN_IN_URL_LEGACY = '/connect/auth/sign-in';
export const CONNECT_SIGN_IN_URL = '/auth/sign-in';

@Injectable({
  providedIn: 'root'
})
export class AuthUtilService {
  public isConnectCredentialsValid(
    connectCredentials: ConnectCredentialsResponse
  ): boolean {
    const { credentials, destination } = connectCredentials;
    return (
      destination === '/connect/ccp-v2' &&
      credentials.length > 0 &&
      credentials.includes('AccessToken') &&
      credentials.includes('AccessTokenExpiration') &&
      credentials.includes('RefreshToken') &&
      credentials.includes('RefreshTokenExpiration')
    );
  }

  public buildAuthUrl(instanceUrl: string) {
    const signInUrl = this.isLegacyInstance(instanceUrl)
      ? CONNECT_SIGN_IN_URL_LEGACY
      : CONNECT_SIGN_IN_URL;
    const authUrl = new URL(signInUrl, instanceUrl);
    return authUrl.toString();
  }

  private isLegacyInstance(instanceUrl: string): boolean {
    const legacyDomainPattern = /.*awsapps\.com\/?$/;
    return legacyDomainPattern.test(instanceUrl);
  }
}
