<div class="recorder {{ currentRecordingState | lowercase }}">
  <div class="recorder--title">
    {{ 'PHONE.RECORDER.STATE.' + currentRecordingState | translate }}
  </div>
  <div
    *ngIf="currentRecordingState !== RecordingState.Stop"
    class="recorder--actions"
  >
    <span
      *ngIf="currentRecordingState === RecordingState.Ready"
      (click)="onStartRecording()"
      title="{{ 'PHONE.RECORDER.TOOLTIPS.START' | translate }}"
      class="recorder--action"
    >
      <i class="icons"> radio_button_checked_icon </i>
    </span>
    <span
      *ngIf="currentRecordingState === RecordingState.Paused"
      (click)="onResumeRecording()"
      title="{{ 'PHONE.RECORDER.TOOLTIPS.RESUME' | translate }}"
      class="recorder--action"
    >
      <i class="icons"> play_circle_icon </i>
    </span>
    <span
      *ngIf="currentRecordingState === RecordingState.Progress"
      (click)="onSuspendRecording()"
      title="{{ 'PHONE.RECORDER.TOOLTIPS.SUSPEND' | translate }}"
      class="recorder--action"
    >
      <i class="icons"> pause_circle_icon </i>
    </span>
    <span
      *ngIf="currentRecordingState === RecordingState.Progress"
      (click)="onStopRecording()"
      title="{{ 'PHONE.RECORDER.TOOLTIPS.STOP' | translate }}"
      class="recorder--action"
    >
      <i class="icons"> stop_circle_icon </i>
    </span>
  </div>
</div>
