import { Pipe, PipeTransform } from '@angular/core';
import { Columns } from './column.model';

@Pipe({
  name: 'columnAlign'
})
export class ColumnAlignPipe implements PipeTransform {
  transform(column: Columns): any {
    return {
      'text-align': column.align || 'left'
    };
  }
}
