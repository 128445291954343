import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flatten'
})
export class FlattenPipe implements PipeTransform {
  transform(value: any) {
    return this.flattenObject(value);
  }

  private flattenObject = (obj: any) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null) {
        Object.assign(flattened, this.flattenObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });

    return flattened;
  };
}
