import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigService } from 'config/config.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'trccp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private configService: ConfigService,
    private titleService: Title
  ) {
    this.setAppTitle();
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(tap(() => this.setAppTitle()))
      .subscribe();
  }

  private setAppTitle(): void {
    const config = this.configService.getFrontendConfig();
    this.titleService.setTitle(config.tabTitle);
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
