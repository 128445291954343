<div class="profile-container">
  <ng-container *let="profile$ | async as profile">
    <ng-container *ngIf="detailViewMode === 'TAB_PAGE'; else singleView">
      <kendo-tabstrip class="profile-tabs">
        <kendo-tabstrip-tab
          [selected]="true"
          title="{{ 'PROFILE.DETAIL.CUSTOMER.TITLE' | translate }}"
        >
          <ng-template kendoTabContent>
            <trccp-property-list
              [keyTemplate]="customerTemplate"
              [properties]="profile"
              [filter]="profileFilter"
              [invertFilter]="true"
            ></trccp-property-list>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab
          title="{{ 'PROFILE.DETAIL.ATTRIBUTES.TITLE' | translate }}"
        >
          <ng-template kendoTabContent>
            <trccp-property-list
              [keyTemplate]="attributeTemplate"
              [properties]="profile.attributes | profileAttributes"
              [filter]="attributeFilter"
              [invertFilter]="true"
            ></trccp-property-list>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </ng-container>
    <ng-template #singleView>
      <div class="profile-single-view">
        <header class="profile-title">
          {{ 'PROFILE.DETAIL.CUSTOMER.TITLE' | translate }}
        </header>
        <trccp-property-list
          [keyTemplate]="customerTemplate"
          [properties]="profile"
          [filter]="profileFilter"
          [invertFilter]="true"
        ></trccp-property-list>
        <header class="profile-title">
          {{ 'PROFILE.DETAIL.ATTRIBUTES.TITLE' | translate }}
        </header>
        <trccp-property-list
          [keyTemplate]="attributeTemplate"
          [properties]="profile.attributes | profileAttributes"
          [filter]="attributeFilter"
          [invertFilter]="true"
        ></trccp-property-list>
      </div>
    </ng-template>
  </ng-container>
  <div class="profile-detail-actions">
    <button
      kendoButton
      [primary]="true"
      *ngIf="!isSingleProfile || !isCalling"
      (click)="onBack()"
    >
      <ng-container *ngIf="isSingleProfile; else toSearch">{{
        'PROFILE.ACTIONS.BACK.TITLE' | translate
      }}</ng-container>
      <ng-template #toSearch>{{
        'PROFILE.DETAIL.ACTIONS.BACK.TITLE' | translate
      }}</ng-template>
    </button>
  </div>
</div>
<ng-template #customerTemplate let-key="key">
  <dt class="profile-attributes-key">{{ ('PROFILE.DETAIL.CUSTOMER.FIELDS.' + key | uppercase) | translate}}</dt>
</ng-template>
<ng-template #attributeTemplate let-key="key">
  <dt class="profile-attributes-key">{{ key | titlecase }}</dt>
</ng-template>

