import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { GridModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '@shared/shared.module';
import { QueueListComponent } from './queue-list.component';

@NgModule({
  declarations: [QueueListComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    GridModule,
    GaugesModule
  ],
  exports: [QueueListComponent]
})
export class QueueListModule {}
