import { CommonModule } from '@angular/common';
import { RoutingProfileComponent } from './routing-profile.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [RoutingProfileComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    DropDownsModule,
    ButtonsModule
  ],
  providers: [],
  exports: [RoutingProfileComponent]
})
export class RoutingProfileModule {}
