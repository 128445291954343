<div class="wrapup">
  <form class="k-form k-form-md" [formGroup]="form">
    <kendo-formfield>
      <kendo-label
        [for]="category"
        [text]="'WRAP_UP.CATEGORY.LABEL' | translate"
      ></kendo-label>
      <kendo-formerror>
        {{ 'WRAP_UP.CATEGORY.ERROR' | translate }}</kendo-formerror
      >
      <kendo-dropdownlist
        #category
        formControlName="category"
        [kendoDropDownFilter]="filterSettings"
        [virtual]="true"
        [data]="wrapupData | category"
        (valueChange)="onCategorySelected($event)"
      ></kendo-dropdownlist>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="classification"
        [text]="'WRAP_UP.CLASSIFICATION.LABEL' | translate"
      ></kendo-label>
      <kendo-formerror>
        {{ 'WRAP_UP.CLASSIFICATION.ERROR' | translate }}</kendo-formerror
      >
      <kendo-dropdownlist
        #classification
        [class.wrapup__classification--disabled]="category$() === ''"
        formControlName="classification"
        [kendoDropDownFilter]="filterSettings"
        [virtual]="true"
        textField="name"
        valueField="id"
        [data]="classifications$()"
      ></kendo-dropdownlist>
    </kendo-formfield>
    <div class="k-form-buttons wrapup__controls">
      <trccp-timer [totalTime]="wrapupTime.duration"></trccp-timer>
      <div class="wrapup__actions">
        <button
          kendoButton
          themeColor="error"
          [title]="'WRAP_UP.ACTIONS.RESET.TOOLTIP' | translate"
          (click)="onReset()"
        >
          {{ 'WRAP_UP.ACTIONS.RESET.TEXT' | translate }}
        </button>
        <button
          kendoButton
          themeColor="primary"
          [disabled]="!form.valid"
          [title]="'WRAP_UP.ACTIONS.APPLY.TOOLTIP' | translate"
          (click)="onConfirm($event)"
        >
          {{ 'WRAP_UP.ACTIONS.APPLY.TEXT' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
