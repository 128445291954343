import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardItemExpansionLevel } from '@shared/enums/dashboard-item-expansion-level.enum';
import { DashboardItem } from '@shared/models/dashboard-item.model';
import {
  makeSameSizeIcon,
  fullscreenExitIcon,
  fullscreenIcon,
  trashIcon
} from '@progress/kendo-svg-icons';

@Component({
  selector: 'trccp-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['dashboard-tile.component.scss']
})
export class DashboardTileComponent {
  @Input() public item: DashboardItem;
  @Input() public hide = false;
  @Input() public editModeEnabled = false;

  public icons = {
    minimize: fullscreenExitIcon,
    maximize: fullscreenIcon,
    delete: trashIcon,
    drag: makeSameSizeIcon
  };

  @Output()
  public expansionLevelChanged: EventEmitter<DashboardItemExpansionLevel> =
    new EventEmitter<DashboardItemExpansionLevel>();
  @Output() public removeIconClicked: EventEmitter<void> =
    new EventEmitter<void>();

  get dashboardItemExpansionLevel(): typeof DashboardItemExpansionLevel {
    return DashboardItemExpansionLevel;
  }

  changeExpansionLevel(expansionLevel: DashboardItemExpansionLevel): void {
    this.expansionLevelChanged.emit(expansionLevel);
  }

  removeItem(): void {
    this.removeIconClicked.emit();
  }
}
