import { Injectable } from '@angular/core';

import { ConnectCredentialsResponse } from '@shared/graphql/types';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConnectCredentialService } from './connect-credential.service';

@Injectable({ providedIn: 'root' })
export class ConnectLoginResolver {
  constructor(
    private logger: NGXLogger,
    private connectCredentialService: ConnectCredentialService
  ) {}

  resolve(): Observable<ConnectCredentialsResponse> {
    return this.connectCredentialService
      .getConnectCredentials()
      .pipe(
        tap((connectCredentials: ConnectCredentialsResponse) =>
          this.logger.debug('Connect credentials ', connectCredentials)
        )
      );
  }
}
