import { Injectable } from '@angular/core';
import { PhoneNumber } from '@shared/graphql/types';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OutboundStateService {
  private state$ = new BehaviorSubject<PhoneNumber>(null);

  public get hasOutbound() {
    return this.state$.pipe(
      map(
        (outbound) =>
          outbound !== null &&
          outbound?.phoneNumber !== null &&
          outbound?.phoneNumber !== ''
      )
    );
  }

  public setOutbound(phoneNumber: PhoneNumber | null) {
    this.state$.next(phoneNumber);
  }
}
