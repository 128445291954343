import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyFilterPipe } from './property-filter.pipe';
import { PropertyListComponent } from './property-list.component';
import { PipesModule } from '@shared/pipes/pipe.module';

@NgModule({
  declarations: [PropertyListComponent, PropertyFilterPipe],
  providers: [],
  imports: [CommonModule, PipesModule],
  exports: [PropertyListComponent, PropertyFilterPipe]
})
export class PropertyListModule {}
