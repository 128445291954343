import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InfoService } from '@shared/services/info.service';
import { throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { RetentionService } from './retention.service';

@Component({
  selector: 'trccp-retention',
  templateUrl: './retention.component.html',
  styleUrl: './retention.component.scss'
})
export class RetentionComponent {
  constructor(
    private retentionService: RetentionService,
    private translateService: TranslateService,
    private infoService: InfoService
  ) {}

  onContract() {
    this.retentionService
      .fromContact()
      .pipe(
        switchMap((contact) =>
          this.retentionService.updateCallRecordingCategory(
            contact.contactId,
            'vertrag'
          )
        ),
        tap(() =>
          this.infoService.showMessage(
            this.translateService.instant(
              'PHONE.RETENTION.NOTIFICATION.SUCCESS',
              {
                category: 'vertrag'
              }
            ),
            'success'
          )
        ),
        catchError((err) => {
          this.infoService.showMessage(
            this.translateService.instant('PHONE.RETENTION.NOTIFICATION.ERROR'),
            'error'
          );
          return throwError(() => err);
        }),
        take(1)
      )
      .subscribe();
  }

  onCoaching() {
    this.retentionService
      .fromContact()
      .pipe(
        switchMap((contact) =>
          this.retentionService.updateCallRecordingCategory(
            contact.contactId,
            'coaching'
          )
        ),
        tap(() =>
          this.infoService.showMessage(
            this.translateService.instant(
              'PHONE.RETENTION.NOTIFICATION.SUCCESS',
              {
                category: 'coaching'
              }
            ),
            'success'
          )
        ),
        catchError((err) => {
          this.infoService.showMessage(
            this.translateService.instant('PHONE.RETENTION.NOTIFICATION.ERROR'),
            'error'
          );
          return throwError(() => err);
        }),
        take(1)
      )
      .subscribe();
  }
}
